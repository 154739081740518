import React from 'react';
import StreamSrrows from "../../../assets/images/dashboard/stream/arrows.svg";

function TradingStream(props) {
    return (
        <>
            <div className="card_trading_stream">
                <div className="total">
                    <p>Total</p>
                    <h3>${props.total}</h3>
                </div>
                <div className="stream_wrap">
                    <img src={StreamSrrows} />
                    <ul>
                        <li>
                            <p>Level 1</p>
                            <h5>${props.level1}</h5>
                        </li>
                        <li>
                            <p>Level 2</p>
                            <h5>${props.level2}</h5>
                        </li>
                        <li>
                            <p>Level 3</p>
                            <h5>${props.level3}</h5>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TradingStream;