import React from 'react';
import { Avatar } from 'antd';

function Participant(props) {
    return (
        <>
            <div className='participant-info'>
                <div className="photo">
                    <Avatar
                        style={{
                            backgroundColor: "#f9b707",
                            verticalAlign: 'middle',
                            color: "#000",
                            fontWeight: "bold"
                        }}
                        size={60}
                        gap={1}
                        src={props.photo}
                    >
                        
                    </Avatar>
                </div>
                <div className="info">
                    <h4>{props.name}</h4>
                    <p>{props.email}</p>
                </div>
            </div>
        </>
    );
}

export default Participant;