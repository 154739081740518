import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import { Table, Pagination, Menu } from "antd";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";


function TransTransfer(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        document.title = "Transaction of Tower Tradings";


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            RequestType: "0",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/BalanceTrasnferDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setlistOfTransactions(response.data.listofTransfers);
                //setLedgerOld(response.data.ListofPackages);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'Payment_ID',
            key: 'paymentid',
        },

        {
            title: 'Date',
            dataIndex: 'Created_Date',
            key: 'Created_Date',
            render: (_, record) => (
                dayjs(record.Created_Date).format(config.date_format)
            )
        },
        {
            title: 'From',
            dataIndex: 'User_Name',
            key: 'User_Name',
        },
        {
            title: 'To',
            dataIndex: 'Beneficiary_Name',
            key: 'Beneficiary_Name',
        },
        {
            title: 'Amount',
            dataIndex: 'Transaction_Amount',
            key: 'Transaction_Amount',
            render: (_, record) => (
                '$' + record.Receiveable_Amount
            )
        },
        {
            title: 'Fee',
            dataIndex: 'Commission_Amount',
            key: 'fee',
        },
        {
            title: 'Receivable Amount',
            dataIndex: 'Receiveable_Amount',
            key: 'Receiveable_Amount',
            render: (_, record) => (
                '$' + record.Receiveable_Amount
            )
        },
        {
            title: 'Notes',
            dataIndex: 'Notes',
            key: 'Notes',

        },
    ];


    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };

   
    return (
        <>
            <TransactionMenu active="transfers" />

            
            <ul className="tabs-menu">
                <li><NavLink className="current" to="/member/transactions/transfers">SENT</NavLink></li>
                <li><NavLink  to="/member/transactions/transfers/received">Received</NavLink></li>
            </ul>

            <Table dataSource={listOfTransactions} columns={columns} size="small" loading={loading} pagination={false} />
            <div className="pagination-wrap">

                {totalRecords > 10 && (
                    <Pagination
                        defaultCurrent={pageNo}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={page => setPageNo(page)}
                    />
                )}
            </div>
        </>
    );
}

export default TransTransfer;