import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import Security from '../../../../assets/images/landing/Security.svg';
import Regulation from '../../../../assets/images/landing/Regulation.svg';
import Analysis from '../../../../assets/images/landing/Analysis.svg';
import money from '../../../../assets/images/landing/money.jpg';
import { Row, Col } from 'antd';
const Exchanges = () => {
  useEffect(() => {
    document.title = "Exchanges";
  }, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Exchanges' />
        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'} row-rewa>
              <Col md={{ span: "12" }} xs={24}>
                <div className="section-title">
                  <h3>
                    <span>Understanding exchanges:</span> <br />
                    Your gateway to profitable investments
                  </h3>
                </div>
                <div className="section-detail">
                  An exchange is a vital pillar of the financial world. It's a platform where assets such as stocks, cryptocurrencies, commodities, and more, are bought and sold. These exchanges provide a transparent marketplace, connecting buyers and sellers. They play a critical role in price discovery and ensuring fair transactions. Investors can access exchanges through brokerage accounts or trading platforms. Here, they can make informed decisions based on market data, research, and analysis.
                </div>
              </Col>
              <Col md={{ span: "12" }} xs={24}>
                <img src={money} alt="" style={{ borderRadius: "10px" }} />
              </Col>
            </Row>
          </div>
        </div>
        <div className="simple-section">
          <div className="tg-container">
            <Row align={'middle'} className='mb-5'>
              <Col md={24} align={'middle'}>
                <div className="section-title">
                  <h3>
                    <span>Ensuring</span>  Safe Trading
                  </h3>
                </div>
              </Col>
            </Row>
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align="center"
              className="mt-5" style={{ alignItems: "flex-start" }}
            >
              <Col md={6} xs={24}>
                <div className="single-feature" style={{minHeight:"455px"}}>
                  <div className="icon-box">
                    <img src={Security} alt="" />
                  </div>

                  <h3>Security Measures</h3>

                  <p>Explore the security protocols implemented by exchanges, such as encryption, tw.</p>
                </div>
              </Col>
              <Col md={6} xs={24}>
                <div className="single-feature" style={{minHeight:"455px"}}>
                  <div className="icon-box">
                    <img src={Regulation} alt="" />
                  </div>

                  <h3>Regulations and Compliance</h3>

                  <p>Understanding the regulatory environment of exchanges is essential. Regulations vary globally and impact how exchanges operate and protect investors' interests.</p>
                </div>
              </Col>
              <Col md={6} xs={24}>
                <div className="single-feature">
                  <div className="icon-box">
                    <img src={Analysis} alt="" />
                  </div>

                  <h3>Research and Analysis</h3>

                  <p>Learn about fundamental and technical analysis techniques. Fundamental analysis involves evaluating a company's financial health, while technical analysis uses historical price data to predict future price movements.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Exchanges