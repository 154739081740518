import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Slider, Form, Input, Button, Space } from "antd";

import TradingCard from "./TradingCard";
import imgellipseRed from "../../../assets/images/dashboard/ellipse-red.svg";

import config from "../../../Config";
import axios from "axios";
import LoginCheck from "../shared/LoginCheck";
import BuyPackage from "./BuyPackage";



function Tradings(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [balance, setBalance] = useState(0);



    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);


    // packages
    const [ListofPackages, setListofPackages] = useState([]);
    const [IsPurchased, setIsPurchased] = useState(false);

    useEffect(() => {
        document.title = "Tradings";
        setIsPurchased(false);
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetMemberPackages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                ////console.log(response.data);
                if (response.data.status_code == 1) {

                    setListofPackages(response.data.ListofPackages);
                    setBalance(response.data.member_balance);
                    setLoading(false);

                }
                else {
                    setLoading(false);
                }


            })
            .catch(function (error) {

            });



    }, [IsPurchased]);



    const renderPackages = () => {
        if (ListofPackages.length > 0) {
            return (
                ListofPackages.map((item, index) => {
                    return (
                        <Col md={12} xs={24} key={index}>
                            <TradingCard package={item} />
                        </Col>
                    )
                })
            )
        }
    }


    const totalValue = () => {
        if(ListofPackages.length > 0) {
            const totalPackagePrice = ListofPackages.reduce((acc, item) => acc + item.PackagePrice, 0);
            return totalPackagePrice;
            ////console.log(totalPackagePrice);
        }
    }



    return (
        <>
            <LoginCheck />
            <BuyPackage IsPackagePurchased={(e) => setIsPurchased(true)} />
            <Row gutter={[24, 24]}>
                <Col md={16} xs={24}>
                    <h3 className="card-title">Tower Tradings</h3>
                    <div className="card-overview card-bottom-shadow">
                        <div className="card-body" style={{backgroundColor: "#FFF"}}>
                            <div className="position-relative">
                                <Row gutter={[24, 24]}>
                                    {renderPackages()}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={8} xs={24}>
                    <h3 className="card-title">Total Tradings</h3>
                    <div className="card-profit card-bottom-shadow">
                        <div className="card-body card-backdrop">
                            <img src={imgellipseRed} />
                            <div className="position-relative">
                                <h2>${totalValue()}</h2>
                                <div style={{ margin: "10px 0" }}>
                                    <svg width="72" height="41" viewBox="0 0 72 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.38947 40.9039L0.735596 38.25L19.3125 19.6731C21.1635 17.8702 23.375 16.9567 25.9471 16.9327C28.5193 16.9087 30.7308 17.8221 32.5818 19.6731L36.8943 23.9856C38.0289 25.1202 39.3642 25.6755 40.9002 25.6514C42.4363 25.6274 43.7717 25.0721 44.9063 23.9856L64.8389 4.125H52.5145V0.375H71.2645V19.125H67.5145V6.80053L47.5602 26.6611C45.7092 28.464 43.4856 29.3774 40.8895 29.4015C38.2933 29.4255 36.0937 28.5361 34.2909 26.7332L29.8846 22.3269C28.7644 21.2067 27.4447 20.6587 25.9255 20.6827C24.4063 20.7067 23.0866 21.2548 21.9664 22.3269L3.38947 40.9039Z" fill="#F9B707" fillOpacity="0.1" />
                                    </svg>
                                </div>
                                {/* <div className="action_box">
                                    <a className="round-icon-button">
                                        <span className="icon"><i className="material-symbols-outlined">add</i></span>
                                        <span className="label">New Trade</span>
                                    </a>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>



        </>
    );
}

export default Tradings;