import React, { useEffect, useState } from 'react'
import LeftMenu from './LeftMenu'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Header from './Header';

function Main() {

  const AccessKey = localStorage.getItem("AccessKey");


  let navigate = useNavigate();

  useEffect(() => {
    ////console.log(AccessKey);
    if (AccessKey == null) {
      navigate("/login");
    }

  }, []);


  const handleClose = () => {


    var body = document.getElementById("body");
    body.classList.remove('overflow-hidden');

    var sider_bars = document.getElementById("side_menu");
    if (sider_bars) {
      sider_bars.classList.remove('active');
    }

    var overlaymenu = document.getElementById("overlaymenu");
    if (overlaymenu) {
      overlaymenu.classList.remove('active');
    }
    var menubutton = document.getElementById("menubutton");
    if (menubutton) {
      menubutton.classList.remove('active');
    }

  }


  return (
    <>
      <div className="wrapper">
        <div onClick={handleClose} id="overlaymenu"></div>
        <LeftMenu />
        <div className="right-side">
          <Header />
          <div className="right-side-contents">
            <div className="page-content">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Main
