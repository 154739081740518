import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import { Table, Pagination, Menu } from "antd";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import LoginCheck from "../shared/LoginCheck";


function DownlineFor(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        document.title = "Downline Activations";


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            

        };

        

        var api_config = {
            method: 'post',
            url: config.base_url + 'Wallet/GetDownlineActivationPaid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setlistOfTransactions(response.data.ListofOtherReward);
                //setLedgerOld(response.data.ListofPackages);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'SecretID',
            key: 'SecretID',
        },
        {
            title: 'Trade',
            dataIndex: 'PackageName',
            key: 'PackageName',
        },
        {
            title: 'Date',
            dataIndex: 'Created_Date',
            key: 'Created_Date',
            render: (_, record) => (
                dayjs(record.Created_Date).format(config.date_format)
            )
        },
       
        {
            title: 'Paid For',
            dataIndex: 'User_ID',
            key: 'User_ID',
        },
        {
            title: 'Paid By',
            dataIndex: 'Paid_By',
            key: 'Paid_By',
        },
        {
            title: 'Trade Value',
            dataIndex: 'PackageAmount',
            key: 'PackageAmount',
            render: (_, record) => (
                '$' + record.PackageAmount
            )
        },
        {
            title: 'Fee Amount',
            dataIndex: 'FeeAmount',
            key: 'FeeAmount',
            render: (_, record) => (
                '$' + record.FeeAmount
            )
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',

        },
    ];


    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };

   
    return (
        <>
            <LoginCheck />
            <TransactionMenu active="downline" />

            
            <ul className="tabs-menu">
                <li><NavLink className="current" to="/member/transactions/downline-activations/activated-for">Purchased For</NavLink></li>
                <li><NavLink  to="/member/transactions/downline-activations/activated-by">Purchased By</NavLink></li>
            </ul>

            <Table dataSource={listOfTransactions} columns={columns} size="small" loading={loading} pagination={false} />
            <div className="pagination-wrap">

                {totalRecords > 10 && (
                    <Pagination
                        defaultCurrent={pageNo}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={page => setPageNo(page)}
                    />
                )}
            </div>
        </>
    );
}

export default DownlineFor;