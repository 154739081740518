import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import config from "../../../Config";
import axios from "axios";
import { message } from "antd";

const AddBalanceModel = ({show,handleClose, selectedMember }) => {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [validated, setValidated] = useState(false);
    const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isUpdated, setIsUpdated] = useState(false);

    //console.log(selectedMember);
    

    const handleAddBalance = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          setLoadingUpdateBtn(true);
          const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberID: selectedMember.Nodes_IN_PK_Code,
            Amount: event.target.Amount.value,
            TypeID: event.target.TypeID.value,
            Description: event.target.Description.value,
            PINCode: event.target.PINCode.value,
          };
    
          //console.log(data);
    
          var api_config = {
            method: "post",
            url: config.base_url + "Users/BalanceTransfer",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
    
          axios(api_config)
            .then(function (response) {
              console.log(response.data);
              if (response.data.status_code == 1) {

                handleClose();
                setLoadingUpdateBtn(false);


                messageApi.open({
                  type: "success",
                  content: response.data.status_message,
                });
                setIsUpdated(true);
              } else {

                handleClose();
                setLoadingUpdateBtn(false);

                
                messageApi.open({
                  type: "error",
                  content: response.data.status_message,
                });
              }
    
              
             // 
            })
            .catch(function (error) {
              //setLoading(false);
              messageApi.open({
                type: "error",
                content: "Network Error",
              });
            });
        }
    
        setValidated(true);
      };

  return (
    <>
    {contextHolder}
      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Balance For {selectedMember.User_Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddBalance}>
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Amount</label>
                <input
                  type="number"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Amount"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Description</label>
                <input
                  type="text"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Description"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Add/Remove</label>
                <select className="form-select" name="TypeID" required>
                    <option value="">Select an Option</option>
                    <option value="1">Add</option>
                    <option value="2">Remove</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <label>Pin Code</label>
                <input
                  type="text"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="PINCode"
                  className="form-control"
                  required
                />
              </div>

              {loadingUpdateBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Add Balance / Remove Balance
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBalanceModel;
