import React, { useState, useEffect } from "react";


import Participant from "./Participant";
import config from "../../../Config";
import { Carousel } from "antd";

function RecentParticipants({ data, loading }) {

    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (loading === false) {
            setParticipants(data);
        }
    }, [loading])

    // console.log(participants);

    const renderParticipants = () => {
        if (participants && participants.length > 0) {
            return participants.map((item, index) => {
                return (

                    <Participant key={index} photo={config.img_path + item.Img_File} name={item.Full_Name} email={item.Email_Address} />

                );
            });
        } else {
            return <p>No participants available</p>; // Or any other fallback message
        }
    };


    return (
        <>
           
            <Carousel
                autoplay
                slidesToShow={7}
                slidesToScroll={1}
                dots={false}
                className="participant-slider"
                responsive={[
                    {
                        breakpoint: 768, // Adjust this breakpoint as needed
                        settings: {
                            slidesToShow: 2, // Show 1 slide on mobile view
                            slidesToScroll: 1,
                        },
                    },
                    // Add more breakpoints and settings as needed
                ]}
            >
                {renderParticipants()}
            </Carousel>

        </>
    );
}

export default RecentParticipants;