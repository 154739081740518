import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";
import { useNavigate } from "react-router";
import Spinner from "react-bootstrap/Spinner";


import withdrawal from "../../../assets/images/admin/icons/withdrawal.png"



import free_balance from "../../../assets/images/admin/icons/free_balance.png";


import crossdolar from "../../../assets/images/admin/icons/crossdolar.png";

import pending_withdrawl from "../../../assets/images/admin/icons/pending_withdrawl.png";

import cashh from "../../../assets/images/admin/icons/cashh.png";

   
   
import { NumberFormat } from "../../member/General/Functions";
import TowergadRightsCheck from "../TowergadRightsCheck";

function WithdrawalDashboard() {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [withdrawDashboard, setwithdrawDashboard] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Withdrawal Panel";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetWithdrawalDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);

        if (response.data.status_code === 1) {
          setwithdrawDashboard(response.data);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          
          navigate("/towergad/login");
        } else {
             
        }

        setLoading(false);
      })
      .catch(function (error) {
            
      });
  }, []);

 
  return (
    <>
    <TowergadRightsCheck />
  {loading? (
      <div
      className="d-flex justify-content-center"
      style={{ marginTop: "20%" }}
    >
      <Spinner animation="border" role="status"></Spinner>
    </div>
    ):(
      <>
         
      <PageTitle title="WITHDRAWAL PANEL" />
      {withdrawDashboard && (
         <div className="admin-content">
         <div className="row">
         <div className="col-md-3">
             <TowergadCard
               link="/towergad/withdrawal/success"
               class="green"
               value={NumberFormat(withdrawDashboard.transferredAmount)}
               label="Success"
               icon={free_balance}
               progress="progress"
               progressbar="progress-bar"
               progressrole="progressbar"
               val="80%"
               arianow="10"
               ariamin="0"
               ariamax="100"
               progressval=""
 
             />
           </div>
           <div className="col-md-3">
             <TowergadCard
               link="/towergad/withdrawal/initiated"
               class="green"
               value={NumberFormat(withdrawDashboard.initiatedAmount)}
               label="INITIATED"
               icon={withdrawal}
               progress="progress"
               progressbar="progress-bar"
               progressrole="progressbar"
               val="10%"
               arianow="10"
               ariamin="0"
               ariamax="100"
               progressval=""
             />
           </div>
 
           <div className="col-md-3">
             <TowergadCard
               link="/towergad/withdrawal/approved"
               class="green"
               value={NumberFormat(withdrawDashboard.approvedAmount)}
               label="APPROVED"
               icon={crossdolar}
               progress="progress"
               progressbar="progress-bar"
               progressrole="progressbar"
               val="50%"
               arianow="10"
               ariamin="0"
               ariamax="100"
               progressval=""
             />
           </div>
 
           <div className="col-md-3">
             <TowergadCard
               link="/towergad/withdrawal/canceled"
               class="red"
               value={NumberFormat(withdrawDashboard.canceledAmount)}
               label="CANCELED"
               icon={crossdolar}
               progress="progress"
               progressbar="progress-bar"
               progressrole="progressbar"
               val="50%"
               arianow="10"
               ariamin="0"
               ariamax="100"
               progressval=""
             />
           </div>
 
           <div className="col-md-3">
             <TowergadCard
               link="/towergad/withdrawal/rejected"
               class="red"
               value={NumberFormat(withdrawDashboard.rejectedAmount)}
               label="REJECTED"
               icon={crossdolar}
               progress="progress"
               progressbar="progress-bar"
               progressrole="progressbar"
               val="50%"
               arianow="10"
               ariamin="0"
               ariamax="100"
               progressval=""
             />
           </div>
 
           
  
         </div>
       </div>


      )}
     
  </>
    )
 

  }
  
  </>
  );
}

export default WithdrawalDashboard;
