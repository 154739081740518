import React, { useEffect } from "react";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";
import iconWithdrawal from "../../../assets/images/admin/icons/withdrawal.png";
import iconReceived from "../../../assets/images/admin/icons/received.png";
import iconCash from "../../../assets/images/admin/icons/cash.png";
import iconPaid from "../../../assets/images/admin/icons/paid.png";
import iconPending from "../../../assets/images/admin/icons/pending.png";
import iconPackage from "../../../assets/images/admin/icons/package.png";
import iconDeposit from "../../../assets/images/admin/icons/deposit.png";
import TowergadRightsCheck from "../TowergadRightsCheck";
// import AdminHeader from "./AdminHeader";

function FundDashboard(props) {
  useEffect(() => {
    document.title = "User Panel";
  }, []);

  return (
    <>
    <TowergadRightsCheck />
      <PageTitle title="TOTAL FUND PANEL" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="blue"
              value="45$"
              label="USER CASH BALANCE"
              icon={iconCash}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="15"
              label="TOTAL RECEIVED BONUS"
              icon={iconReceived}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="70%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="red"
              value="3222"
              label="TOTAL PAID BONUS"
              icon={iconPaid}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="brown"
              value="178455"
              label="USER PENDING BONUS"
              icon={iconPending}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="/admin/fund/total-packages"
              class="green"
              value="325"
              label="TOTAL PACKAGE"
              icon={iconPackage}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="50%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="red"
              value="325"
              label="TOTAL DEPOSIT"
              icon={iconDeposit}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="325"
              label="TOTAL WITHDRAWAL"
              icon={iconWithdrawal}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="100%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FundDashboard;
