import React, {useState} from 'react';
import { Menu } from 'antd';

import { NavLink } from "react-router-dom";


function RanksMenu(props) {

    const [current, setCurrent] = useState(props.active);


    const items = [
      {
        label: (<NavLink to="/member/ranks">Ranks</NavLink>),
        key: 'Ranks',
  
      },
      {
        label: (<NavLink to="/member/Rewards">Rewards</NavLink>),
        key: 'Rewards',
  
      },
      {
        label: (<NavLink to="/member/MysteryBox">Mystery Box Winners</NavLink>),
        key: 'Mystery',
  
      },
     
    ];
  
  
    const onClick = (e) => {
      //console.log('click ', e);
      setCurrent(e.key);
    };

    return (
        <>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            <br />
        </>
    );
}

export default RanksMenu;