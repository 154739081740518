import React from 'react';
import logo from "../../../assets/images/logo/towergear_white.png"
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';

function LeftMenu() {

    const currentLocation = useLocation();
    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };


    return (
        <>


            <div className="left-side" id='side_menu'>
                <NavLink to="/member/dashboard">
                    <img src={logo} alt="" />
                </NavLink>
                <ul className="side-bar">
                    <li>
                        <NavLink to="/member/dashboard" className={isMenuItemActive('/member/dashboard') ? 'active' : ''}>
                            <i className="material-symbols-outlined">dashboard</i>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/tradings" className={isMenuItemActive('/member/tradings') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                credit_card
                            </i>
                            <span>Tower Tradings</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/addmoney" className={isMenuItemActive('/member/addmoney') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                add_card
                            </i>
                            <span>Add Money</span>
                        </NavLink>
                    </li>
                   
                    <li>
                        <NavLink to="/member/transfers" className={isMenuItemActive('/member/transfers') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                group
                            </i>
                            <span>Transfers</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/referrals" className={isMenuItemActive('/member/referrals') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                manage_accounts
                            </i>
                            <span>Referrals</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/network" className={isMenuItemActive('/member/network') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                account_tree
                            </i>
                            <span>Network</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/transactions/tradings" className={isMenuItemActive('/member/transactions') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                sync_alt
                            </i>
                            <span>Transactions</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/withdrawal" className={isMenuItemActive('/member/withdrawal') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                whatshot
                            </i>
                            <span>Withdrawal</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/member/ranks" className={isMenuItemActive('/member/ranks') ? 'active' : ''}>
                            <i className="material-symbols-outlined">
                                military_tech
                            </i>
                            <span>Ranks & Rewards</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default LeftMenu
