import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import { Popover, Progress, Result, Space, Switch, Tooltip, message } from 'antd';
import config from '../../../Config';
import axios from 'axios';

import send from "../../../assets/images/dashboard/icons/sendmoney.png";
import withdraw from "../../../assets/images/dashboard/icons/withdraw.png";


function ProfitEarned({ roi, value, loading, compounding, tradevolume, tradeincome }) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [incrementedValue, setIncrementedValue] = useState(0);
    const [CompoundingStatus, setCompoundingStatus] = useState(false);
    const [Percentage, setPercentage] = useState(0);

    // messages
    const [messageApi, contextHolder] = message.useMessage();

    //console.log(compounding);

    // setInterval(() => {
    //     // Increment the value by 1 every second
    //     setIncrementedValue(roi + value);


    //   }, 1000);

    // useEffect(() => {
    //     // Check if roi and value are defined before setting the initial state
    //     if (roi && value) {
    //         setIncrementedValue(roi);

    //         const interval = setInterval(() => {
    //             // Use the functional update form of setIncrementedValue
    //             setIncrementedValue(prevValue => prevValue + value);
    //         }, 1000);

    //         return () => clearInterval(interval);
    //     }
    // }, [roi, value]);



    const onChange = (checked) => {
        //setCompoundingStatus(!CompoundingStatus);
        setCompoundingStatus(checked);

        //console.log(checked);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/ChangeCompoundStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);

                if (response.data.status_code == 1) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                }
                else {

                }
            })
            .catch(function (error) {
                console.log(error);
            });


    };


    useEffect(() => {
        //console.log(compounding);
        if (!loading) {
            if (compounding === true) {
                setCompoundingStatus(true);
            }
            else {
                setCompoundingStatus(false);
            }
        }

        const calculatedPercentage = (tradeincome / tradevolume) * 100;
        if (isNaN(calculatedPercentage)) {
            setPercentage(0);
        }
        else {
            setPercentage(calculatedPercentage.toFixed(2));
        }





    }, [loading]);

    const content = (
        <div>
            <p style={{ marginTop: "0", marginBottom: "0" }}>Received: <strong>${tradeincome}</strong></p>
            <p style={{ marginTop: "0", }}>Remaining: <strong>${(tradevolume - tradeincome).toFixed(2)}</strong></p>
        </div>
    );

    return (
        <>
            {contextHolder}
            <div className="card-action-title">
                <h3 className="card-title">Profit Earned</h3>
                <div className="card-actions">
                    <Space>
                        <span>Compounding</span>
                        {
                            <Switch
                                onChange={onChange}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                loading={loading}
                                //defaultChecked={CompoundingStatus}
                                checked={CompoundingStatus}
                            />
                        }

                    </Space>

                </div>
            </div>
            <div className="card-profit card-bottom-shadow">
                <div className="card-body card-backdrop">
                    <div className="position-relative">
                        {
                            Percentage >= 100 ?
                                <>
                                    <div class="ant-result ant-result-success css-dev-only-do-not-override-fsqviy">
                                        <div class="ant-result-icon" style={{marginBottom: "0"}}>
                                            <span role="img" aria-label="check-circle" class="anticon anticon-check-circle">
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="3rem" height="3rem" fill="#f9b707" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg>
                                            </span>
                                        </div>
                                    <div class="ant-result-title">Congratulations</div>
                                    <div class="ant-result-subtitle">We congratulate you on your investment success! Well done!</div>
                                </div>

                                </>
                                :
                                <Popover content={content} title="">
                                    <Progress type="circle" percent={Percentage} strokeColor="#f9b707" />
                                </Popover>
                        }

                        {/* {
                            loading ?
                                "Loading.."
                                :
                                <h2>${incrementedValue.toFixed(8)}</h2>
                        } */}

                       
                        <div className="action_box mt-3">
                            <NavLink to="/member/withdrawal" className="round-icon-button">
                                <span className="icon" style={{border: "none"}}>
                                    <img src={withdraw} />
                                </span>
                                <span className="label">Withdraw</span>
                            </NavLink>
                            <NavLink to="/member/transfers" className="round-icon-button">
                                <span className="icon" style={{border: "none"}}>
                                <img src={send} />
                                </span>
                                <span className="label">Send</span>
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfitEarned;