import React from 'react';

function PageTitle(props) {
    return (
        <>
            <div className="admin-title">
                <div className="">
                    <h4>{props.title}</h4>
                </div>
            </div>
            <div className="title-bar" >
                <div className="container">
                    <h4>{props.subtitle}</h4>
                </div>
            </div>
        </>
    );
}

export default PageTitle;