import React, { useState ,useEffect} from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
} from "antd";
import { NavLink,useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config";

const KycStep1 = () => {
  const { Text, Link, Title } = Typography;

  const [messageApi, contextHolder] = message.useMessage();

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

  let navigate = useNavigate();

  useEffect(() => {
    document.title= "Start KYC"
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };
        
    var api_config = {
      method: "post",
      url: config.base_url + "MemberKYC/GetMemberKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
          // //console.log(response.data.CurrentStatus);

          if (response.data.CurrentStatus === "Submitted") {
         
             navigate("/member/kyc/submitted");
            // document.title= "KYC Submitted"

          } else if (response.data.CurrentStatus === "Approved") {
           
            navigate("/member/kyc/congratulations");
            // document.title= "KYC Approved"

          }
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/login");
        }  
      })
      .catch(function (error) {
        messageApi.open({
            type: "error",
            content: "Network Error.",
          });
      });
  }, []);

















  return (
    <>
    {contextHolder}
      <Row gutter={[24, 24]}>
        <Col md={18} offset={3} xs={24}>
          <Card title="KYC - Know Your Client" bordered={true}>
            <Title level={5} style={{margin:"0px"}}>
              Become Tower Gear Plus verified member in less than 5 minutes and unlock
              full access <br /> to your account and its affiliate resources.
            </Title>
            <div style={{textAlign:"end"}}>

            
           
             
                <NavLink
                  to="/member/kyc/birth-details"
                  style={{ padding: "20px" }}
                 
                  
                >
                   <Button style={{marginTop:"2%",width:"10%"
                  
                  }}>
                    Start
                   </Button>
                </NavLink>
             
              </div>
          
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KycStep1;
