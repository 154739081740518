import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
   
   
// import Logo from "../../assets/images/logo-white.png";
function TowergadLogin(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Login";
    if (AccessKey != null) {
      const dashboardParms = {
        AccessKey: AccessKey,
        UserID: UserID,
      };
      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetDashboardData",
        headers: {
          "Content-Type": "application/json",
        },
        data: dashboardParms,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            navigate("/dashboard/home");
          }
          else{
            navigate("/towergad/login")
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
      //navigate("/login");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const signInData = {
        Email_Address: event.target.email.value,
        Password: event.target.password.value,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Users/SignIn",
        headers: {
          "Content-Type": "application/json",
        },
        data: signInData,
      };
      axios(api_config)
        .then(function (response) {
          ////console.log(response.data);
          if (response.data.status_code == 1) {
            localStorage.setItem("AdminAccessKey", response.data.AccessKey);
            localStorage.setItem("AdminID", response.data.ID);
            localStorage.setItem("AdminFull_Name", response.data.Full_Name);
            

                navigate("/towergad/dashboard");
            
          
          } else {
           
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
            
        });
    }
    setValidated(true);
  };

  return (
    <>
         
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="admin_login_wrap">
          <div className="login_box">
            <div className="login_box_head">
              {/* <img src={Logo} /> */}
              <h4>Let's Get Started</h4>
              <p>Sign in to continue to Tower Gear Plus.</p>
            </div>
            <div className="login_box_body form_default">
              <div className="form-group mb-3">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  style={{color: "#FFF"}}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  style={{color: "#FFF"}}
                  className="form-control"
                  required
                />
              </div>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default TowergadLogin;
