import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from "../../../Config";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  Modal,
  Upload,
  message,
  Space,
  Spin,
  Select
} from "antd";

import ProfileMenu from "./ProfileMenu";
import { PlusOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CountryDropDown from "../General/CountryDropDown";

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function UpdateProfile(props) {
  const onFinish = (values) => {
    //console.log("Success:", values);
    // Add logic to save the form data
  };

  const { TextArea } = Input;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageUploaded, setImageUploaded] = useState(false); // Track whether an image has been uploaded
  const [newNameLoading, setNewNameLoading] = useState(false);
  const [NewName, setNewName] = useState("");

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
  const [newAddressLoading, setNewAddressLoading] = useState(false);
  const [NewAddress, setNewAddress] = useState("");
  const [upload_image, setupload_image] = useState(false);



  // imgage
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [imageSelected, setImageSelected] = useState(false);
  const [imageCropped, setImageCropped] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [profile, setProfileData] = useState({});

  // messages
  const [messageApi, contextHolder] = message.useMessage();

  const [newCountryLoading, setNewCountryLoading] = useState(false);

  const [NewcountryName, setNewCountryName] = useState("");
  const [country, setCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [countriesOld, setCountriesOld] = useState([]);
  const countryArry = CountryDropDown();
  const { Option } = Select;
  const [form] = Form.useForm();

  // const handleCancel = () => setPreviewOpen(false);
  let navigate = useNavigate();

  useEffect(()=>{
  document.title="Edit Profile"

},[])



useEffect(() => {

 
  
  setLoading(true);
  const profileParams = {
      AccessKey: AccessKey,
      ID: UserID,
      Email_Address: Email,

  };

  var api_config = {
      method: 'post',
      url: config.base_url + 'Members/GetMemberProfile',
      headers: {
          'Content-Type': 'application/json'
      },
      data: profileParams
  };


  axios(api_config)
      .then(function (response) {
          if (response.data.status_code == 1) {
             //console.log(response.data);
              setProfileData(response.data);
              //form.setFieldsValue(response.data);
              setNewName(response.data.Full_Name);
              setNewAddress(response.data.Postal_Address);
              setNewCountryName(response.data.Country);
              
          }

          setLoading(false);

      })
      .catch(function (error) {
          console.log(error);
      });

}, [newNameLoading,newAddressLoading,newCountryLoading]);



















  const UpdateName = () => {
    if (NewName == "") {
      messageApi.open({
        type: 'error',
        content: "Please enter name",
      });
      return false;
    }

    setNewNameLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Full_Name: NewName,
    };

    // //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ChangeFullName",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setNewNameLoading(false);
          messageApi.open({
            type: 'success',
            content: response.data.status_message,
          });
          window.location.reload();

          // settriggerUpdate(true);

        
        }
        else{
             setNewNameLoading(false);
          messageApi.open({
            type: 'error',
            content: response.data.status_message,
          });

          // settriggerUpdate(true);

       
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const updateAddress = () => {
    if (NewAddress == "") {

      messageApi.open({
        type: 'error',
        content: "Please enter address",
      });
      return false;
    }

    setNewAddressLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Postal_Address: NewAddress,
    };

    // //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ChangePostalAddress",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          messageApi.open({
            type: 'success',
            content: response.data.status_message,
          });

          setNewAddressLoading(false);
        }
        else{
          messageApi.open({
            type: 'error',
            content: response.data.status_message,
          });
           

          setNewAddressLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };


  const UpdateCountry = () => {
    if (NewcountryName == "") {
      messageApi.open({
        type: 'error',
        content: "Please enter country",
      });
      return false;
    }

    setNewCountryLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      CountryName: NewcountryName,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ChangeCountry",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          messageApi.open({
            type: 'success',
            content: response.data.status_message,
          });
          // settriggerUpdate(true);

          setNewCountryLoading(false);
        }
        else{
          messageApi.open({
            type: 'error',
            content: response.data.status_message,
          });

          // settriggerUpdate(true);

          setNewCountryLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };


  const updateProfile_Img = () => {
    if (typeof cropper !== "undefined") {
      setupload_image(true);
      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      console.log(cropData1);
      setImageCropped(true);
      var cleanerBase64 = cropData1.substring(22);


      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Img_File: cleanerBase64,
      };

      console.log(data);
      

      var api_config = {
        method: "post",
        url: config.base_url + "Members/UpdatePhoto",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      };

     // console.log(api_config);
      axios(api_config)
        .then(function (response) {
         // console.log(response.data);
          if (response.data.status_code === 1) {
            setupload_image(false);
            messageApi.open({
              type: 'success',
              content: response.data.status_message,
            });
           // window.location.reload();
          } else {
            messageApi.open({
              type: 'error',
              content: response.data.status_message,
            });
            ////console.log("API call failed");
            setupload_image(false);
          }
        })
        .catch(function (error) {
          //console.log(error);
          setupload_image(false);
        });
    }
  };

 
  const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setImageSelected(false)
      messageApi.open({
        type: 'error',
        content: "Image size must be less than 2 MB",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };



  const [dragging, setDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event) => {

    event.preventDefault();
    setImageSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {

      if (file.size > MAX_IMAGE_SIZE) {

        setImageSelected(false)
        messageApi.open({
          type: 'error',
          content: "Image size must be less than 2 MB",
        });

        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        if (cropper) {
          cropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <>
      {contextHolder}
      <h3 className="card-title">Profile</h3>
      <Row gutter={[24, 24]}>
        <Col md={6} xs={24}>
          <ProfileMenu />
        </Col>
        <Col 
        md={{span: 16, offset: 2}}
        xs={24}>
          <Card title="General" bordered={false} loading={loading}>
            <div className="profile-wrap">
              <Form
                name="basic"
                layout="vertical"
                size="large"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row gutter={[24, 0]} align={"top"}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Full Name"
                      name="Full_Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    >
                      <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          value={NewName}
                          maxLength={12}
                          onChange={(e) => setNewName(e.target.value)}
                        />
                        <Button
                          type="default"
                          onClick={UpdateName}
                          loading={newNameLoading}
                        >
                         Update
                        </Button>
                      </Space.Compact>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Address"
                      name="Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your address!",
                        },
                      ]}
                    >
                      <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          value={NewAddress}
                          onChange={(e) => setNewAddress(e.target.value)}
                        />
                        <Button
                          type="default"
                          onClick={updateAddress}
                          loading={newAddressLoading}
                        >
                          Update
                        </Button>
                      </Space.Compact>
                    </Form.Item>

                    <Form.Item
                    label="Country"
                    name="BirthCountry"
                    
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input country !",
                      },
                    ]}
                  >
                    <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                    <Select
                      showSearch
                       optionFilterProp="children"
                        
                      onChange={(value) => setNewCountryName(value)}                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={countryArry === false ? true : false}
                      defaultValue={loading ? null : profile.Country}
                      options={countryArry}
                    />
                       <Button
                          type="default"
                            onClick={UpdateCountry}
                            loading={newCountryLoading}
                        >
                          Update
                        </Button>
                    </Space.Compact>
                  </Form.Item>

                  </Col>
                  <Col xs={24} md={{ span: 24 }} align={"middle"}>
                    <Form.Item
                      label="Profile Picture"
                      name="ProfilePicture"
                      rules={[
                        {
                          required: !imageUploaded, // Make it required if no image uploaded
                          message: "Please upload an image!",
                        },
                      ]}
                    >
                      <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                        id="drop-area"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        {
                          imageSelected ?
                            <>
                              <Cropper
                                zoomTo={0}
                                aspectRatio={8 / 8}
                                preview=".img-preview"
                                src={image}
                                dragMode="move"
                                viewMode={2}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                onInitialized={(instance) => {
                                  setCropper(instance);
                                }}
                                guides={true}
                              />
                              <div style={{marginTop:"15px"}}>
                                <label htmlFor="ChoosePhoto1">
                                  <span htmlFor="ChoosePhoto1" type="button" className="button button-outline-primary mt-3">Re Upload</span>
                                  <input style={{position:"absolute",opacity:"0"}} id="ChoosePhoto1" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                </label>
                              </div>
                            </>

                            :
                            <>
                              <label htmlFor="ChoosePhoto">
                                <h4>Drag and drop your document</h4>
                                <p>Max size 2 MB. Files allowed: JPG, PNG</p>
                                <span htmlFor="ChoosePhoto" type="button" className="button button-outline-primary">Browse File</span>
                                <input id="ChoosePhoto"
                                 style={{position:"absolute",opacity:"0"}}
                                  accept="image/jpeg,image/png" type="file" onChange={onChange} />
                              </label>

                            </>

                        }

                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" block shape="rounded" loading={upload_image} onClick={updateProfile_Img}>
                        Save Photo
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UpdateProfile;
