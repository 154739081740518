import React, { useEffect } from 'react'
import Footer from '../include/Footer'
import Header from '../include/Website_Header'
import { Col, Row } from 'antd'
import { Link, NavLink } from 'react-router-dom'
import girl_photo from '../../../assets/images/services/girl-photo.jpg'
import boy_photo from '../../../assets/images/services/boy-photo.jpg'
import map from '../../../assets/images/services/map.png'
import amazone from "../../../assets/images/landing/partners/amazon.svg";
import apple from "../../../assets/images/landing/partners/apple.svg";
import atandt from "../../../assets/images/landing/partners/atandt.svg";
import cocacola from "../../../assets/images/landing/partners/cocacola.svg";
import google from "../../../assets/images/landing/partners/google.svg";
import intel from "../../../assets/images/landing/partners/intel.svg";
import kfc from "../../../assets/images/landing/partners/kfc.svg";
import linkedin from "../../../assets/images/landing/partners/linkedin.svg";
import mcdonald from "../../../assets/images/landing/partners/mcdonald.svg";
import microsoft from "../../../assets/images/landing/partners/microsoft.svg";
import nike from "../../../assets/images/landing/partners/nike.svg";
import pepsi from "../../../assets/images/landing/partners/pepsi.svg";
import pg from "../../../assets/images/landing/partners/pg.svg";
import samsung from "../../../assets/images/landing/partners/samsung.svg";
import toyota from "../../../assets/images/landing/partners/toyota.svg";
import Page_head from '../../member/layout/Page_head'

const Services = () => {
  useEffect(() => {
    document.title = "Services"

  }, [])
  return (
    <>
      <Header />
      <Page_head page_title='Services' />
      <div className="simple-section services-bg bg-black">
        <div className="tg-container">
          <Row align={'middle'} gutter={25}>
            <Col md={10} xs={24}>
              <div class="thumb">
                <img src={girl_photo} className='girl_photo' />
                <img src={boy_photo} className='boy_photo' />
                <div class="experience">
                  <h2><strong>15</strong> Years experience</h2>
                </div>
              </div>
            </Col>
            <Col md={{ span: 12, offset: 2 }} xs={24}>
              <div className='content'>
                <div className="section-title">
                  <h3>Finance Consulting for Challenging Times</h3>
                </div>
                <div className="section-detail">
                  We work to understand your issues and are driven to ask better questions in the pursuit of making work.
                  Me contained explained my education. Vulgar as hearts by garret.
                  Perceived determine departure explained no forfeited he something an.
                  Contrasted dissimilar get joy you instrument out reasonably. Again keep.
                </div>
                <div className="primary-box">
                  <NavLink>
                    <span class="material-symbols-outlined">
                      currency_bitcoin
                    </span>
                    <h4>Targeting &amp;  <br /> Positioning</h4>
                  </NavLink>
                  <NavLink>
                    <span class="material-symbols-outlined">
                      lightbulb
                    </span>
                    <h4>Unique Ideas <br /> &amp; Solution</h4>
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="simple-section map-section">
        <div className="tg-container">
          <Row align={'middle'} gutter={25}>
            <Col md={10} xs={24}>
              <div className="partner-map">
                <h2 className="mask-text">80</h2>
                <h4>Partners worldwide</h4>
              </div>
            </Col>
            <Col md={{ span: 12, offset: 2 }} xs={24}>
              <section className='partners-section'>
                <ul className='partners-row'>
                  <li style={{ width: "30%" }}>
                    <img src={amazone} alt="partner" />
                  </li>
                  <li style={{ width: "30%" }}>
                    <img src={apple} alt="partner" />
                  </li>
                  <li style={{ width: "30%" }}>
                    <img src={atandt} alt="partner" />
                  </li>
                  <li style={{ width: "30%" }}>
                    <img src={google} alt="partner" />
                  </li>
                  <li style={{ width: "30%" }}>
                    <img src={mcdonald} alt="partner" />
                  </li>
                  <li style={{ width: "30%" }}>
                    <img src={microsoft} alt="partner" />
                  </li>
                </ul>
              </section>
            </Col>
          </Row>
        </div>
      </div>
      <div className="call-back-area">
        <div className="glass">
          <div className="tg-container">
            <Row align={'middle'}
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
            >
              <Col md={10} xs={24} className='text-center text-md-left'>
                <div className="section-title mb-3">
                  <h3 className='secondary'>Looking for a First-Class
                    Business Consultant?</h3>
                </div>
                <Link class="button button-secondary button-icon" href="">
                  Request a Call back
                  <span class="material-symbols-outlined">arrow_forward_ios</span>
                </Link>
              </Col>
              <Col md={{ span: 12, offset: 2 }} xs={24}>
                <div class="achivement-counter">
                  <ul>
                    <li>
                      <div class="icon">
                        <span class="material-symbols-outlined">
                          handshake
                        </span>
                      </div>
                      <div class="info-icon">
                        <h2>500+</h2>
                        <span>Business advices given over 30 years</span>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="material-symbols-outlined">
                          verified
                        </span>
                      </div>
                      <div class="info-icon">
                        <h2>30+</h2>
                        <span>Business Excellence awards achieved</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="simple-section support-section bg-black">
        <div className="tg-container">
          <Row align={'middle'} gutter={25}>
            <Col md={10} xs={24}>
              <div className="support_wraper">
                <div className="support_card">
                  <div className="subtitle">
                    <span>WHAT WE DO</span>
                  </div>
                  <div className="section-title">
                    <h3 style={{ fontSize: "27px" }} className=''>
                      Excellent service and support for you
                    </h3>
                  </div>
                  <ul className='support-list'>
                    <li>
                      <span class="material-symbols-outlined">
                        construction
                      </span>
                      Consulting services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">
                        volume_up
                      </span>
                      Marketing services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">
                        account_balance
                      </span>
                      Banking services
                    </li>
                  </ul>
                </div>
              </div>

            </Col>
            <Col md={{ span: 14 }} xs={24}>
              <ul className='support-list-item'>
                <li>
                  <span class="material-symbols-outlined">
                    travel_explore
                  </span>
                  <NavLink className={'setion-tilte'}>Investment Planning</NavLink>
                  <div className="section-detail">
                    Prevailed always tolerably
                    discourse and loser assurance creatively coin applauded more uncommonly.
                    Him everything trouble
                  </div>
                </li>
                <li>
                  <span class="material-symbols-outlined">
                    query_stats
                  </span>
                  <NavLink className={'setion-tilte'}>Markets Research</NavLink>
                  <div className="section-detail">
                    Prevailed always tolerably
                    discourse and loser assurance creatively coin applauded more uncommonly.
                    Him everything trouble
                  </div>
                </li>
                <li>
                  <span class="material-symbols-outlined">
                    supervisor_account
                  </span>
                  <NavLink className={'setion-tilte'}>Investment Planning</NavLink>
                  <div className="section-detail">
                    Prevailed always tolerably
                    discourse and loser assurance creatively coin applauded more uncommonly.
                    Him everything trouble
                  </div>
                </li>
                <li>
                  <span class="material-symbols-outlined">
                    lightbulb
                  </span>
                  <NavLink className={'setion-tilte'}>Business Planning
                  </NavLink>
                  <div className="section-detail">
                    Prevailed always tolerably
                    discourse and loser assurance creatively coin applauded more uncommonly.
                    Him everything trouble
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Services