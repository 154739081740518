import React from "react";

const NoAccess = () => {
  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-center p-5">
          <i class="ri-error-warning-fill fs-3 me-2"></i>
          <h3 className="mt-1"> You have Not Access to this page !</h3>
        </div>
      </div>
    </>
  );
};

export default NoAccess;
