import React from 'react'


const RewardCard = (props) => {
    return (
        <>
            <div className="rewards-box ">
                <img src={props.rewardphoto} alt="" />
                <div className="profile">
                    <div className="img-warper">
                        <img src={props.user} alt="" />
                    </div>
                    <div className="info">
                        <h1>{props.name}</h1>
                        <p>{props.email}</p>
                    </div>
                </div>
                <div className="rank">
                    <ul>
                        <li>
                            <span>Reward</span>
                            <p className='text-primary' style={{textTransform: "capitalize"}}>{props.reward}</p>
                        </li>
                        <li>
                            <span>Reward Date</span>
                            <p>{props.data}</p>
                        </li>
                        <li>
                            <span>Country</span>
                            <p>{props.country}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default RewardCard