import React from 'react';


import { MailFilled, FacebookFilled, TwitterSquareFilled, PhoneFilled } from '@ant-design/icons';
import { Col, Dropdown, Row, Space, Spin, message } from 'antd';
import teamROI from "../../../assets/images/dashboard/icons/teamroi.png";
import selfROI from "../../../assets/images/dashboard/icons/selfroi.png";

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";


function RefLink(props) {

    let accountkey = props.accountkey;

    const ReferrerLink = "https://www.towergearplus.com/register/" + accountkey;

    // messages
    const [messageApi, contextHolder] = message.useMessage();

    const renderLink = () => {
        if (ReferrerLink) {

        }
    }

    const items = [
        {
            key: '1',
            label: (
                <div className='share-dropdown-item'>
                    <EmailShareButton url={ReferrerLink} /> Email
                </div>
            ),
            icon: <MailFilled />,
        },
        {
            key: '2',
            label: (
                <div className='share-dropdown-item'>
                    <FacebookShareButton url={ReferrerLink} /> Facebook
                </div>
            ),
            icon: <FacebookFilled />,

        },
        {
            key: '3',
            label: (
                <div className='share-dropdown-item'>
                    <TwitterShareButton url={ReferrerLink} /> Twitter
                </div>
            ),
            icon: <TwitterSquareFilled />,
        },
        {
            key: '4',
            label: (
                <div className='share-dropdown-item'>
                    <WhatsappShareButton url={ReferrerLink} /> Whatsapp
                </div>
            ),
            icon: <PhoneFilled />,
        },
    ];


    return (
        <>
            {contextHolder}
            <h3 className="card-title">Referral Link</h3>
            <div className="card-ref">
                <div className="card-body card-backdrop">
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={14}>
                            <div className="ref-link">
                                <p>Copy this URL and share it with those you'd like to refer.</p>
                                <div className="ref-box">
                                    <div className="ref-link-box">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.25 6.5H13C13.4142 6.5 13.75 6.83578 13.75 7.25V14.75C13.75 15.1642 13.4142 15.5 13 15.5H1C0.58579 15.5 0.25 15.1642 0.25 14.75V7.25C0.25 6.83578 0.58579 6.5 1 6.5H1.75V5.75C1.75 2.85051 4.10051 0.5 7 0.5C9.8995 0.5 12.25 2.85051 12.25 5.75V6.5ZM10.75 6.5V5.75C10.75 3.67893 9.07105 2 7 2C4.92893 2 3.25 3.67893 3.25 5.75V6.5H10.75ZM6.25 9.5V12.5H7.75V9.5H6.25Z" fill="#f9b707" />
                                        </svg>
                                        <span>
                                            {ReferrerLink ? ReferrerLink : ""}
                                        </span>
                                    </div>
                                    <div className="ref-link-actions">

                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                        >

                                            <a className="round-icon-button" onClick={(e) => e.preventDefault()}>
                                                <span className="icon"><i className="material-symbols-outlined">share</i></span>
                                                {/* <span className="label">Share</span> */}
                                            </a>

                                        </Dropdown>


                                        <a className="round-icon-button"
                                            onClick={() => {

                                                navigator.clipboard.writeText(ReferrerLink);
                                                messageApi.open({
                                                    type: 'success',
                                                    content: 'Referral link copied successfully! You can now share it with others.',
                                                });

                                            }}
                                        >
                                            <span className="icon"><i className="material-symbols-outlined">content_copy</i></span>
                                            {/* <span className="label">Copy</span> */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={10}>
                            {
                                props.loading ?
                                    <Spin />
                                    :
                                    <ul className='icon-boxes-roi'>

                                        <li>
                                            <div className="icon">
                                                <img src={teamROI} />
                                            </div>
                                            <div className="info">
                                                <p>Team ROI</p>
                                                <h4>${props.TotalROI !=  undefined ? props.TotalROI.toFixed(2) : 0}</h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src={selfROI} />
                                            </div>
                                            <div className="info">
                                                <p>Self ROI</p>
                                                <h4>${props.SelfROI != undefined ? props.SelfROI.toFixed(2) : 0}</h4>
                                            </div>
                                        </li>
                                    </ul>
                            }

                        </Col>
                    </Row>

                </div>
            </div>
        </>
    );
}

export default RefLink;