import React, { useEffect, useState } from "react";

import { Pagination, Spin, Empty } from 'antd';

import avatar from "../../../assets/images/avatar.png";
import NetworkMember from './NetworkMember';
import axios from "axios";
import config from "../../../Config";

function Network(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);

    const [members, setMembers] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPaid, settotalPaid] = useState(0);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        document.title = "Network";
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/ViewNetworkPage',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
            //    console.log(response.data);
                if (response.data.status_code == 1) {

                    setMembers(response.data.ListofMembers);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                    setHasData(true);

                }
                else {
                    setLoading(false);
                    setHasData(false);
                }


            })
            .catch(function (error) {

            });

    }, [pageNo, pageSize]);

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };



    const renderMembers = () => {
        if (members.length > 0) {
            return (
                members.map((item, index) => {
                    return (
                        <NetworkMember
                            key={index}
                            photo={config.img_path + item.Img_File}
                            name={item.Full_Name}
                            email={item.Email_Address}
                            type={item.Member_Type}
                            amount={item.Investment_Balance}
                            level={item.Hiera_IN_xx_NodeLevel}
                            ROI_Balance={item.ROI_Balance}
                            Profit_Received={item.Profit_Received}
                            TeamCount={item.TeamCount}
                        />
                    )
                })
            )
        }
    }

    return (
        <>
            <h3 className="card-title">Network</h3>

            {
                loading ?
                    <Spin />
                    :
                    <>
                        <div className="network-list">
                            {
                                hasData ?
                                    <ul>
                                        {renderMembers()}
                                    </ul>
                                    :
                                    <Empty />
                            }

                        </div>
                        <div className="pagination-wrap">

                            {totalRecords > 10 && (
                                <Pagination
                                    defaultCurrent={pageNo}
                                    showSizeChanger
                                    onShowSizeChange={onShowSizeChange}
                                    pageSize={pageSize}
                                    total={totalRecords}
                                    onChange={page => setPageNo(page)}
                                />
                            )}
                        </div>
                    </>
            }

        </>
    );
}

export default Network;