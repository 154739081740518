import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";


import TowergadRightsCheck from "../TowergadRightsCheck";
import { Spinner, Alert } from "react-bootstrap";



function RoiDashboard() {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [Date, SetDate] = useState("");
  const [Email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const [ResponseMessage, setResponseMessage] = useState(null);
  const [Variant, setVariant] = useState("");
  // loadings

  const [LoadingButton1, setLoadingButton1] = useState(false);
  const [LoadingButton2, setLoadingButton2] = useState(false);
  const [LoadingButton3, setLoadingButton3] = useState(false);
  const [LoadingButton4, setLoadingButton4] = useState(false);

  useEffect(() => {
    document.title = "Send ROI";
  }, []);


  const SendSelfROI = () => {


    setLoadingButton1(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: Date,
      Email_Address: Email,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/SendROI",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          setLoadingButton1(false);
          setShow(true);
          setVariant("success");
          setResponseMessage(response.data.status_message);
        } else {
          setLoadingButton1(false);
          setVariant("danger");
          setResponseMessage(response.data.status_message);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });


  }

  const SendTeamROI = () => {




    if (Date == "") {
      setResponseMessage("Please select date.");
      setVariant("danger");
      return false;
    }


    setLoadingButton2(true);


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: Date,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/SendTeamROI",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          setLoadingButton2(false);
          setShow(true);
          setVariant("success");
          setResponseMessage(response.data.status_message);
        } else {
          setLoadingButton2(false);
          setShow(true);
          setVariant("danger");
          setResponseMessage(response.data.status_message);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });


  }


  const DeductMysteryBox = () => {




    if (Date == "") {
      setResponseMessage("Please select date.");
      setVariant("danger");
      return false;
    }


    setLoadingButton3(true);


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: Date,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/DeductLuckyDraw",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          setLoadingButton3(false);
          setShow(true);
          setVariant("success");
          setResponseMessage(response.data.status_message);
        } else {
          setLoadingButton3(false);
          setShow(true);
          setVariant("danger");
          setResponseMessage(response.data.status_message);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });


  }


  const ResetDatabase = () => {


    const isConfirmed = window.confirm('Are you sure you want to reset the database?');

    if (isConfirmed) {
      setLoadingButton4(true);


      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Dated: Date,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Users/ResetDatabase",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data)
          if (response.data.status_code == 1) {
            setLoadingButton4(false);
            setShow(true);
            setVariant("success");
            setResponseMessage(response.data.status_message);
          } else {
            setLoadingButton4(false);
            setShow(true);
            setVariant("danger");
            setResponseMessage(response.data.status_message);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }






  }


  const handleCloseAlert = () => {
    setShow(false);
    setResponseMessage(null);
    setVariant("");
  }

  return (
    <>
      <TowergadRightsCheck />

      <PageTitle title="Send ROI" />
      <div className="admin-content">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="form-label">Date:</label>
                  <input type="date" onChange={(e) => SetDate(e.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label">Provide Email to send ROI to single person</label>
                  <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email Address" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {
                  ResponseMessage != null &&
                  <Alert key={Variant} variant={Variant} onClose={() => handleCloseAlert} dismissible>
                    {ResponseMessage}
                  </Alert>
                }



                <div className="d-flex justify-content-between flex-wrap">
                  {
                    LoadingButton1 ?
                      <Spinner />
                      :
                      <button className="button button-outline-primary" onClick={SendSelfROI}>Send Self ROI</button>
                  }

                  {
                    LoadingButton2 ?
                      <Spinner />
                      :
                      <button className="button button-outline-primary" onClick={SendTeamROI}>Send Team ROI</button>
                  }

                  {
                    LoadingButton3 ?
                      <Spinner />
                      :
                      <button className="button button-outline-primary" onClick={DeductMysteryBox}>Deduct Mystery Box</button>
                  }

                  {
                    LoadingButton4 ?
                      <Spinner />
                      :
                      <button className="button button-outline-primary" onClick={ResetDatabase}>Reset Database</button>
                  }





                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default RoiDashboard;
