import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
const OnlineSupport = () => {
  useEffect(() => {
    document.title = "Online Support";
}, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Online Support'/>

        <div className="website-section">
          <div className="auth-container terms-wrap">
            <h1>
              <b>Comming Soon</b>
            </h1>
           
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default OnlineSupport