import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import axios from "axios"
import config from '../../../Config';
import dayjs from "dayjs";
import { NavLink, useParams } from 'react-router-dom';
import TransactionsMenu from './TransactionsMenu';

import Pagination from "../../member/General/Pagination";

const TransSecond = () => {


  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [listOfTransactions, setlistOfTransactions] = useState([]);


  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();

  useEffect(() => {
    document.title = "ROI Self Transaction";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: params.id,
      pageNo: pageNo,
      pageSize: pageSize,
      LevelNo: 2,
      MemberType: "Admin"
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'ROI/GetTeamROI',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        //console.log("response",response.data);
        setlistOfTransactions(response.data.ListofLedger);
        setTotalRecords(response.data.totalRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });

  }, [pageNo, pageSize]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfTransactions != null && listOfTransactions.length > 0) {
      let count = 1;
      return listOfTransactions.map((item, index) => {
        //console.log(item);
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{dayjs(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{item.Full_Name}</td>
            <td>{item.Email_Address}</td>
            <td>{"$" + item.TradingAmount}</td>
            <td>{"$" + item.EligibleTradingAmount}</td>
            <td>{"$" + item.ROIAmount}</td>
            <td>{item.LevelNo}</td>
            <td>{item.Status}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Transactions</div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/towergad/userstatistics/Total-user">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <TransactionsMenu />
        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>	Trading Amount</th>
                  <th>Eligible Trading</th>
                  <th>Profit </th>
                  <th>Level No</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TransSecond