import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Input, Button, message, Space, Typography } from "antd";
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from "../../Config";
import Logo from "../../assets/images/logo/towergear_full_white.png";

import { generateRandomString } from "../member/General/Functions";
const Forgot = () => {
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const { Text, Link, Title } = Typography;

  useEffect(() => {
    document.title = "Forgot Password";
    setCaptcha(generateRandomString(6));
  }, []);

  const handleSubmit = (formData) => {

    setCaptcha(generateRandomString(6));

    if (formData.Captcha != captcha) {
      messageApi.open({
        type: "error",
        content: "Incorrect Captcha",
      });
    } else {
      setLoading(true);
      const signInData = {
        ...formData,
      };
      var api_config = {
        method: "post",
        url: config.base_url + "Members/ForgotPassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: signInData,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            messageApi.open({
              type: "success",
              content: response.data.status_message,
            });

            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 60000);
          } else {
            setIsButtonDisabled(false);
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error.",
          });
          //console.log("network ...");
        });
    }
  };

  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  };

  return (
    <>
      {contextHolder}
      <div className="auth-wrap-new">
        <div className="col-left">
          <div className="auth-form">
            <div className="card-black card-auth-form">
              <h2>Forgot Password</h2>
              <Form
                name="basic"
                layout="vertical"
                size="large"
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Email/Username/Member ID"
                  name="Email_Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input Email / Username /Member ID",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row gutter={10}>
                  <Col md={11} xs={24}>
                    <Form.Item>
                      <div className="Character-wrap">
                        <Input className="Character" value={captcha} readOnly />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col md={13} xs={24}>
                    <Form.Item
                      // label="Captcha"
                      name="Captcha"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please input Captcha",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Captcha" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="mb-2">
                  <NavLink onClick={UpdateCaptcha}>
                    Refresh The Captcha
                  </NavLink>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" shape="round" block loading={loading} disabled={isButtonDisabled}>Get Reset Link</Button>
                </Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  <span>Already Have An Account? </span>
                  <NavLink to="/login"> Sign In Here</NavLink>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-right">
          <div className="auth-right-content">
            <div className="auth-head">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className="auth-actions">
                <p>New to Tower Gear Plus?</p>
                <NavLink to="/register" className="button button-secondary button-round button-long">Join Now</NavLink>
              </div>
            </div>
            <br />
            <ul className="list-reasons">
              <li>
                <span>
                  01
                </span>
                <div>
                  <h4>Expertise</h4>
                  <p>Benefit from our seasoned professionals' market knowledge for better trading decisions.</p>
                </div>
              </li>
              <li>
                <span>
                  02
                </span>
                <div>
                  <h4>Cutting-Edge Tech</h4>
                  <p>Access advanced tools and resources to boost your trading performance.</p>
                </div>
              </li>
              <li>
                <span>
                  03
                </span>
                <div>
                  <h4>Global Network</h4>
                  <p>Expand your reach and explore international trading opportunities.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
};

export default Forgot;
