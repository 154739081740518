import React, { useState, useEffect } from "react";
import TransactionMenu from "./TransactionMenu";
import axios from "axios";
import config from "../../../Config";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";

const LuckyBox = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [loading, setLoading] = useState(false);
  const [listOfTransactions, setlistOfTransactions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    document.title = "Lucky Box Transaction";

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Mystry/GetLuckyBoxTransactions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setlistOfTransactions(response.data.ListofLedger);
        setTotalRecords(response.data.totalRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize]);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID", // Assuming 'row' is a field in your data source
      key: "id",
      //render: (_, record, index) => index + 1,
    },
    {
      title: "Transaction Date",
      dataIndex: "Transaction_Date",
      key: "Transaction_Date",
      render: (_, record) =>
        dayjs(record.Transaction_Date).format(config.date_format),
    },
    {
      title: "Reference Type",
      dataIndex: "Reference_Type",
      key: "Reference_Type",
    },
   
    {
      title: "Detail",
      dataIndex: "Details",
      key: "detail",
    },
    {
      title: "Debit",
      dataIndex: "Debit",
      key: "Debit",
      render: (_, record) =>(
        "$" + record.Debit
      )
    },
    {
      title: "Credit",
      dataIndex: "Credit",
      key: "credit",
      render: (_, record) =>(
        "$" + record.Credit
      )
    },

    
  ];

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <>
      <TransactionMenu active="lucky-box" />
      <Table
        dataSource={listOfTransactions}
        columns={columns}
        size="small"
        loading={loading}
        pagination={false}
      />
      <div className="pagination-wrap">
        {totalRecords > 10 && (
          <Pagination
            defaultCurrent={1}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSize={pageSize}
            total={totalRecords}
            onChange={(page) => setPageNo(page)}
          />
        )}
      </div>
    </>
  );
};

export default LuckyBox;
