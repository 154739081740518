import React, { useEffect, useState } from "react";
import RanksMenu from './RanksMenu';
import { Col, Row, Spin, Empty } from 'antd';
import MysteryCard from "./MysteryCard";
import dayjs from "dayjs";


import avatar1 from "../../../assets/images/avatar/1.png";
import avatar2 from "../../../assets/images/avatar/2.png";
import avatar3 from "../../../assets/images/avatar/3.png";
import united_states from '../../../assets/images/flag/united-states.png';
import philippines from '../../../assets/images/flag/philippines.png';
import australia from '../../../assets/images/flag/australia.png';
import config from "../../../Config";
import axios from "axios";
const MysteryBox = () => {


  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  const [box, setBox] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Tower Mystry Box";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      // pageNo: 1,
      // pageSize: 10,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Mystry/GetAllMystryBox",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setBox(response.data.ListofLedger);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          setLoading(false);
          // navigate("/towergad/login");
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (box != null && box.length > 0) {
      return box.map((item, index) => {

        return (
          <>
            <Col xs={24} md={{ span: 8, }}>
              <MysteryCard
                flag={config.images + "flags/" + item.Extra1}
                country_name={item.Country}
                name={item.Full_Name}
                email={item.Email_Address}
                deduction={item.DeductionAmount}
                winner={item.ProfitAmount}
                photo={config.img_path + item.Img_File}
                dated={dayjs(item.Dated).format(config.date_format)}
              />
            </Col>


          </>
        );
      });
    } else {
      return (
        <Col xs={24} md={{ span: 8, offset: 8 }} textAlign={"middle"}>
          <Empty />
        </Col>
      );
    }
  };

  return (
    <>
      <RanksMenu active="Mystery" />
      <div className='ranks-bg'>
        <div className='overlay'>
          <h3 style={{ textAlign: "center", margin: "0 0 15px 0", }}>Mystery Box Winners</h3>
          <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: ".8rem", color: "rgba(255,255,255,.6)" }}>The total deduction amount signifies the collective investment made by enthusiastic participants. Each deduction adds to the anticipation, making the
            Mystery Box prizes all the more thrilling.</p>
          <Row className="mt-5" gutter={[24, 24]}>
            {loading ? (
              <Spin />
            ) : (
              <>{renderLedger()}</>
            )}
          </Row>
        </div>
      </div>
    </>
  )
}

export default MysteryBox