import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../../Config';



function WalletsDropdown() {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [listofWallets, setlistofWallets] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/GetWallets',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    setlistofWallets(response.data.ListofWallets);
                    setLoading(false);
                } else {
                    // Handle error
                    
                }
                //   setLoading(false);
            })
            .catch(function (error) {
              
                //console.log(error);
            });

    }, []);

    if (!loading) {

        let defaultWallets = listofWallets.filter((item) => item.IsDefault == true);

        // Return the array of objects in the specified format
        const result = defaultWallets.map((item) => ({
            value: item.ID,
            label: item.Nick,
        }));

        return result;

    }
    else {
        return false;
    }



   
}

export default WalletsDropdown;
