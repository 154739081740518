import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import house from "../../../../assets/images/landing/house.jpg";
import Wealth from "../../../../assets/images/landing/Wealth.jpg";
import home from "../../../../assets/images/landing/home.jpg";
import { Row, Col } from 'antd';

const Property = () => {
  useEffect(() => {
    document.title = "Property";
  }, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Property' />

        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'} row-rewa>
              <Col md={{ span: "12" }} xs={24}>
                <div className="section-title">
                  <h3>
                    HOW CAN WE ATTAIN WEALTH THROUGH REAL ESTATE?
                  </h3>
                </div>
                <div className="section-detail">
                  Unlock wealth through property. Invest wisely, earn passive income, and build equity. Your path to financial freedom starts here.
                  <br />
                  The key to prosperity. Secure assets, capitalize on growth, and diversify investments. Harness the power of real estate for lasting wealth.
                </div>
              </Col>
              <Col md={{ span: "12" }} xs={24}>
                <img src={Wealth} alt="" style={{ borderRadius: "10px" }} />
              </Col>
            </Row>
          </div>
        </div>

        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'}>
              <Col md={{ span: "12", order: "1" }} xs={{ span: 24, order: "2" }}>
                <img src={home} alt="" style={{ borderRadius: "10px" }} />
              </Col>
              <Col md={{ span: "12", order: "1" }} xs={{ span: 24, order: "1" }}>
                <div className="section-title">
                  <h3>
                    About Property
                  </h3>
                </div>
                <div className="section-detail">
                  We're here to show you how properties can be your ideal investment source and more. Our mission is to help you discover the incredible potential of real estate.
                  <br />
                  Owning a property isn't just about having a place to call home; it's also a powerful investment opportunity. Real estate has a proven track record of building wealth over time. With us, you'll learn how to make smart, informed decisions that will secure your financial future.
                </div>
              </Col>

            </Row>
          </div>
        </div>
        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}

              align={'middle'}>
              <Col md={{ span: "12" }}>

                <div className="section-title">
                  <h3>
                    Why Is a Home Essential for Us
                  </h3>
                </div>
                <div className="section-detail">
                  Home is where memories are made, dreams take shape, and your journey unfolds. It's your sanctuary, your canvas your legacy. <br /><br />
                  Home, a universal necessity, isn't limited to humans alone. Lions, birds, and all creatures seek refuge and a place to thrive.
                </div>
                <ul className='check_list mt-4'>
                  <li>
                    <span class="material-symbols-outlined">
                      check
                    </span>
                    Stability and Security
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      check
                    </span>
                    Investment and Financial Security
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      check
                    </span>
                    Personal Space and Privacy
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      check
                    </span>
                    Community and Social Connections
                  </li>
                </ul>
                <button className="button button-primary mt-3">
                  About Us
                </button>
              </Col>
              <Col md={{ span: "12" }}>
                <img src={house} alt="" style={{ borderRadius: "10px" }} />
              </Col>
            </Row>


          </div>
        </div>
        <Footer />
      </div >
    </>
  )
}

export default Property