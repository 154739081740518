import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Button, Col, Card, Form, Input, Row, Select, Result, Space, Drawer, Steps, QRCode, Spin, message } from "antd";
import { CreditCardOutlined, UserOutlined, LoadingOutlined, SmileOutlined, SolutionOutlined } from '@ant-design/icons';

import axios from "axios";
import config from "../../../Config";
import { NavLink } from "react-router-dom";
import { NumberFormat } from "../General/Functions";

function Deposit(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);

    const [selectedMode, setSelectedMode] = useState(null);
    const [TargetCurrency, setTargetCryptoCurrency] = useState(null);
    const [Converted, setConverted] = useState(null);
    const [EstimatedAmount, setEstimatedAmount] = useState(0);

    const [Order, setOrder] = useState({});

    const [messageApi, contextHolder] = message.useMessage();

    // steps
    const [step, setStep] = useState(0);

    // loadings
    const [loadingButtonCrypto, setLoadingButtonCrypto] = useState(false);

    const ReferrerLink = localStorage.getItem("ReferrerLink");

    useEffect(()=>{
        document.title= "Add Money"

    },[])


    const ConvertUSDToCryptoValue = (value) => {

        setLoadingButtonCrypto(true);
        setSelectedMode(value);

        setTargetCryptoCurrency(value);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoOfUSD: amount,
            TargetCryptoCurrency: value
        };


        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/ConvertUSDToCryptoValue',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setConverted(response.data);
                    setEstimatedAmount(response.data.EstimatedAmount);
                    //setStep(2);
                    setLoadingButtonCrypto(false);
                }


            })
            .catch(function (error) {
                //console.log(error);
            });


    }

    const CreateCryptoOrder = () => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoOfUSD: amount,
            TargetCryptoCurrency: TargetCurrency,
            EstimatedAmount: EstimatedAmount
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/CreateCryptoOrder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    //console.log(response.data)
                    setOrder(response.data);
                    setStep(2);
                    setLoading(false);
                    //console.log(loading)
                }
                else {
                    setLoading(false);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });


    }


    const renderSteps = () => {
        if (step == 0) {
            return (
                <>
                    <div className="bt_box">
                        <h1>Enter Amount to Deposit</h1>
                        <p>Follow the instructions to add funds into your account.<br />
                            In the next step, select the wallet to deposit funds into the platform.
                            <br />
                            Minimum Deposit: $50
                        </p>


                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="form_default">
                                    <div className="form-group bt_input_group">
                                        <div className="inputwrap">
                                            <input type="number"
                                                min={50}
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    amount < 50 &&
                                    <p class="Resend" style={{ color: "red", marginTop: "6px" }}> Minimum amount: $50</p>
                                }
                            </div>
                        </div>
                        <ul className="bt_amounts">
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(100))}>+ 100</span></li>
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(500))}>+ 500</span></li>
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(1000))}>+ 1000</span></li>
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(5000))}>+ 5000</span></li>
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(10000))}>+ 10000</span></li>
                            <li><span onClick={() => setAmount(parseInt(amount) + parseInt(20000))}>+ 20000</span></li>
                            <li>
                                <span className="clear" onClick={() => setAmount(0)}>
                                    <i className="material-symbols-outlined">backspace</i>
                                    Clear
                                </span>
                            </li>
                        </ul>

                        {
                            amount >= 50 &&
                            <>
                                {/* <h4 className="text-center mt-5 mb-3 text-uppercase">Payment Mode</h4> */}
                                <div className="text-center mt-5 mb-3">
                                    <Button type="primary"
                                        shape="round"
                                        size="large"
                                        onClick={(e) => setStep(1)}
                                    >NEXT</Button>
                                </div>
                            </>
                        }
                    </div>
                </>
            )
        }
        else if (step == 1) {
            return (
                <>
                    <Row>
                        <Col md={{span:8, offset:8}} xs={24}>
                            <Card
                                title="Payment Mode"
                                bordered={false}
                                style={{ width: "100%" }}
                                extra={
                                    loadingButtonCrypto ?
                                        <Spin />
                                        :
                                        ""
                                }
                            >
                                <Form
                                    size="large"
                                    layout="vertical"
                                >
                                    <Row gutter={[20, 0]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Payment Mode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select Payment Mode',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    defaultValue="Select Payment Mode"
                                                    onChange={ConvertUSDToCryptoValue}
                                                    loading={loadingButtonCrypto}
                                                    options={[
                                                        {
                                                            value: 'BTC',
                                                            label: 'Bitcoin - BTC',
                                                        },
                                                        {
                                                            value: 'ETH',
                                                            label: 'Ethereum (ERC-20) - ETH',
                                                        },
                                                        {
                                                            value: 'USDTTRC20',
                                                            label: 'TRC20 - USDT',
                                                        },
                                                        {
                                                            value: 'TRX',
                                                            label: 'TRON - TRX',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            {
                                                Converted != null &&
                                                <>
                                                    <ul className='list-info-between'>
                                                        <li>
                                                            <span className="list-label">Amount in USD:</span>
                                                            <span className='list-value'>$ {NumberFormat(amount)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="list-label">Deposit in {selectedMode}:</span>
                                                            <span className='list-value'>{Converted.EstimatedAmount}</span>
                                                        </li>
                                                    </ul>

                                                </>

                                            }
                                            <Row gutter={15}>
                                                <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }}>
                                                    <Button type="default" block
                                                        onClick={(e) => {
                                                            setStep(0);
                                                            setConverted(null);
                                                        }}
                                                    >BACK</Button>
                                                </Col>
                                                {
                                                    Converted != null &&
                                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                                                        <Button htmlType="submit" type="primary" loading={loading} block
                                                            onClick={CreateCryptoOrder}>Get wallet Address & Deposit</Button>
                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </>
            )
        }
        else if (step == 2) {
            return (
                <>
                    <Row gutter={20} className="mb-5">
                        <Col md={12} xs={24}>
                            <div className="crypto_order">
                                <h2>Deposit <span style={{ fontSize: "20px" }}>{Order.Amount}</span> {Order.Pay_Currency} into below Address</h2>
                                <p className="sub-heading">
                                    Scan the below QR or Deposit the {Order.Amount} {Order.Pay_Currency} into the below address and wait for 1 Confirmation in the network.
                                </p>

                                <div style={{ height: "auto", margin: "0 auto", padding: "20px", maxWidth: 204, width: "100%" }}>
                                    <QRCode
                                        value={Order.Pay_Address}
                                    />
                                    {/* <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={Order.Pay_Address}
                                viewBox={`0 0 256 256`}
                            /> */}
                                </div>
                            </div>
                        </Col>
                        <Col md={10} xs={24} align={'middle'}>

                            <Card
                                title="Payment Mode"
                                bordered={false}
                                style={{ width: "100%" }}
                            >

                                <div className="card-ref">
                                    <div className="card-body card-backdrop">
                                        <div className="ref-link">
                                            <p> <span className="text-primary">Note:</span> Sending Coin or token other than {Order.Pay_Currency} to this address may result in the loss of your deposit</p>
                                            <div className="ref-link-box">
                                                <span style={{ color: "#f9b707", margin: "0" }} class="material-symbols-outlined">
                                                    currency_bitcoin
                                                </span>

                                                <span>
                                                    {Order.Pay_Address.length > 35
                                                        ? `${Order.Pay_Address.substring(0, 35)}...`
                                                        : Order.Pay_Address}
                                                </span>
                                            </div>
                                            <div className="ref-link-actions">
                                                <a className="round-icon-button"
                                                    onClick={() => {
                                                        setOrder({});
                                                        setStep(1);
                                                        setConverted(null);
                                                    }}
                                                >
                                                    <span className="icon"><i className="material-symbols-outlined">attach_money</i></span>
                                                    <span className="label">New Deposit</span>
                                                </a>
                                                <a className="round-icon-button"
                                                    onClick={() => {
                                                        setStep(3);
                                                    }}
                                                >
                                                    <span className="icon"><i className="material-symbols-outlined">sync_alt</i></span>
                                                    <span className="label">Transfered</span>
                                                </a>
                                                <a className="round-icon-button"
                                                    onClick={() => {

                                                        navigator.clipboard.writeText(Order.Pay_Address);
                                                        messageApi.open({
                                                            type: 'success',
                                                            content: "Address Copied Successfully",
                                                        });

                                                    }}
                                                >
                                                    <span className="icon"><i className="material-symbols-outlined">content_copy</i></span>
                                                    <span className="label">Copy</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row >
                </>
            )
        }
        else if (step == 3) {
            return (
                <>
                    <Row>
                        <Col md={{ span: 20, offset: 2 }} xs={24}>
                            <Card
                                title="Payment Mode"
                                bordered={false}
                                style={{ width: "100%" }}
                            >
                                <div className="text-center">
                                    <p>
                                        If you have deposited the amount into the wallet address displayed in the
                                        previous step, you can close this window now. After one successful network
                                        confirmation, the amount will be automatically credited to your Tower Gear Plus Account in
                                        Dollars($).

                                    </p>
                                    <NavLink to="/member/transactions/deposits">
                                        <Button>Check Status</Button>
                                    </NavLink>
                                </div>
                            </Card>
                        </Col>
                    </Row >
                </>
            )
        }
    }

    return (
        <>
            {contextHolder}
            <h3 className="card-title">Add Money</h3>
            <div className="add-money-wrap">

                <Steps

                    current={step}
                    items={[
                        {
                            title: 'Amount',
                            icon: <UserOutlined />,

                        },
                        {
                            title: 'Select Mode',
                            icon: <CreditCardOutlined />,
                        },
                        {
                            title: 'Get Address & Deposit',
                            icon: <SolutionOutlined />,
                        },
                        {
                            title: 'Done',
                            icon: <SmileOutlined />,
                        },
                    ]}
                />

                <div style={{ marginTop: "50px" }}>

                    {renderSteps()}
                </div>
            </div>
        </>
    );
}

export default Deposit;