import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const SettingsDashboard = () => {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [settingsList, setSettingsList] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settingVal, setSettingVal] = useState("");

  useEffect(() => {
    document.title = "Settings";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetSettings",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setSettingsList(response.data.ListofRecords);
          setLoading(false);
          //   setTotalRecords(response.data.totalRecords);
          ////console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          //   navigate("/towergad/login");
        } else {
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [isUpdated]);

  const handleUpdate = (id) => {
    setIsUpdated(false);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: id,
      SettingValue: settingVal,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/UpdateSetting",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // setSettingsList(response.data.ListofRecords);
          setIsUpdated(true);

          // setLoading(false);
          //   setTotalRecords(response.data.totalRecords);
          ////console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          setIsUpdated(false);

          //   navigate("/towergad/login");
        } else {
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const renderLedger = () => {
    if (settingsList != null && settingsList.length > 0) {
      let count = 1;
      return settingsList.map((item, index) => {
        //console.log(item);
        return (
          <div className="form_default" key={index}>
            <div
              className="row p-3"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="col-md-4 d-flex">
                <label style={{ width: "10vw" }}>{item.Name}</label>
                <div className="form-group">
                  <div className="inputwrap   ">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={item.Value}
                      onChange={(e) => setSettingVal(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2  ">
                <button
                  onClick={() => handleUpdate(item.ID)}
                  className="button button-primary"
                  style={{
                    fontWeight: "500",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Settings</div>
          </div>
          <div className="card card-table mb-3 mt-4  ">
            <div className="card-inner">
              {loading ? (
                <tr>
                  <td className="text-center" colspan="10">
                    <Spinner animation="border" role="status"></Spinner>
                  </td>
                </tr>
              ) : (
                <>{renderLedger()}</>
              )}
            </div>
          </div>
        </div>
      </div>
     
  );
};

export default SettingsDashboard;
