import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

import config from "../../../Config";
import UserGroupDropDown from "./UserGroupDropDown";
import { useParams } from "react-router-dom";
import TowergadRightsCheck from "../TowergadRightsCheck";
import { Eye_icon, Eye_Off } from "../../member/UserControls/Icons";

const UpdateUser = () => {
  let params = useParams();

  let navigate = useNavigate();

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [Id, setId] = useState(params.id);

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState("");
  const [fullName, setfullName] = useState();
  const [password, setpassword] = useState("");
  const [group, setgroup] = useState();
  const [retype_password, setretype_password] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [needToChangePassword, setNeedToChangePassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [User, setUser] = useState({});

  useEffect(() => {
    document.title = "Update User";
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Id,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Security/GetUserByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setUser(response.data);
          setgroup(response.data.Group_ID);
          setfullName(response.data.Full_Name);
          setemail(response.data.Email_Address);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);

      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Id,
      Email_Address: email,
      Password: password,
      Group_ID: group,
      Full_Name: fullName,
    };

    if (!isValidEmail || !passwordsMatch) {


    }
    else {
      var api_config = {
        method: "post",
        url: config.base_url + "Security/UpdateUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {


            setTimeout(() => {
              navigate("/towergad/security/manage-users");
            }, 2000);
          } else if (response.data.status_code == 2) {

          } else {

          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);

        });

      // setValidated(true);
    }

  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNeedToChangePassword(true);
    setpassword(newPassword);
    setPasswordsMatch(newPassword === retype_password);
    setDisabled(newPassword !== retype_password);
  };

  const handleRetypePasswordChange = (e) => {
    const newRetypedPassword = e.target.value;
    setretype_password(newRetypedPassword);
    setPasswordsMatch(password === newRetypedPassword);
    setDisabled(newRetypedPassword !== password);
  };

  const validateEmail = (email) => {
    // You can implement your own email validation logic here
    // For simplicity, let's use a basic regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const checkEmail = () => {
    setIsValidEmail(validateEmail(email));
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>

      <TowergadRightsCheck />

      <div className="admin-content">
        <div className="row align-items-center mb-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Update User
            </div>
          </div>
          <div className="col-md-6 text-end">
            <NavLink to="/towergad/security/manage-users">
              <button type="button" className="button button-primary">
                Back
              </button>
            </NavLink>
          </div>
        </div>

        <div className="card card-table form_default">
          <form onSubmit={handleSubmit}>
            <div className="card-inner p-4 form-group">
              <div className="row gy-3">
                <div className="col-md-3">
                  <h6 className="">Full Name</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={User.Full_Name}
                      onChange={(e) => setfullName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <h6 className="">Email Address</h6>
                  <div className="inputwrap">
                    <input
                      type="email"
                      className="form-control"
                      defaultValue={User.Email_Address}
                      onChange={handleEmailChange}
                      onBlur={checkEmail}
                      required
                    />
                  </div>

                  {!isValidEmail && <div style={{ fontSize: "12px", color: "red" }}>Invalid email address</div>}
                </div>

                <div className="col-md-3">
                  <h6 className="">Password</h6>
                  <div className="inputwrap input-icon">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      defaultValue={password}
                      onChange={handlePasswordChange}
                    // maxLength={18}
                    />
                    <div className="lock-icon" onClick={togglePasswordVisibility} >
                      {
                        showPassword ?
                          <span class="material-symbols-outlined">visibility_off</span>
                          :
                          <span class="material-symbols-outlined">visibility</span>
                      }
                    </div>

                  </div>
                  {!needToChangePassword && (
                    <div style={{ fontSize: "12px", color: "red" }}>To Change Password Provide it.</div>
                  )}
                </div>

                <div className="col-md-3">
                  <h6 className="">Retype Password</h6>
                  <div className="inputwrap input-icon">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      defaultValue={retype_password}
                      onChange={handleRetypePasswordChange}
                    // maxLength={18}
                    />
                    <div className="lock-icon" onClick={toggleConfirmPasswordVisibility} >
                      {
                        showConfirmPassword ?
                          <span class="material-symbols-outlined">visibility_off</span>
                          :
                          <span class="material-symbols-outlined">visibility</span>
                      }
                    </div>
                  </div>
                  {retype_password.length !== 0 && !passwordsMatch && (
                    <div style={{ fontSize: "12px", color: "red" }}>Passwords do not match</div>
                  )}

                </div>
                <div className="col-md-3">
                  <h6 className="">Select User Group</h6>
                  <div className="inputwrap">
                    <select
                      id="dropdown"
                      className="form-select"
                      //  required
                      onChange={(e) => setgroup(e.target.value)}
                    >
                      <UserGroupDropDown selected={User.Group_ID} />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-7">
                  <button
                    type="submit"
                    className="button button-primary mt-4 w-100"
                    disabled={disabled || !isValidEmail}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateUser;
