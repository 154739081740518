import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Result, Space, Drawer, Steps, Table, message } from "antd";
import { CreditCardOutlined, UserOutlined, LoadingOutlined, SmileOutlined, SolutionOutlined } from '@ant-design/icons';
import iconBalance from "../../../assets/images/dashboard/icons/balance.svg";
import imgellipseRed from "../../../assets/images/dashboard/ellipse-red.svg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from "../../../Config";
import SendEmail from "../shared/SendEmail";
import MemberInfo from "../shared/MemberInfo";
import dayjs from "dayjs";


function Transfer(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Full_Name = localStorage.getItem("Full_Name");
    const Email_Address = localStorage.getItem("Email_Address");
    const [user, setUser] = useState({});
    
    const [amountToTransfer, setAmountToTransfer] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(60); // 60 seconds
    const [IsValidMember, setIsValidMember] = useState(null);
    const [RemainingBalance, setRemainingBalance] = useState(0);
    const [TransferData, setTransferData] = useState({});

    // steps
    const [step, setStep] = useState(0);


    // messages
    const [messageApi, contextHolder] = message.useMessage();


    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingButton1, setLoadingButton1] = useState(false);
    const [loadingButton2, setLoadingButton2] = useState(false);

     // pagination 
     const [pageNo, setPageNo] = useState(1);
     const [pageSize, setPageSize] = useState(10);
     const [totalRecords, setTotalRecords] = useState(0);
     const [listOfTransactions, setlistOfTransactions] = useState([]);
     const [CommissionPercentage, setCommissionPercentage] = useState(0);
  
    const member = MemberInfo();

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        setStep(0);
    };




    const onFinish = (formData) => {

        setLoadingButton1(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ...formData
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetBalanceTransferInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
              console.log(response.data);
                if (response.data.status_code == 1) {
                    setUser(response.data);
                    setStep(1);
                    setLoadingButton1(false);
                    setIsValidMember(true);

                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });

                    setIsValidMember(false);
                    setLoadingButton1(false);
                }

                setLoading(false);


            })
            .catch(function (error) {
                //console.log(error);
            });

    };

    const onFinishAmount = (formData) => {
        setAmountToTransfer(formData.Amount);

        let amount = formData.Amount;
        let final = (user.Commission_Percentage / 100) * amount;
        
        let remaining_balance = (member.Current_Balance - final) - amount;
        
        setRemainingBalance(remaining_balance.toFixed(2));
        setStep(2);
    };

    const onFinishReview = (formData) => {

        setLoadingButton2(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Email_Address: user.Email_Address,
            Current_Balance: amountToTransfer,
            Member_Type: "balance",
            ...formData
        };

        ////console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/BalanceTransfer',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                console.log(response.data);
                if (response.data.status_code == 1) {
                    setTransferData(response.data);
                    setStep(3);
                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                    setLoadingButton2(false);
                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoadingButton2(false);
                }



            })
            .catch(function (error) {
                //console.log(error);
            });
        //setStep(3);
    };


  


   


    const handleSendCode = () => {
        const props = {
            TransactionType: 'BalanceTransfer'
        };


        if(SendEmail(props)) {
            setIsButtonDisabled(true);

            messageApi.open({
                type: 'success',
                content: 'Authentication code has been sent',
            });
        }   
        else {
            setIsButtonDisabled(false);

            messageApi.open({
                type: 'error',
                content: 'Fail to send Authentication code',
            });
        }

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    }



    const renderSteps = () => {
        if (step == 0) {
            return (
                <Form
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                >

                    <Form.Item
                        name="Email_Address"
                        label="Enter Receiver ID / Email"
                        validateStatus={IsValidMember === null ? '' : 'error'}
                        hasFeedback
                        // help={IsValidMember === null ? '' : 'Normal Maintenance in Process. Please Check back Later.'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter receiver id or email',
                            },
                        ]}
                    >
                        <Input  />
                    </Form.Item>

                    <div style={{ textAlign: "right" }}>
                        <Button htmlType="submit" type="primary" loading={loadingButton1}>Fetch Title</Button>
                    </div>

                </Form>
            )
        }
        else if (step == 1) {
            return (
                <Form
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    size="large"
                    onFinish={onFinishAmount}
                >
                    <ul className="bt_info_head">
                        <li className="bt_avb">
                            <p>Available Balance:</p>
                            <h4>${member.Current_Balance}</h4>
                        </li>
                        <li>
                            <p>From:</p>
                            <h4>{member.Full_Name}</h4>
                            <span>{member.Email_Address}</span>
                        </li>
                        <li>
                            <p>To:</p>
                            <h4>{user.FullName}</h4>
                            <span>{user.Email_Address}</span>
                        </li>

                    </ul>
                    <Form.Item
                        name="Amount"
                        label="Enter Amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter receiver id or email',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || parseFloat(value) <= parseFloat(member.Current_Balance)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Entered amount is greater than the current balance');
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>


                    <div style={{ textAlign: "right" }}>
                        <Button onClick={() => setStep(0)} type="default" className="mr-3">Back</Button>
                        <Button htmlType="submit" type="primary">Continue</Button>
                    </div>
                </Form>
            )
        }
        else if (step == 2) {
            return (
                <>
                    <ul className="bt_review">
                        <li>
                            <div className="bt_review_label">Current Balance</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">${member.Current_Balance}</div>
                        </li>
                        <li>
                            <div className="bt_review_label">From</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">{member.Full_Name}</div>
                        </li>
                        <li>
                            <div className="bt_review_label">To</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">{user.FullName}</div>
                        </li>
                        <li>
                            <div className="bt_review_label">Amount</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">${amountToTransfer}</div>
                        </li>
                        <li>
                            <div className="bt_review_label">Transaction Charges</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">{user.Commission_Percentage}%</div>
                        </li>
                        <li>
                            <div className="bt_review_label">Remaining Balance</div>
                            <div className="bt_review_line"></div>
                            <div className="bt_review_value">${RemainingBalance}</div>
                        </li>
                    </ul>

                    <Form
                        name="normal_login"
                        className="login-form"
                        layout="vertical"
                        size="large"
                        onFinish={onFinishReview}
                    >

                        <Form.Item
                            name="Extra2"
                            label="Notes"
                        >
                            <Input placeholder="Description" />
                        </Form.Item>

                        <Form.Item
                            name="AuthorizationCode"
                            label="Authentication Code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Authentication Code',
                                },
                            ]}
                        >
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input  />
                                <Button type="default"
                                    onClick={handleSendCode}
                                    disabled={isButtonDisabled}
                                >GET CODE</Button>
                            </Space.Compact>
                        </Form.Item>


                        <Form.Item
                            name="PINCode"
                            label="Enter PIN to confirm transaction"
                            rules={[
                                {
                                  required: true,
                                  message: 'Please enter your 6-digit pin.',
                                },
                                {
                                  pattern: /^\d{6}$/,
                                  message: 'Please enter a 6-digit number.',
                                },
                              ]}
                        >
                            <Input maxLength={6} showCount />
                        </Form.Item>

                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => setStep(1)} type="default" className="mr-3">Back</Button>
                            <Button
                                htmlType="submit" 
                                type="primary" 
                                loading={loadingButton2}
                                disabled={RemainingBalance < 0}
                                >Confirm Transfer</Button>
                        </div>
                    </Form>
                </>
            )
        }
        else if (step == 3) {
            return (
                <>
                    <Result
                        status="success"
                        title="Transaction Successful"
                        subTitle="Your payment transfer has been completed successfully. Thank you for using our service."
                        extra={[
                            <div key="custom-content">
                                <h4>Amount: {TransferData.Amount}</h4>
                                <h4>Transaction ID: {TransferData.TransactionID}</h4>
                            </div>,
                            <Button type="primary" onClick={() => setStep(0)} key="console">
                                New Transaction
                            </Button>,
                            <NavLink to="/member/transactions/transfers"><Button key="buy">View Transactions</Button></NavLink>,
                        ]}
                    />
                </>
            )
        }
    }


   

    useEffect(() => {
        document.title = "Transaction of Tower Tradings";


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/BalanceTrasnferDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setlistOfTransactions(response.data.listofTransfers);
                //setLedgerOld(response.data.ListofPackages);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'Payment_ID',
            key: 'paymentid',
        },
        
        {
            title: 'Date',
            dataIndex: 'Created_Date',
            key: 'Created_Date',
            render: (_, record) => (
                dayjs(record.Created_Date).format(config.date_format)
            )
        },
        {
            title: 'From',
            dataIndex: 'User_Name',
            key: 'User_Name',
        },
        {
            title: 'To',
            dataIndex: 'Beneficiary_Name',
            key: 'Beneficiary_Name',
        },
        {
            title: 'Amount',
            dataIndex: 'Transaction_Amount',
            key: 'Transaction_Amount',
            render: (_, record) => (
                '$' + record.Receiveable_Amount
            )
        },
        {
            title: 'Fee',
            dataIndex: 'Commission_Amount',
            key: 'fee',
        },
        {
            title: 'Receivable Amount',
            dataIndex: 'Receiveable_Amount',
            key: 'Receiveable_Amount',
            render: (_, record) => (
                '$' + record.Receiveable_Amount
            )
        },
        {
            title: 'Notes',
            dataIndex: 'Notes',
            key: 'Notes',
            
        },
    ];


    return (
        <>
            {contextHolder}
            <Row align={'top'} gutter={[0,50]}>
                <Col xs={24} md={8}>
                    <div className="card-profit mt-4 card-bottom-shadow">
                        <div className="card-body card-backdrop">
                           
                            <div className="position-relative">
                                <p>Your Current Balance is</p>
                                <h2>${member.Current_Balance}</h2>
                                <br />
                                <div className="action_box">
                                    <a className="round-icon-button" onClick={showDrawer}>
                                        <span className="icon"><i className="material-symbols-outlined">north_east</i></span>
                                        <span className="label">Start Transfer</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 15, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                    <div className="card-action-title mb-sm-2">
                        <h3 className="card-title">Recent Transactions</h3>
                        <div className="card-actions">
                            <a className="button button-round  button-outline-primary button-long button-small">View All</a>
                        </div>
                    </div>

                    <Table
                        dataSource={listOfTransactions}
                        columns={columns}
                        size="small"
                        pagination={false} />
                </Col>

            </Row>

            <Drawer
                title="Balance Transfer"
                width="100%"
                placement="right"
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <div className="btm_wrap">
                    <Steps
                        current={step}
                        items={[
                            {
                                title: 'Account',
                                icon: <UserOutlined />,
                            },
                            {
                                title: 'Amount',
                                icon: <CreditCardOutlined />,
                            },
                            {
                                title: 'Review',
                                icon: <SolutionOutlined />,
                            },
                            {
                                title: 'Done',
                                icon: <SmileOutlined />,
                            },
                        ]}
                    />

                    <div className="btm_wrap_content">
                        {renderSteps()}
                    </div>


                    <ul className="bt_instructions">
                        <li>
                            <h4>Check Recipient Details Carefully:</h4>
                            <p>Before initiating the transfer, double-check the recipient's account details such as account number, name, and bank information. Any inaccuracies can result in funds being sent to the wrong recipient, and it may be challenging to recover the funds.</p>
                        </li>
                        <li>
                            <h4>Verify Transaction Amount:</h4>
                            <p>Ensure that the amount you're transferring is accurate and matches your intended transaction. Be cautious of any additional fees or charges that may apply, and make sure you have sufficient funds in your account to cover the transfer.</p>
                        </li>
                        <li>
                            <h4>Review Transfer Notes:</h4>
                            <p>If there is an option to add a note or description to the transfer, use it to specify the purpose of the payment. This can be especially important for record-keeping and can help both you and the recipient identify the transaction.</p>
                        </li>
                        <li>
                            <h4>Keep Transaction Records:</h4>
                            <p>Save confirmation receipts, transaction reference numbers, or any other proof of the transaction. It's a good practice to maintain a record of your payment transfers for future reference and dispute resolution, if needed.</p>
                        </li>
                    </ul>


                </div>
            </Drawer>

        </>
    );
}

export default Transfer;