import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Input, Button, message, Space } from "antd";
import ProfileMenu from "./ProfileMenu";
import LoginCheck from "../shared/LoginCheck";
import axios from "axios";
import config from "../../../Config";
import SendEmail from "../shared/SendEmail";

function ChangePassword(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

  const [loading, setLoading] = useState(false);
  const [codeloading, setcodeloading] = useState(false);

  // messages
  const [messageApi, contextHolder] = message.useMessage();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds

  useEffect(() => {
    document.title = "Change Password";
  }, []);

  const [authCode, setAuthCode] = useState("");
  const [authCodeValid, setAuthCodeValid] = useState(true); // To track if the code is valid
  const [OTP, setOTP] = useState("");

  //   const AuthorizeCode = (formData) => {
  //     setLoading(true);

  //     const data = {
  //       UserID: UserID,
  //       AccessKey: AccessKey,
  //       ...formData,
  //     };

  //     const dataa = {
  //       AccessKey: AccessKey,
  //       UserID: UserID,
  //       //OldPin: OldPin,
  //       NewPin: OTP,
  //       //   AuthorizationCode:authCode,
  //     };

  //     ////console.log(data);

  //     var api_config = {
  //       method: "post",
  //       url: config.base_url + "Members/ChangeSmartPass",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(api_config)
  //       .then(function (response) {
  //         ////console.log(response.data);
  //         if (response.data.status_code == 1) {
  //           messageApi.open({
  //             type: "success",
  //             content: response.data.status_message,
  //           });
  //         } else {
  //           messageApi.open({
  //             type: "success",
  //             content: response.data.status_message,
  //           });
  //         }
  //       })
  //       .catch(function (error) {});
  //   };

  const onFinish = (formData) => {
    //console.log("data", formData.NewPassword);
    setLoading(true);

    delete formData.ConfirmPassword;

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      //    AuthorizationCode:authCode,
      ...formData,
    };

   

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ChangePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {});
  };
  const handleSendCode = () => {
    const props = {
      TransactionType: "ChangePassword",
    };

    if(SendEmail(props)) {
      setIsButtonDisabled(true);

      messageApi.open({
          type: 'success',
          content: 'Authentication code has been sent',
      });
  }   
  else {
      setIsButtonDisabled(false);

      messageApi.open({
          type: 'error',
          content: 'Fail to send Authentication code',
      });
  }
   

    
    // Enable the button after 1 minute
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 60000);
    setTimer(60);
  };

  return (
    <>
      {contextHolder}
      <LoginCheck />
      <h3 className="card-title">Profile</h3>
      <Row gutter={[24, 24]}>
        <Col md={6} xs={24}>
          <ProfileMenu />
        </Col>
        <Col md={{span: 16, offset: 2}} xs={24}>
          <Card title="Change Password" bordered={false}>
            <div className="profile-wrap">
              <Form
                name="basic"
                layout="vertical"
                size="large"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Old Password"
                  name="OldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your old password!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="NewPassword"
                  dependencies={["NewPassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="ConfirmPassword"
                  dependencies={["NewPassword"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("NewPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                {/* <Row gutter={20} align={'middle'}>
                                    <Col xs={24} md={18}>
                                        <Form.Item
                                            label="Email Authentication Code"
                                            name="AuthorizationCode"
                                            autoComplete={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter valid verification code!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="mt-3"
                                            block
                                            loading={codeloading}
                                            disabled={true} // Conditionally enable the button based on authCodeValid
                                        >
                                            Get Code
                                        </Button>
                                    </Col>
                                </Row> */}
                <Form.Item
                  name="AuthorizationCode"
                  label="Authentication Code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Authentication Code",
                    },
                  ]}
                >
                  <Space.Compact
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input />
                    <Button
                      type="default"
                      onClick={handleSendCode}
                      disabled={isButtonDisabled}
                    >
                      GET CODE
                    </Button>
                  </Space.Compact>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ChangePassword;
