import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
   
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import TowergadRepurchaseBonus from "../CommodityPanel/TowergadRepurchaseBonus";
import TowergadRightsCheck from "../TowergadRightsCheck";

function ManageUsers(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {
    document.title = "Manage Users";

    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/GetAllUsers',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };


    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);

      })
      .catch(function (error) {
        //console.log(error);
      });

  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };


  const renderLedger = () => {

    if (listOfLedger.length > 0) {
      return (
        listOfLedger.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.ID}</td>
              <td>{item.Full_Name}</td>
              <td>{item.Email_Address}</td>
              <td>{item.Group_Name}</td>
              <td>
                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                  <li><Link className="primary" to={'/towergad/security/manage-users/edit/' + item.ID}  >Edit</Link></li>
                  <li><Link className="red" onClick={(e) => deleteUsers(item.ID)} type="button" >Delete</Link></li>
                </ul>
              </td>
            </tr>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Record Exists</h4></td>
        </tr>
      )
    }

  }

  const deleteUsers = (ID) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this user?');

    if (isConfirmed) {

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/DeleteUser',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {

             
          setIsUpdated(true);
        }
        else if (response.data.status_code == 0) {

             
         
        }
        // setIsUpdated(false);

      })
      .catch(function (error) {
        //console.log(error);
      });
    }
  }

  return (
    <>
    <TowergadRightsCheck />
       
      <div className="admin-content">



      <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">
            MANAGE USERS
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/towergad/security/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <NavLink to="/towergad/security/manage-users/add">
                <button type="button" className="button button-primary  ms-3">
                Add User
                </button>
              </NavLink>
            </div>
          </div>
        </div>
         
        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    ID
                  </th>
                  <th>
                    Full Name
                  </th>
                  <th>
                    Email Address
                  </th>
                  <th>
                    Group
                  </th>

                  <th className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
   </>
  );
}

export default ManageUsers;
