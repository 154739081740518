import React from 'react'

const Page_head = (props) => {
    return (
        <>
            <div class="page-head">
                <h3 style={{textAlign: "center"}}>{props.page_title}</h3>
            </div>
        </>
    )
}

export default Page_head