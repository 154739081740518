export function checkNumber(num) {
    if (num > 0) {
      return num + " $";
    } else {
      return "-";
    }
  }
  export function calculateColumnTotal(data, columnName) {
    let total = 0;
  
    data.forEach((row) => {
      total += row[columnName];
    });
  
    return total;
  }
  
  