const host = window.location.hostname;


let base_url;
let path;
let img_path;
let imge;
let assets;
let root;
let admin_path;
let images;


if (host == "localhost") {
    // base_url= "http://localhost:52476/api/";
    // path= "http://localhost:52476/";
    // img_path= "http://localhost:52476/Personal/MemberImages/";
    // imge="http://localhost:52476/personal/memberimages/kyc/";
    // assets= "http://localhost:52476/assets/";
    // root= "http://localhost:52476/";
    // admin_path= "https://downloadstest.towergearplus.com/";

    base_url = "https://testapi.towergearplus.com/api/";
    path = "https://test.towergearplus.com/";
    img_path = "https://testapi.towergearplus.com/Personal/MemberImages/";
    imge = "https://testapi.towergearplus.com/personal/memberimages/kyc/";
    assets = "https://testapi.towergearplus.com/";
    root = "https://testapi.towergearplus.com/";
    admin_path = "https://downloads.towergearplus.com/";
    images = "https://testapi.towergearplus.com/images/";

    //  base_url = "https://api.towergearplus.com/api/";
    // path = "https://api.towergearplus.com/";
    // img_path = "https://api.towergearplus.com/Personal/MemberImages/";
    // imge = "https://api.towergearplus.com/personal/memberimages/kyc/";
    // assets = "https://api.towergearplus.com/";
    // root = "https://api.towergearplus.com/";
    // admin_path = "https://downloads.towergearplus.com/";
    // images = "https://api.towergearplus.com/images/";



}
else if(host == "test.towergearplus.com") {
  base_url = "https://testapi.towergearplus.com/api/";
  path = "https://test.towergearplus.com/";
  img_path = "https://testapi.towergearplus.com/Personal/MemberImages/";
  imge = "https://testapi.towergearplus.com/personal/memberimages/kyc/";
  assets = "https://testapi.towergearplus.com/";
  root = "https://testapi.towergearplus.com/";
  admin_path = "https://downloads.towergearplus.com/";
  images = "https://testapi.towergearplus.com/images/";
}
else  {
  base_url = "https://api.towergearplus.com/api/";
  path = "https://www.towergearplus.com/";
  img_path = "https://api.towergearplus.com/Personal/MemberImages/";
  imge = "https://api.towergearplus.com/personal/memberimages/kyc/";
  assets = "https://api.towergearplus.com/";
  root = "https://api.towergearplus.com/";
  admin_path = "https://downloads.towergearplus.com/";
  images = "https://api.towergearplus.com/images/";
}

const config = {
  base_url,
  path,
  img_path,
  imge,
  assets,
  root,
  admin_path,
  images,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};



// const config = {
//   // base_url: "http://localhost:8000/api",
//   // MEDIA_ROOT_URL: "http://localhost:8000/",

//   date_format: "DD/MM/YYYY",
//   date_format_input: "YYYY-MM-DD",


//   // base_url: "http://localhost:52476/api/",
//   // path: "http://localhost:52476/",
//   // img_path: "http://localhost:52476/Personal/MemberImages/",
//   // imge:"http://localhost:52476/personal/memberimages/kyc/",
//   // assets: "http://localhost:52476/assets/",
//   // root: "http://localhost:52476/",



// // changes in config file

// };
export default config;