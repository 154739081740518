import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";

import deposit from "../../../assets/images/admin/icons/deposit.png";

import earnings from "../../../assets/images/admin/icons/earnings.png";
import paid_withdrawl from "../../../assets/images/admin/icons/paid_withdrawl.png";

import pending_withdrawl from "../../../assets/images/admin/icons/pending_withdrawl.png";

import TowergadHeader from "../TowergadHeader";

   
   
import TowergadRightsCheck from "../TowergadRightsCheck";

function MonthlyFundsDashboard() {
  return (
    <>
      <TowergadRightsCheck />
         
      <PageTitle title="TOTAL TRANSFERS" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="45$"
              label="DEPOSIT"
              icon={deposit}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="blue"
              value="80$"
              label="USER
EARNINGS
"
              icon={earnings}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="50$"
              label="PAID
WITHDRAWAL"
              icon={paid_withdrawl}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="60%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="red"
              value="30$"
              label="PENDING
WITHDRAWAL"
              icon={pending_withdrawl}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MonthlyFundsDashboard;
