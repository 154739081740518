import React, { useState, useEffect } from "react";

import avatar from "../../../assets/images/avatar.png";

import {
    EditOutlined,
    LockOutlined,
    LogoutOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import Notifications from "../shared/Notifications";
import config from "../../../Config";
import ServerTime from "../shared/ServerTime";
import axios from "axios";

function Header(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [loading, setLoading] = useState(false);

    const [profile, setProfileData] = useState({});
    const [profileImg, setProfileImg] = useState("");


    const Sponsor = localStorage.getItem("Sponsor");

    const ProfileImage = localStorage.getItem("Img_File");

    useEffect(() => {
        setLoading(true);
        const profileParams = {
            AccessKey: AccessKey,
            ID: UserID,
            Email_Address: Email,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "Members/GetMemberProfile",
            headers: {
                "Content-Type": "application/json",
            },
            data: profileParams,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    // console.log(response.data);
                    setProfileData(response.data);
                    let newImg = response.data.Img_File.split("/images/");

                    setProfileImg(newImg[1])
                    console.log(profileImg);
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    let navigate = useNavigate();
    const handleSignOut = () => {
        localStorage.clear();
        navigate("/login");
    };

    const items = [
        {
            key: "1",
            icon: <EditOutlined />,
            label: <NavLink to="/member/profile">Edit Profile</NavLink>,
        },
        {
            key: "2",
            label: <NavLink to="/member/change-password">Change Password</NavLink>,
            icon: <LockOutlined />,
        },
        {
            key: "3",
            label: <NavLink to="/member/kyc">KYC</NavLink>,
            icon: <SettingOutlined />,
        },
        {
            key: "4",
            label: <a onClick={handleSignOut}>Sign Out</a>,
            icon: <LogoutOutlined />,
        },
    ];

    const [active, setActive] = useState(false);

    const handleClick = () => {
        // Toggle the 'active' state
        setActive(!active);

        // Toggle the 'active' class on 'overlaymenu' based on the 'active' state
        const overlaymenu = document.getElementById("overlaymenu");

        if (overlaymenu) {
            overlaymenu.classList.toggle("active");
        }

        // Toggle the 'active' class on 'menubutton' based on the 'active' state
        const menubutton = document.getElementById("menubutton");

        if (menubutton) {
            menubutton.classList.toggle("active");
        }

        // Toggle the 'active' class on 'side_menu' based on the 'active' state
        const sider_bars = document.getElementById("side_menu");

        if (sider_bars) {
            sider_bars.classList.toggle("active");
        }
    };

    return (
        <header id="member-header">
            <svg
                className={`ham hamRotate ham8 ${active ? "active" : ""}`}
                id="menubutton"
                onClick={handleClick}
                viewBox="0 0 100 100"
                width="80"
            >
                <path
                    className="line top"
                    d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                />
                <path className="line middle" d="m 30,50 h 40" />
                <path
                    className="line bottom"
                    d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                />
            </svg>

            <div className="server-time">
                <p>
                    <span>Server Time:</span> <ServerTime />
                </p>
            </div>
            <div className="header-right">
                <div className="sponsor">
                    <p>
                        <span>Sponsor: </span> {Sponsor}
                    </p>
                </div>
                <div className="divider"></div>
                <Notifications />
                <div className="divider"></div>

                <Dropdown
                    menu={{
                        items,
                    }}
                >
                    <div className="user_logged_in">
                        <div className="photo">
                            <img src={config.img_path + profileImg} alt="" />
                        </div>
                        <div className="info">
                            <h4>{profile.Full_Name}</h4>
                            <p>{profile.Member_Type}</p>
                        </div>
                    </div>
                </Dropdown>
            </div>
        </header>
    );
}

export default Header;
