import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
  DatePicker,
  Select,
} from "antd";
import axios from "axios";
import config from "../../../../Config";
import { useNavigate } from "react-router-dom";

import Flag from "../../../../assets/images/flag-thumb.png";
import CountryDropDown from "../../General/CountryDropDown";
const KycBirthDetails = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Text, Link, Title } = Typography;

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

  const [countryName, setCountryName] = useState(null);
  const [country, setCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [countriesOld, setCountriesOld] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [CurrentStatus, setCurrentStatus] = useState("");
  const [finalCong, setFinalCong] = useState("");
  let navigate = useNavigate();
  const countryArry = CountryDropDown();
  //console.log(countryArry);
  const { Option } = Select;

  useEffect(() => {
  document.title= "KYC Document Details"

    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "MemberKYC/GetMemberKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };
    //console.log(dashboardParms);
    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
          //console.log(response);

          setCurrentStatus(response.data.CurrentStatus);
          setFinalCong(response.data.CurrentStatus);
          // setHasKyc(true);
          // setDateOfBirth(moment(response.data.DateOfBirth).format(config.date_format_input));
          // setInitialCountryFlag(getFlagImageByName(response.data.BirthCountry));

          if (response.data.CurrentStatus === "Submitted") {
            messageApi.open({
              type: "error",
              content: "KYC Already In Process",
            });

            navigate("/member/kyc/submitted");
            // document.title= "KYC Submitted"

          } else if (response.data.CurrentStatus === "Approved") {
            navigate("/member/kyc/congratulations");
            // document.title= "KYC Approved"

          }
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/login");
        } else {
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
          // toast.error(response.data.status_message, {
          //     position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch(function (error) {
        messageApi.open({
          type: "error",
          content: "Network Error.",
        });
        // toast.error('Network Error..', {
        //     position: toast.POSITION.TOP_RIGHT,
        //  });
      });
  }, []);

  //   useEffect(() => {
  //     const data = {
  //       Email_Address: Email,
  //     };

  //     var api_config = {
  //       method: "post",
  //       url: config.base_url + "Country/GetCountryList",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(api_config)
  //       .then(function (response) {
  //         //console.log(response.data);
  //         setCountries(response.data.listofCountries);
  //         setCountriesOld(response.data.listofCountries);
  //       })
  //       .catch(function (error) {
  //         //console.log(error);
  //       });
  //   }, []);

  //   const renderCountries = () => {
  //     if (countries != "") {
  //       return countries.map((item) => {
  //         return (
  //           <li key={item.ID} onClick={(e) => handleCountry(e, item)}>
  //             <img
  //               style={{
  //                 width: "30px",
  //                 marginRight: "3%",
  //                 marginTop: "2%",
  //                 justifyContent: "center",
  //               }}
  //               src={`${config.root}images/flags/${item.Flage_Image_Name}`}
  //               className="flag"
  //             />
  //             {item.Country_Name}
  //           </li>
  //         );
  //       });
  //     }
  //   };
  //   const handleCountry = (e, item) => {

  //     setCountry(item);
  //     setCountryName(item.Country_Name);
  //     setShow(false);
  //     setCountries(countriesOld);
  //   };

  //   useEffect(() => {
  //     document.addEventListener("click", ClickOutsideOfSearchbox);
  //   }, []);

  //   const ClickOutsideOfSearchbox = (event) => {
  //     const innerDiv = document.getElementById("countries-box");

  //     if (!innerDiv.contains(event.target)) {
  //       setShow(false);
  //     }
  //   };
  const handleSubmit = (formData) => {
    if (formData.BirthCountry == null) {
      messageApi.open({
        type: "error",
        content: "Please select a Country",
      });
    } else {
      setLoading(true);
      // let day = event.target.day.value;
      // let month = event.target.month.value;
      // let year = event.target.year.value;

      // let date = year + "-" + month + "-" + day;

      // let formateddate = moment(date).format("YYYY-MM-DD");

      const data = {
        UserID: UserID,
        AccessKey: AccessKey,

        ...formData,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "MemberKYC/UpdatePersonalKYC",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            navigate("/member/kyc/document-details");
          } else if (
            (response.data.status_code === 0 &&
              response.data.status_message === "Invalid Access Key.") ||
            response.data.status_code === 2
          ) {
            navigate("/login");
          } else {
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error.",
          });
        });
    }
  };

  //   const getFlagImageByName = (countryName) => {
  //     const country = countries.find(
  //       (country) => country.Country_Name === countryName
  //     );
  //     if (country && country.Flage_Image_Name) {
  //       return `${config.root}images/flags/${country.Flage_Image_Name}`;
  //     } else {
  //       return Flag; // Replace with your default image path
  //     }
  //   };
  //   const filterCountries = (e) => {
  //     setCountryName(e.target.value);

  //     let value = e.target.value;

  //     const filteredCountryList = countries.filter((country) =>
  //       country.Country_Name.toLowerCase().includes(value.toLowerCase())
  //     );

  //     if (value == "") {
  //       setCountries(countriesOld);
  //       setCountryName(null);
  //     } else {
  //       setCountries(filteredCountryList);
  //     }
  //   };

  return (
    <>
    {contextHolder}
      <Row gutter={[24, 24]}>
        <Col md={20} offset={2} xs={24}>
          <Card title="Personal details" bordered={true}>
            <Title level={5}>Enter your personal details below.</Title>
            <div className="profile-wrap">
              <>
                <Form
                  name="basic"
                  layout="vertical"
                  size="large"
                  onFinish={handleSubmit}
                  autoComplete="off"
                >
                  <Form.Item
                    label="First name"
                    name="FirstName"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 20px)",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Last name"
                    name="LastName"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 20px)",
                      margin: "0 20px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input LastName",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Enter your birth date"
                    name="DateOfBirth"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 20px)",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Date Of Birth",
                      },
                    ]}
                  >
                    <DatePicker
                      name="DateOfBirth"
                      format="DD/MM/YYYY"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    label="Choose your country of birth"
                    name="BirthCountry"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 20px)",
                      margin: "0 20px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Birth Country",
                      },
                    ]}
                  >
                    <Input
                      id="countries-box"
                      className="flag-input"
                      onFocus={handleShow}
                      onChange={(e) => filterCountries(e)}
                      value={countryName}
                      style={{ paddingLeft: "56px" }}
                    />
                     <div
                        style={{
                          position: "absolute",
                          right: "19px",
                          top: "9px",
                        }}
                      >
                        {show == true ? (
                          <svg
                            width="16"
                            height="9"
                            viewBox="0 0 16 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 -3.98606e-07L0 7.63251L1.43333 9L8 2.70318L14.5667 8.9682L16 7.60071L8 -3.98606e-07Z"
                              fill="#727272"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="16"
                            height="9"
                            viewBox="0 0 24 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 14.15L0 2.15L2.15 0L12 9.9L21.85 0.05L24 2.2L12 14.15Z"
                              fill="#727272"
                            />
                          </svg>
                        )}
                      </div>
                    {countryName != null && (
                      <img
                        src={getFlagImageByName(countryName)}
                        style={{
                          width: "26px",
                          position: "absolute",
                          top: "14px",
                          left: "12px",
                        }}
                        onError={(e) => {
                          e.target.src = Flag; // Replace with your default image path
                        }}
                      />
                    )}
                  </Form.Item> */}

                  <Form.Item
                    label="Select Country"
                    name="BirthCountry"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input country !",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      //   placeholder="Select Country"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={countryArry === false ? true : false}
                      options={countryArry}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        float: "right",
                        width: "30%",
                        marginRight: "3%",
                      }}
                    >
                      Continue
                    </Button>
                    {/*                   
                    <div  className="country_list" style={{marginTop:"61px",marginLeft:"42%",width:"100%"}}>
                    

                      {show == true && (
                        <div style={{ top: "51px" }}>
                          <div className="flag-list">
                            <ul style={{ listStyle: "none" }}>
                              {renderCountries()}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </Form.Item>
                </Form>
              </>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KycBirthDetails;
