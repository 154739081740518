import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";
import Spinner from "react-bootstrap/Spinner";
import received from "../../../assets/images/admin/icons/received.png"

import CRYPTO from "../../../assets/images/admin/icons/CRYPTO.png";
import pm from "../../../assets/images/admin/icons/pm.png";

import wire from "../../../assets/images/admin/icons/wire.png";
import mastercard from "../../../assets/images/admin/icons/mastercard.png";

import cash_deposit from "../../../assets/images/admin/icons/cash_deposit.png";

import jazz from "../../../assets/images/admin/icons/jazz.png";

import crossdolar from "../../../assets/images/admin/icons/crossdolar.png";

import pendings from "../../../assets/images/admin/icons/pendings.png";
import easypesa from "../../../assets/images/admin/icons/easypesa.png";

   
   
import { useNavigate } from "react-router";
import { NumberFormat } from "../../member/General/Functions";

import TowergadRightsCheck from "../TowergadRightsCheck";
function DepositDashboard() {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [depositStatics, setDepositStatics] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Deposit Panel";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDepositDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);

        if (response.data.status_code === 1) {
          setDepositStatics(response.data);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
        
          navigate("/towergad/login");
        } else {
             
        }

        setLoading(false);
      })
      .catch(function (error) {
            
      });
  }, []);

  return (
    <>
    <TowergadRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
             
          <PageTitle title="DEPOSIT PANEL" />
          {depositStatics && (
            <div className="admin-content">
              <div className="row">
                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/total-deposit"
                    class="blue"
                    value={NumberFormat(depositStatics.totalDeposit)}
                    label="TOTAL DEPOSIT"
                    icon={received}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="10%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>
                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/crypto-deposit"
                    class="green"
                    value={NumberFormat(depositStatics.cryptoDeposit)}
                    label="CRYPTO DEPOSIT"
                    icon={CRYPTO}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="16%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>
                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/coin-payment"
                    class="red"
                    value={NumberFormat(depositStatics.coinPaymentDeposit)}
                    label="COIN PAYMENTS 
"
                    icon={pm}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="30%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>
                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/now-payment"
                    class="green"
                    value={NumberFormat(depositStatics.nowPaymentsDeposit)}
                    label="NOW PAYMENTS"
                    icon={wire}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="70%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>

                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/pending-deposit"
                    class="green"
                    value={depositStatics.pendingDeposit}
                    label="PENDING DEPOSIT"
                    icon={pendings}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="30%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>
                <div className="col-md-3">
                  <TowergadCard
                    link="/towergad/deposit/rejected-deposit"
                    class="red"
                    value={depositStatics.rejectedDeposit}
                    label="REJECTED DEPOSIT"
                    icon={crossdolar}
                    progress="progress"
                    progressbar="progress-bar"
                    progressrole="progressbar"
                    val="50%"
                    arianow="10"
                    ariamin="0"
                    ariamax="100"
                    progressval=""
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DepositDashboard;
