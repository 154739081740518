import React from 'react'
import Footer from '../include/Footer'
import Header from '../include/Website_Header'

const News = () => {
    return (
        <>
            <Header />
            <h1>News</h1>
            <Footer />
        </>
    )
}

export default News