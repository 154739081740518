import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
   
import axios from "axios";
import config from "../../../Config";

import { useNavigate } from "react-router-dom";
import TowergadRightsCheck from "../TowergadRightsCheck";
const AddGroup = () => {
  let navigate = useNavigate()

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
 
  const [loading, setLoading] = useState(false);


const [groupName,setgroupName] = useState("")
const [groupDetails,setgroupDetails] = useState("")
useEffect(()=>{
  document.title = "Add Group";

},[])
const handleSubmit = (event) => {

  event.preventDefault();
  event.stopPropagation();
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
  }
  else {
      setLoading(true);
      const data = {
          AccessKey: AccessKey,
          UserID: UserID,
          MemberType: "Admin",
          Group_Name: groupName,
          Description: groupDetails
      };

      // //console.log(data);

      var api_config = {
          method: 'post',
          url: config.base_url + 'Security/AddSecurityGroup',
          headers: {
              'Content-Type': 'application/json'
          },
          data: data
      };



      axios(api_config)
          .then(function (response) {
              ////console.log(response.data);
              if (response.data.status_code == 1) {

                     
                  setTimeout(()=>{
                    navigate("/towergad/security/manage-groups")
             
                  },2000)

                 
              }
              else if(response.data.status_code == 0 && response.data.status_message==="Group Name Already Exists."){
                    

              }
              else {
                 
              }

              setLoading(false);


          })
          .catch(function (error) {
              setLoading(false);
              
          });


       

  }

  // setValidated(true);
};


  return (
    <>
    <TowergadRightsCheck />
       
    <div className="admin-content">


    <div className="row align-items-center mb-3">
          <div className="col-md-6">
            <div className="d-title m-0">
            Add New Group
            </div>
          </div>
          <div className="col-md-6 text-end">
           
              <NavLink to="/towergad/security/manage-groups">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
             
             
            
          </div>
        </div>










       
      <div className="card card-table  ">
        <div className="card-inner p-3">
          <form onSubmit={handleSubmit}>
          <div className="row mb-1">
            <div className="col-md-3">
              <h6 className="">Group Name</h6>
              <div className="inputwrap">
                <input
                  type="text"
                  // style={{ width: "120px" }}
                  className="form-control w-100"
                  onChange={(e)=>setgroupName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <h6 className="">Group Details</h6>
              <div className="inputwrap">
                <input
                  type="text"
                  // style={{ width: "120px" }}
                  className="form-control w-100"
                  onChange={(e)=>{setgroupDetails(e.target.value)}}
                  required
                />
              </div>
            </div>

            <div className="text-end">
              <button type="submit" className="button button-primary" >
                Add
              </button>
            </div>
          </div>
          </form>
          
        </div>
      </div>
    </div>
    </>
  );
};

export default AddGroup;
