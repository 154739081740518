import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Input, Button, message, Space } from "antd";
import ProfileMenu from "../member/profile/ProfileMenu";
import { generateRandomString } from "../member/General/Functions";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config";
import Logo from "../../assets/images/logo/towergear_full_white.png";

const ResetPassword = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [IsVerified, setIsVerified] = useState(false);
  const [IsPasswordChanged, setIsPasswordChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [captcha, setCaptcha] = useState("");

  let navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const SecurityCode = searchParams.get("AuthorizationCode");
  const UserName = searchParams.get("UserName");
  useEffect(() => {
    document.title = "Reset Password";
    setCaptcha(generateRandomString(6));
    if (SecurityCode && UserName) {
      setLoading(true);
      const data = {
        // UserID: UserID,
        // AccessKey: AccessKey,
        AuthorizationCode: SecurityCode,
        UserName: UserName,
      };

      //Members/GetResetUserInfo

      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetResetUserInfo",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          ////console.log(response.data);

          if (response.data.status_code == 1) {
            setIsVerified(true);
            setLoading(false);
          } else {
            setIsVerified(true);
            setLoading(false);
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error..",
          });
        });
    }
  }, []);

  const handleSubmit = (formData) => {
    // //console.log(formData);

    if (formData.NewPassword != formData.Confirmpassword) {
      messageApi.open({
        type: "error",
        content: "Password does not match",
      });
      return false;
    } else if (formData.Captcha != captcha) {
      messageApi.open({
        type: "error",
        content: "Incorrect Captcha",
      });
    } else {
      setLoading(true);
      const data = {
        UserName: UserName,
        NewPassword: formData.Confirmpassword,
      };
      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Members/ChangePasswordWithoutKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // //console.log(response.data);
          if (response.data.status_code == 1) {
            messageApi.open({
              type: "success",
              content: response.data.status_message,
            });
            navigate('/member/dashboard');

            setIsPasswordChanged(true);

            //    
          } else {
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });

            setIsPasswordChanged(false);
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: "Network Error",
          });
        });
    }

    setValidated(true);
  };

  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  };

  // const handleChange = (event) => {
  //     const { value } = event.target;
  //     setPassword(value.slice(0, 18));

  //     if (isPasswordValid(value)) {
  //         setPasswordvalid(false)
  //     }
  //     else {
  //         setPasswordvalid(true)
  //         return false
  //     }

  // };
  return (
    <>
      {contextHolder}
      {/* <p>Please Enter the New Password</p> */}
      <div className="auth-wrap-new">
        <div className="col-left">
          <div className="auth-form">
            <div className="card-black card-auth-form">
              <h2>Forgot Password</h2>
              <Form
                name="basic"
                layout="vertical"
                size="large"
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="New Password"
                  name="NewPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password.",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/,
                      message: "Please enter a strong password.",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="Confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your confirm password.",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Row gutter={10}>
                  <Col md={11} xs={24}>
                    <Form.Item>
                      <div className="Character-wrap">
                        <Input className="Character" value={captcha} readOnly />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col md={13} xs={24}>
                    <Form.Item
                      // label="Captcha"
                      name="Captcha"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please input Captcha",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Captcha" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="mb-2">
                  <NavLink onClick={UpdateCaptcha}>Refresh The Captcha</NavLink>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" shape="round" block  loading={loading}>
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-right">
          <div className="auth-right-content">
            <div className="auth-head">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className="auth-actions">
                <p>New to Tower Gear Plus?</p>
                <NavLink to="/register" className="button button-secondary button-round button-long">Join Now</NavLink>
              </div>
            </div>
            <br />
            <ul className="list-reasons">
              <li>
                <span>
                  01
                </span>
                <div>
                  <h4>Secure Process</h4>
                  <p>Your password reset request is processed securely to safeguard your account.</p>
                </div>
              </li>
              <li>
                <span>
                  02
                </span>
                <div>
                  <h4>User Verification</h4>
                  <p>We ensure your identity through verification steps to protect your account from unauthorized access.</p>
                </div>
              </li>
              <li>
                <span>
                  03
                </span>
                <div>
                  <h4>Quick Response</h4>
                  <p>Receive prompt assistance from our support team to swiftly reset your password and regain access.</p>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div >
    </>
  );
};

export default ResetPassword;
