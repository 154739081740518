import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";

// import RankCard from "./RankCard";
import config from "../../../Config";
import axios from "axios";
import Pagination from "../../member/General/Pagination";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

function Reward(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));


    const [ranks, setRanks] = useState([]);
    const [loading, setLoading] = useState(false);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    useEffect(() => {
        document.title = "Rewards";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        // console.log("Rewards",data);

        var api_config = {
            method: "post",
            url: config.base_url + "Reward/GetAllRewards",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log("Rewards",response.data)
                if (response.data.status_code == 1) {
                    setRanks(response.data.ListofLedger);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);

                } 
                    // navigate("/towergad/login");
                 else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const renderLedger = () => {
        if (ranks != null && ranks.length > 0) {
            let count = 1;
            return ranks.map((user, index) => {
                return (
                    <>
                        <tr key={index}>
                            <td>{count++ + pageSize * (pageNo - 1)}</td>
                            <td><img src={config.img_path + user.Img_File} alt=""
                                style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }}
                            />{user.Full_Name}</td>
                            <td>
                                <img src={config.images + "flags/" + user.Extra1} alt=""
                                    style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }} />{user.Country}</td>
                            <td>{user.Email_Address}</td>
                            <td>{dayjs(user.RewardDate).format(config.date_format)}</td>
                            <td>{user.RewardName}</td>
                            <td>${user.RewardAmount}</td>
                        </tr>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="6">
                        <h4
                            className="mt-3 text-center"
                            style={{ fontSize: "15px", color: "white" }}
                        >
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    return (
        <>
            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">Rewards</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="#/">
                                <button type="button" className="button button-primary">
                                    Back
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>User</th>
                                    <th>Country</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Reward Name</th>
                                    <th>Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={"7"} className="text-center">
                                            <Spin />
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}</>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div >
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default Reward