import React, { useState, useEffect } from "react";

import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Col, Button, Form, Input, message } from 'antd';

import Logo from "../../assets/images/logo/towergear_full_white.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import getDeviceInfo from "../member/shared/DeviceInfo";
import config from "../../Config";
import axios from "axios";

import { generateRandomString } from "../member/General/Functions";

function Login(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    // device and location info
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [Devicename, setDevicename] = useState("");
    const [Location, setLocation] = useState("");
    const [captcha, setCaptcha] = useState("");

    // loadings
    const [loading, setLoading] = useState(false);

    // messages
    const [messageApi, contextHolder] = message.useMessage();

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Login"

        const fetchData = async () => {
            try {
                const info = await getDeviceInfo();
                setDeviceInfo(info);
                setDevicename(info.deviceName);
                setLocation(info.address);

                console.log(info);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setCaptcha(generateRandomString(6));
    }, []);



    const onFinish = (formData) => {


        if (formData.Captcha != captcha) {
            messageApi.open({
                type: "error",
                content: "Incorrect Captcha",
            });
            return;
        }

        setLoading(true);
        const data = {
            //Location: Location,
            //Devicename: Devicename,
            ...formData
        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SignIn',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {


                //console.log(response.data);
                if (response.data.status_code == 1) {

                    //if (response.data.Email_Address_Verified == true) {
                    localStorage.setItem("AccessKey", response.data.AccessKey);
                    localStorage.setItem("ID", response.data.ID);
                    localStorage.setItem("Full_Name", response.data.Full_Name);
                    localStorage.setItem("User_Name", response.data.User_Name);

                    localStorage.setItem("Email_Address", response.data.Email_Address);
                    localStorage.setItem("Img_File", response.data.Img_File);
                    localStorage.setItem("Mobile_No", response.data.Mobile_No);
                    localStorage.setItem("Member_Type", response.data.Member_Type);
                    localStorage.setItem("ReferrerLink", response.data.ReferrerLink);
                    localStorage.setItem("Sponsor", response.data.Sponsor);
                    //localStorage.setItem("theme", 'theme-light');



                    navigate('/member/dashboard');
                    setLoading(false);

                    //}
                    //else {
                    //localStorage.setItem("User_Name", response.data.User_Name);
                    //navigate('/email-not-verified');
                    //}



                    //

                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                }

                setLoading(false);


            })
            .catch(function (error) {


            });

    };


    const UpdateCaptcha = () => {
        setCaptcha(generateRandomString(6));
    };

    return (
        <>
            {contextHolder}
            <div className="auth-wrap-new">
                <div className="col-left">
                    <div className="auth-form">
                        <div className="card-black card-auth-form">
                            <h2>Sign In</h2>

                            <Form
                                name="normal_login"
                                className="login-form"
                                layout="vertical"
                                size="large"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="Email_Address"
                                    label="Email / Trade ID"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email or Trade ID',
                                        },
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email or Trade ID" />
                                </Form.Item>
                                <Form.Item
                                    name="Password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Row gutter={10}>
                                    <Col md={11} xs={24}>
                                        <Form.Item>
                                            <div className="Character-wrap">
                                                <Input className="Character" value={captcha} readOnly />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col md={13} xs={24}>
                                        <Form.Item
                                            // label="Captcha"
                                            name="Captcha"
                                            style={{ width: "100%" }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input Captcha",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Captcha" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item className="mb-2">
                                    <NavLink onClick={UpdateCaptcha}>
                                        Refresh The Captcha
                                    </NavLink>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" shape="round" block loading={loading}>
                                        Log in
                                    </Button>
                                </Form.Item>

                                <div className="text-center">
                                    <NavLink className="login-form-forgot" to="/forgot-password">
                                        Forgot password?
                                    </NavLink>
                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
                <div className="col-right">
                    <div className="auth-right-content">
                        <div className="auth-head">
                            <div className="logo">
                                <img src={Logo} />
                            </div>
                            <div className="auth-actions">
                                <p>New to Tower Gear Plus?</p>

                                <NavLink to="/register" className="button button-secondary button-round button-long">Join Now</NavLink>
                            </div>

                        </div>
                        <h3>Why Join Us?</h3>
                        <br />
                        <ul className="list-reasons">
                            <li>
                                <span>
                                    01
                                </span>
                                <div>
                                    <h4>Expertise</h4>
                                    <p>Benefit from our seasoned professionals' market knowledge for better trading decisions.</p>
                                </div>
                            </li>
                            <li>
                                <span>
                                    02
                                </span>
                                <div>
                                    <h4>Cutting-Edge Tech</h4>
                                    <p>Access advanced tools and resources to boost your trading performance.</p>
                                </div>
                            </li>
                            <li>
                                <span>
                                    03
                                </span>
                                <div>
                                    <h4>Global Network</h4>
                                    <p>Expand your reach and explore international trading opportunities.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;