import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../PageTitle";
import Popup from "../../Common/Popup";
import TowergadRightsCheck from "../TowergadRightsCheck";

function UserDetailsPage(props) {
  const navigate = useNavigate();
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [listOfLedger, setLedger] = useState([]);
  const [user, setUser] = useState("");
  const [status, setStatus] = useState("Approved");

  const [cnic, setCnic] = useState();
  const [pasportno, setPasportno] = useState();
  const [licenseno, setLicenseno] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const [MemberID, setMemberID] = useState("");

  const [stateValidate, setStateValidate] = useState(false);

  const [validCnic, setValidCnic] = useState();
  const [validPassport, setValidPassport] = useState();

  const [validLicense, setValidLicense] = useState();

  const { KYCID } = useParams();
  //console.log(KYCID);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const [Imgpopup, setImgpopup] = useState("");

  const [validateLoading, setvalidateLoading] = useState(false);
  const [validationCode1, setValidationCode1] = useState(false);
  const [validationCode0, setValidationCode0] = useState(false);

  const [nextId, setNextId] = useState(KYCID);


  useEffect(() => {
    // //console.log(KYCID);

    document.title = "KYC REQUEST";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: KYCID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMemberKYCByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // //console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setUser(response.data);
          setMemberID(response.data.MemberID);

          // //console.log("data", response.data);
          // setLedger(response.data.ListofLedger);
          setLoading(false);
          // setTotalRecords(response.data.totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/towergad/login");
        } else {

        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageSize, nextId]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleUpdate = () => {
    setLoadingUpdate(true);
    const dataforUpdate = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: KYCID,
      CurrentStatus: status,
      IDCardNumber: cnic,
      PassportNumber: pasportno,
      LicenseNumber: licenseno,
      AdminRemarks: adminRemarks,
    };
    //console.log(dataforUpdate);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/UpdateMemberKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataforUpdate,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response);
        if (response.data.status_code === 1) {
          navigate("/towergad/userstatistics/KycRequests");
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/towergad/login");
        } else {

        }
        setLoadingUpdate(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleNext = async () => {
    try {
      const response = await axios.post(
        config.base_url + "Users/GetNextKYCID",
        {
          UserID: UserID,
          AccessKey: AccessKey,
          ID: KYCID,
        }
      );
      //console.log(KYCID);
      //console.log(response.data);

      if (response.data.status_code === 1 && response.data.NextKYCID !== 0) {
        setNextId(response.data.NextKYCID);
        navigate(`/towergad/userstatistics/user-details/${response.data.NextKYCID}`);
      } else if (
        response.data.status_code === 0 ||
        response.data.status_code === 2
      ) {
        navigate("/towergad/login");
      } else {

      }
    } catch (error) {
      // Handle any errors that occurred during the request
      //console.log(error);
    }
  };

  const VerifyNo = async () => {
    setvalidateLoading(true);

    const dataforValidation = {
      UserID: UserID,
      AccessKey: AccessKey,
      MemberID: MemberID,
      KYCDocType: user.DocumentType,
      IDCardNumber: cnic,
      PassportNumber: pasportno,
      LicenseNumber: licenseno,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/ValidateKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataforValidation,
    };
    // //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log("validate response", response);
        if (response.data.status_code === 1) {
          setvalidateLoading(false);
          setStateValidate(true);
          setValidationCode1(true);
          setValidationCode0(false);
          // setLoading(false);
        } else if (response.data.status_code === 0) {
          setvalidateLoading(false);
          setValidationCode1(false);
          setValidationCode0(true);
          setStateValidate(true);

        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };


  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (e) => {
    const src = e.target.src;
    setImgpopup(src);
    setShowModal(true);
  }

  const handleCloseModal = (e) => {
    setShowModal(false);
  }

  const [showBackModal, setShowBackModal] = useState(false);

  const renderLedger = () => {
    if (user) {
      return (
        <>
          <div className="form_admin">
            <div className="row gy-3">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="row gy-2">
                      <div className="admin-card-title">Personal details.</div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>First Name</label>
                          <div className="form-control disabled">
                            {user.FirstName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Last Name</label>
                          <div className="form-control disabled">
                            {user.LastName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Birth Country</label>
                          <div className="form-control disabled">
                            {user.BirthCountry}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>User ID</label>
                          <div className="form-control disabled">
                            {user.User_Name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Email Address</label>
                          <div className="form-control disabled">
                            {user.Email_Address}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Pay ID</label>
                          <div className="form-control disabled">
                            {user.AccountKey}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row gy-2">
                      <div className="admin-card-title">
                        Document Item (
                        {user.DocumentType == "License" ? (
                          <>
                            <span>Driving licensce</span>
                          </>
                        ) : user.DocumentType === "ID" ? (
                          <>
                            <span>ID</span>
                          </>
                        ) : user.DocumentType === "Passport" ? (
                          <>
                            <span>Passport</span>
                          </>
                        ) : (
                          <></>
                        )}
                        )
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Document Type</label>
                          <div className="value">
                            <div className="form-control disabled">
                              {user.DocumentType}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {user.DocumentType === "License" && (
                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => handleShowModal(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.LicenseFront}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                              <div className="back">
                                <img
                                  onClick={(e) => handleShowModal(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.LicenseBack}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <br />
                            <div className="form-group">
                              <label>Driving licensce</label>
                              <div className="d-flex">
                                <input
                                  placeholder="License Number"
                                  className="form-control w-50"
                                  value={licenseno}
                                  onChange={(e) => {
                                    setLicenseno(e.target.value);
                                    setStateValidate(false);
                                  }}
                                />

                                <div
                                  style={{
                                    position: "absolute",
                                    right: "51%",
                                    top: "51%",
                                  }}
                                >
                                  {validationCode1 &&
                                    stateValidate === true &&
                                    licenseno ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick yes"
                                    >
                                      done
                                    </span>
                                  ) : validationCode0 &&
                                    stateValidate === true &&
                                    licenseno ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick no"
                                    >
                                      cancel
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-3 ms-4">
                                  <button
                                    onClick={VerifyNo}
                                    className="button button-outline-primary"
                                    type="button"
                                    // disabled={
                                    //   licenseno === validLicense ? false : true
                                    // }
                                    disabled={licenseno ? false : true}
                                  >
                                    <span>
                                      {validateLoading
                                        ? "Loading.."
                                        : "VALIDATE"}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {user.DocumentType === "ID" && (
                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => handleShowModal(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.IDFront}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                              <div className="back">
                                <img
                                  onClick={(e) => handleShowModal(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.IDBack}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <br />
                            <div className="form-group">
                              <label>ID</label>
                              <div className="d-flex">
                                <input
                                  placeholder="CNIC Number"
                                  className="form-control w-50"
                                  value={cnic}
                                  onChange={(e) => {
                                    setCnic(e.target.value);
                                    setStateValidate(false);
                                  }}
                                />

                                <div
                                  style={{
                                    position: "absolute",
                                    right: "51%",
                                    top: "51%",
                                  }}
                                >
                                  {validationCode1 &&
                                    stateValidate === true &&
                                    cnic ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick yes"
                                    >
                                      done
                                    </span>
                                  ) : validationCode0 &&
                                    stateValidate === true &&
                                    cnic ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick no"
                                    >
                                      cancel
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-3 ms-4">
                                  <button
                                    onClick={VerifyNo}
                                    className="button button-outline-primary"
                                    type="button"
                                    // disabled={cnic === validCnic ? false : true}
                                    disabled={cnic ? false : true}
                                  >
                                    <span>
                                      {validateLoading
                                        ? "Loading.."
                                        : "VALIDATE"}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {user.DocumentType === "Passport" && (
                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => handleShowModal(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.Passport}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Passport</label>
                              <div className="d-flex">
                                <input
                                  placeholder="Pasport Number"
                                  className="form-control w-50"
                                  value={pasportno}
                                  onChange={(e) => {
                                    setPasportno(e.target.value);
                                    setStateValidate(false);
                                  }}
                                />

                                <div
                                  style={{
                                    position: "absolute",
                                    right: "51%",
                                    top: "51%",
                                  }}
                                >
                                  {validationCode1 &&
                                    stateValidate === true &&
                                    pasportno ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick yes"
                                    >
                                      done
                                    </span>
                                  ) : validationCode0 &&
                                    stateValidate === true &&
                                    pasportno ? (
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="material-symbols-outlined tick no"
                                    >
                                      cancel
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-3 ms-4">
                                  <button
                                    onClick={VerifyNo}
                                    className="button button-outline-primary"
                                    type="button"
                                    // disabled={
                                    //   pasportno === validPassport ? false : true
                                    // }
                                    disabled={pasportno ? false : true}
                                  >
                                    <span>
                                      {validateLoading
                                        ? "Loading.."
                                        : "VALIDATE"}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Current Status</label>
                          <select
                            id="dropdown"
                            className="form-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="Approved">Approved</option>
                            <option value="Declined">Declined</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Admin Remarks</label>
                          <textarea
                            rows={3}
                            className="form-control"
                            placeholder="admin remarks"
                            value={adminRemarks}
                            onChange={(e) => setAdminRemarks(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="row mt-3 gy-3">
                        <div className="col-md-4">
                          <button
                            className="button w-100 button-outline-primary"
                            onClick={() => navigate("/towergad/userstatistics/KycRequests")}
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-md-4">
                          {loadingUpdate ? (
                            <div className="text-center">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            <button
                              className="button w-100 button-primary"
                              onClick={handleUpdate}
                              disabled={
                                (validationCode0 && status === "Approved") ||
                                  (!stateValidate && status === "Approved")
                                  ? // || stateValidate === false
                                  true
                                  : (stateValidate && status === "Declined") ||
                                    validationCode1
                                    ? false
                                    : ""
                              }
                            // disabled={
                            //   validationCode1 &&
                            //     stateValidate === true &&
                            //     (cnic || pasportno || licenseno)
                            //     ? false
                            //     : true
                            // }
                            >
                              Update
                            </button>
                          )}
                        </div>
                        <div className="col-md-4">
                          <button
                            className="button w-100 button-primary"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <h5
          className="mt-3 mr-3"
          style={{ fontSize: "20px", color: "rgba(0,0,0,0.6)" }}
        >
          No Record Exists
        </h5>
      );
    }
  };


  return (
    <>

      <PageTitle title="KYC REQUEST" />
      <TowergadRightsCheck />

      <div className="admin-content">
        <div>
          {loading ? (
            <p className="text-center" colspan="10">
              <Spinner animation="border" role="status"></Spinner>
            </p>
          ) : (
            <>
              <Modal
                className="modal-theme modal-small modal-lg"
                show={showModal}
                onHide={handleCloseModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div className="admin-card-title">
                      View (
                      {user.DocumentType == "License" ? (
                        <>
                          <span>Driving licensce</span>
                        </>
                      ) : user.DocumentType === "ID" ? (
                        <>
                          <span>ID Card</span>
                        </>
                      ) : user.DocumentType === "Passport" ? (
                        <>
                          <span>Passport</span>
                        </>
                      ) : (
                        <></>
                      )}
                      )
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-lg">
                  <img
                    src={Imgpopup}
                    className="img-fluid"
                    alt="View Photo"
                  />
                </Modal.Body>
              </Modal>
              {renderLedger()}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default UserDetailsPage;
