import dayjs from 'dayjs';
import React from 'react';

function TradingCard(props) {

    return (
        <>
            <div className="trading-card">

                <div className="trading-info">
                    <div className="amount">
                        <p>{props.package.selectedPackage.Name}</p>
                        <h3>${props.package.PackagePrice}</h3>
                    </div>
                    <div className="date">
                        <i className="material-symbols-outlined">calendar_month</i>
                        <span>{dayjs(props.package.BuyingDate).format("DD MMMM YYYY")}</span>
                    </div>
                    {/* <div className="profit">
                        <p>Profit</p>
                        <h3>$30</h3>
                    </div> */}
                </div>

            </div>
        </>
    );
}

export default TradingCard;