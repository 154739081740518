import React, { useState, useEffect } from 'react';
import promotion from "../../../assets/images/dashboard/promo1.jpg";

import { NavLink } from 'react-router-dom';
import { Space, Switch, message, Spin } from 'antd';
import config from '../../../Config';
import axios from 'axios';

import CountdownTimer from "../../Common/CountdownTimer";


function TowerMillionaire({ loading, participationStatus, EndDate }) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [incrementedValue, setIncrementedValue] = useState(0);
    const [ParticipationStatus, setParticipationStatus] = useState(false)
    // messages
    const [messageApi, contextHolder] = message.useMessage();



    const onChange = (checked) => {
        //setCompoundingStatus(!CompoundingStatus);
        setParticipationStatus(checked);

        //console.log(checked);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/ChangeLuckyBoxStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);

                if (response.data.status_code == 1) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                }
                else {

                }
            })
            .catch(function (error) {
                console.log(error);
            });


    };





    useEffect(() => {
        //console.log(compounding);
        if (!loading) {
            if (participationStatus === true) {
                setParticipationStatus(true);
            }
            else {
                setParticipationStatus(false);
            }
        }



    }, [loading]);
    return (
        <>
            {contextHolder}
            <div className="card-action-title">
                <h3 className="card-title">Tower Millionaire</h3>
                <div className="card-actions">
                    <Space>
                        <span>Participate</span>
                        <Switch
                            onChange={onChange}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={ParticipationStatus}
                            loading={loading}
                        />

                    </Space>

                </div>
            </div>
            {
                ParticipationStatus &&
                <div style={{ position: "relative" }}>

                    <div className="mystery-box-wrap">

                        <p>Amount auto withdrawal in </p>
                        {
                            loading ?
                                <Spin />
                                :
                                <CountdownTimer date={EndDate} />
                        }


                    </div>
                </div>
            }

            <img style={{ borderRadius: "8px" }} src={promotion} />
        </>
    );
}

export default TowerMillionaire;