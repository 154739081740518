import React from 'react';

import { Button, Progress, Popover } from 'antd';

import avatar from "../../../assets/images/avatar.png";


function NetworkMember(props) {

    let percent = 0;
    let remaining = 0;

    const calculatedPercentage = (props.Profit_Received / props.amount) * 100;
    if (isNaN(calculatedPercentage)) {
        percent = 0;
    }
    else {
        percent = calculatedPercentage.toFixed(2);
    }

    remaining = props.amount - props.Profit_Received;

    if(isNaN(remaining)) {
        remaining = 0;
    }
   

    const content = (
        <div>
            <p style={{ marginTop: "0", marginBottom: "0" }}>Received: <strong>${props.Profit_Received ? props.Profit_Received : 0}</strong></p>
            <p style={{ marginTop: "0", }}>Remaining: <strong>${remaining.toFixed(2)}</strong></p>
        </div>
    );


    return (
        <>
            <li className={`level${props.level}`}>
                <div className='tree-card-wrap'>
                    <div className="profile">
                        <img src={props.photo} alt="" />
                        <div className="info">
                            <h5>{props.name}</h5>
                            <p>{props.email}</p>
                        </div>
                    </div>
                    <div className='tree-card-right '>
                        <ul>
                            <li>
                                {
                                    percent >= 100 ?
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="3rem" height="3rem" fill="#f9b707" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg>
                                        :
                                        <div className='progress-wrap'>
                                            <Popover content={content} title="">
                                                <Progress size={50} type="circle" percent={percent} strokeColor="#f9b707" />
                                            </Popover>
                                        </div>
                                }

                            </li>
                            <li>
                                <div className='tree-info'>
                                    <span>
                                        Member Type
                                    </span>
                                    <p>{props.type}</p>
                                </div>
                            </li>
                            <li>
                                <div className='tree-icon-info'>
                                    <span className="material-symbols-outlined">
                                        attach_money
                                    </span>
                                    <div className="amount">
                                        <p>Trading</p>
                                        <h5>{props.amount}</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='tree-icon-info'>
                                    <span className="material-symbols-outlined">
                                        trending_up
                                    </span>
                                    <div className="amount">
                                        <p>Profit</p>
                                        <h5>$ {props.ROI_Balance.toFixed(6)}</h5>
                                    </div>
                                </div>
                            </li>
                            <li className='total_members'>
                                <div className='tree-icon-info'>
                                    <div className="amount">
                                        <p>Total Members</p>
                                        <h5>{props.TeamCount}</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='level'>
                                    <p>Level</p>
                                    <span>{props.level - 1}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <Button type="default">View</Button> */}
            </li>
        </>
    );
}

export default NetworkMember;