import React, { useState } from "react";
import TowergadHeader from "./TowergadHeader";
import { NavLink, Outlet } from "react-router-dom";
import TowergadLoginCheck from "./TowergadLoginCheck";
import TowergadRightsCheck from "./TowergadRightsCheck";
import { useNavigate } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.js";

function TowergadLayout(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(isMenuOpen ? false : true);
  };

  const closeMenu = () => {
    var admin_side_bar = document.getElementById("admin_side_bar");
    var overlay = document.getElementById("overlaymenu");
    var menubutton = document.getElementById("menubutton");
    var body = document.getElementById("body");
    body.classList.remove("overflow-hidden");
    if (admin_side_bar) {
      admin_side_bar.classList.remove("active");
    }

    if (overlay) {
      overlay.classList.remove("active");
    }

    ////console.log(menubutton);

    if (menubutton) {
      menubutton.classList.remove("active");
    }
  };

  let navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/towergad/login");
  };

  return (
    <>
      <TowergadLoginCheck />
      <TowergadRightsCheck />
      <div id="admin-body">
        <TowergadHeader />
        <div onClick={closeMenu} id="overlaymenu"></div>
        <div className="admin-wrap">
          <div className={`admin-left`} id="admin_side_bar">
            <ul className="admin_side_nav">
              <li>
                <NavLink to="/towergad/dashboard">
                  {/* <i class="material-symbols-outlined">dashboard</i> */}
                  <i class="material-symbols-outlined">grid_view</i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/towergad/userstatistics/dashboard">
                  <i class="material-symbols-outlined">trending_up</i>
                  <span>User Statistics</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/towergad/fund/dashboard">
                  <i class="material-symbols-outlined">monetization_on</i>
                  <span>Fund Statistics</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to="/towergad/total-transfer/dashboard">
                  <i class="material-symbols-outlined">local_atm</i>
                  <span>Total Transfers</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/towergad/towergad-fee/dashboard">
                  <i class="material-symbols-outlined"> credit_score</i>
                  <span>Admin Fee</span>
                </NavLink>
              </li>

               <li>
                <NavLink to="/towergad/total-packages/dashboard">
                  <i class="material-symbols-outlined">package</i>
                  <span>Total Trading</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to="/towergad/trading-list">
                  <i class="material-symbols-outlined">equalizer</i>
                  <span>Trades</span>
                </NavLink>
              </li>

              {/* start dashboard 1 side bar  */}

              <li>
                <NavLink to="/towergad/deposit/dashboard">
                  <i class="material-symbols-outlined">insert_chart</i>
                  <span>Deposit Statistics</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/towergad/withdrawal/dashboard">
                  <i class="material-symbols-outlined">
                    account_balance_wallet
                  </i>
                  <span>Withdrawal</span>
                </NavLink>
              </li>

              {/* start dashboard 3 from here  */}

              <li>
                <NavLink to="/towergad/roi/dashboard">
                  <i class="material-symbols-outlined">timeline</i>
                  <span>Send ROI</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/towergad/Reward">
                  <i class="material-symbols-outlined">
                    emoji_events
                  </i>
                  <span>Rewards</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/towergad/ranks/direct-rank">
                  <i class="material-symbols-outlined">
                    stars
                  </i>
                  <span>Direct Ranks</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/towergad/mystery-box-deductions">
                  <i class="material-symbols-outlined">equalizer</i>
                  <span>MystryBox Deductions</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/towergad/PurchaseNow">
                  <i class="material-symbols-outlined">business_center</i>
                  <span>Purchase for Now</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/towergad/security/dashboard">
                  <i class="material-symbols-outlined">privacy_tip</i>
                  <span>Security</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/towergad/setting/dashboard">
                  <i class="material-symbols-outlined">settings</i>
                  <span>Settings</span>
                </NavLink>
              </li>
              <li>
                <a href="#" onClick={handleSignOut}>
                  <i class="material-symbols-outlined">logout</i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="admin-right" id="right-side">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>
  );
}

export default TowergadLayout;
