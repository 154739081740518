import React, { useState, useEffect } from "react";
import { Menu } from 'antd';

import { NavLink } from "react-router-dom";


function TransactionMenu(props) {


  const [current, setCurrent] = useState(props.active);


  const items = [
    {
      label: (<NavLink to="/member/transactions/tradings">Tradings</NavLink>),
      key: 'tradings',

    },
    {
      label: (<NavLink to="/member/transactions/deposits">Deposits</NavLink>),
      key: 'deposits',

    },
    {
      label: (<NavLink to="/member/transactions/transfers">Transfers</NavLink>),
      key: 'transfers',

    },
    {
      label: (<NavLink to="/member/transactions/roi/self">Self ROI </NavLink>),
      key: 'rioself',

    },
    {
      label: (<NavLink to="/member/transactions/roi/first">First Level ROI</NavLink>),
      key: 'roifirst',

    },
    {
      label: (<NavLink to="/member/transactions/roi/second">Second Level ROI</NavLink>),
      key: 'roisecond',

    },
    {
      label: (<NavLink to="/member/transactions/roi/third">Third Level ROI</NavLink>),
      key: 'roithird',

    },
    {
      label: (<NavLink to="/member/transactions/lucky-box">Mystery Box</NavLink>),
      key: 'lucky-box',

    },
    {
      label: (<NavLink to="/member/transactions/instantrewards">Instant Rewards</NavLink>),
      key: 'InstantRewards',

    },
    {
      label: (<NavLink to="/member/transactions/rewards">Rewards</NavLink>),
      key: 'Rewards',

    },
    {
      label: (<NavLink to="/member/transactions/downline-activations/activated-for">Others Purchases</NavLink>),
      key: 'downline',

    },
  ];


  const onClick = (e) => {
    //console.log('click ', e);
    setCurrent(e.key);
  };


  return (
    <>
      <h3 className="card-title">Transactions</h3>
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      <br />
    </>
  );
}

export default TransactionMenu;