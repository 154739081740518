import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
  DatePicker,
  Select,
} from "antd";
import axios from "axios";
import config from "../../../../Config";
import { useNavigate } from "react-router-dom";

const KycDocumentDetails = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Text, Link, Title } = Typography;

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
  const [selectedValue, setSelectedValue] = useState(null);

  let navigate = useNavigate();
  useEffect(()=>{
    document.title= "KYC Document Type"

  },[])
  const handleSubmit = () => {
    if(selectedValue!=null){

        navigate("/member/kyc/document-details/" + selectedValue);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col md={14} offset={5} xs={24}>
          <Card title="Document details" bordered={true}>
            <Title level={5} style={{margin:"0px"}}>
              Please choose your preferred (valid) document for verification.
            </Title>
            <br />

            <Select
              showSearch
              style={{ width:"100%",marginBottom:"4%" ,marginTop:"2%"}}
              placeholder="Select Option"
              
              options={[
                {
                  value: "ID",
                  label: "ID",
                },
                {
                  value: "license ",
                  label: "Driving License",
                },
                {
                  value: "passport",
                  label: "Passport",
                },
              ]}
              onChange={(value) => setSelectedValue(value)}
            />
            <div style={{textAlign:"end"}}>

          


            <Button type="primary" onClick={handleSubmit} >
              
              Continue
            </Button>
              </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KycDocumentDetails;
