import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import axios from "axios"
import config from '../../../Config';
import dayjs from "dayjs";
import TransactionsMenu from './TransactionsMenu';
import Pagination from "../../member/General/Pagination";
import { NavLink, useParams } from 'react-router-dom';


const TransTransfers = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [listOfTransactions, setlistOfTransactions] = useState([]);


  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();

  useEffect(() => {
    document.title = "Transaction of Tower Tradings";

    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: params.id,
      pageNo: pageNo,
      pageSize: pageSize,
      RequestType: "0",
      MemberType: "Admin"

    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/BalanceTrasnferDetails',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setlistOfTransactions(response.data.listofTransfers);
        //setLedgerOld(response.data.ListofPackages);
        setTotalRecords(response.data.totalRecords);
        setLoading(false);

      })
      .catch(function (error) {
        //console.log(error);
      });

  }, [pageNo, pageSize]);


  const renderLedger = () => {
    if (listOfTransactions != null && listOfTransactions.length > 0) {
      return listOfTransactions.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Payment_ID}</td>
            <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
            <td>{item.User_Name}</td>
            <td>{item.Beneficiary_Name}</td>
            <td>{"$" + item.Transaction_Amount}</td>
            <td>{"$" + item.Commission_Amount}</td>
            <td>{"$" + item.Receiveable_Amount}</td>
            <td>{item.Notes}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };
  return (
    <>
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Transactions</div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/towergad/userstatistics/Total-user">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <TransactionsMenu />
        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Fee</th>
                  <th>Receivable Amount</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="8">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TransTransfers