import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import { Card, Row, Col, Button, Form, Input, Checkbox, Modal, message, Spin, Space } from "antd";


// images
import iconBalance from "../../../assets/images/dashboard/icons/balance.svg";
import TradeCard from "../../../assets/images/dashboard/trading/card.svg";
import LogoWhite from "../../../assets/images/logo/towergear_white.png";

function BuyPackage({ IsPackagePurchased }) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    const [ListofPackages, setListofPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [balance, setBalance] = useState(0);

    // downline activation 
    const [IsDownline, setIsDownline] = useState(false);
    const [downlineUserName, setDownlineUsername] = useState(null);
    const [user, setUser] = useState({});
    const [downlineVerified, setDownlineVerified] = useState(null);
    const [downlineLoading, setDownlineLoading] = useState(false);
    const [FeePercentage, setFeePercentage] = useState(0);


    const [form] = Form.useForm();

    // messages
    const [messageApi, contextHolder] = message.useMessage();

    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    // modal to buy package
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (item) => {
        ////console.log(item);
        setSelectedPackage(item);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setSelectedPackage({});
        form.resetFields();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setSelectedPackage({});
        setUser(null);
        setIsDownline(false);
        setDownlineVerified(false);
        form.resetFields();
        setIsModalOpen(false);
    };


    // get all packages
    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetAllPackages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                if (response.data.status_code == 1) {

                    setBalance(response.data.member_balance);
                    setListofPackages(response.data.ListofPackages);
                    setLoading(false);

                }


            })
            .catch(function (error) {

            });

    }, []);

    const renderPackages = () => {
        if (ListofPackages.length > 0) {
            return (
                ListofPackages.map((item, index) => {
                    return (
                        <>
                            <Col xs={24} md={8} key={index}>
                                <div className="package-item">
                                    <img src={TradeCard} />
                                    <div className="card-content">
                                        <div className="top-row">
                                            <img src={LogoWhite} />
                                            <h4>${item.Amount}</h4>
                                        </div>
                                        <div className="bottom-row">
                                            <h3>{item.Name}</h3>
                                            <Button onClick={(e) => showModal(item)} block disabled={item.Amount > balance}>Buy Now</Button>
                                        </div>



                                    </div>
                                </div>
                            </Col>
                        </>
                    )
                })
            )
        }
    }


    // to buy package function
    const onFinish = (formData) => {
        setLoadingButton(true);

        //const modifiedObject = { ...formData, remember: undefined };

        delete formData.remember;


        if (IsDownline) {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                PackageID: selectedPackage.ID,
                Email_Address: user.Email_Address,
                ...formData
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackageDownline',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            console.log(data);
        }
        else {



            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                PackageID: selectedPackage.ID,
                ...formData
            };

            ////console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackage',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
        }

        axios(api_config)
            .then(function (response) {


                //console.log(response.data);
                if (response.data.status_code == 1) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                    handleCancel();
                    IsPackagePurchased();
                    setLoadingButton(false);


                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoadingButton(false);
                }
            })
            .catch(function (error) {


            });


    };


    const handleDownlineCheck = (e) => {
        if (e.target.checked) {
            setIsDownline(true);
        }
        else {
            setIsDownline(false);
        }

    }


    const VerifyUser = () => {
        if (downlineUserName != null) {

            setDownlineLoading(true);

            const downlineData = {
                Email_Address: downlineUserName,
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetBalanceTransferInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: downlineData
            };

            axios(api_config)
                .then(function (response) {
                    //  console.log(response.data);
                    if (response.data.status_code == 1) {
                        setUser(response.data);
                        setDownlineVerified(true);

                    }
                    else {
                        setDownlineVerified(false);

                    }

                    setDownlineLoading(false);


                })
                .catch(function (error) {
                    console.log(error);
                });


            // Package/GetOtherPurchaseFee
            setDownlineLoading(true);
            const dataOther = {
                Email_Address: downlineUserName,
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/GetOtherPurchaseFee',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: dataOther
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setFeePercentage(response.data.FeePercentage);
                        setDownlineLoading(false);

                    }
                    else {

                        setFeePercentage(0);
                        setDownlineLoading(false);
                    }




                })
                .catch(function (error) {
                    console.log(error);
                });



        }
    }

    return (
        <>
            {contextHolder}
            <h3 className="card-title">Start New Trading</h3>
            {
                loading ?
                    <Spin />
                    :
                    <Row gutter={[24, 24]} loading={loading}>
                        {renderPackages()}
                    </Row>
            }



            <Modal
                title={`Buy ${selectedPackage.Name}`}
                open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}

            >
                <div className="modal_new_trading">
                    <div className="icon-box">
                        <div className="icon">
                            <img src={iconBalance} />
                        </div>
                        <div className="info">
                            <p>Available Balance</p>
                            <h4 className="mt-0">${balance}</h4>
                        </div>
                        <div className="info">
                            <p>Package Price</p>
                            <h4 className="mt-0">${selectedPackage.Amount}</h4>
                        </div>
                    </div>
                </div>

                {
                    balance > 0 &&
                    <Form
                        name="normal_login"
                        className="login-form"
                        layout="vertical"
                        size="large"
                        onFinish={onFinish}
                        form={form}
                    >

                        <Form.Item
                            name=""
                            valuePropName="checked"
                        >
                            <Checkbox onChange={handleDownlineCheck}>Click here to purchase for others</Checkbox>
                        </Form.Item>

                        {
                            IsDownline &&
                            <>
                                <Form.Item
                                    label="Enter Email / Username"
                                    hasFeedback
                                    validateStatus={downlineLoading ? "validating" : downlineVerified === true ? "success" : "error"}
                                    help={downlineLoading ? "The information is being validated..." : ""}
                                >
                                    <Space.Compact
                                        style={{
                                            width: "100%",
                                        }}
                                    >

                                        <Input
                                            placeholder="Enter Email / Username"
                                            status="success"
                                            onChange={(e) => {
                                                setDownlineUsername(e.target.value);
                                                //setDownlineVerified(false);
                                            }}
                                        />

                                        <Button
                                            type="default"
                                            onClick={VerifyUser}


                                        >
                                            VERIFY
                                        </Button>
                                    </Space.Compact>
                                </Form.Item>

                                {
                                    downlineVerified &&
                                    <Form.Item
                                        label="Fee Percentage"
                                    >
                                        <Input value={FeePercentage + "%"} readOnly />
                                    </Form.Item>
                                }
                    </>
                }


                <Form.Item
                    name="PinCode"
                    label="Enter Security Pass"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your 6-digit Security Pass.',
                        },
                        {
                            pattern: /^\d{6}$/,
                            message: 'Please enter a 6-digit number.',
                        },
                    ]}
                >
                    <Input placeholder="PIN" maxLength={6} showCount />
                </Form.Item>
                {/* <Form.Item
                            name="Email" label="Enter Email Address" hasFeedback validateStatus="success">
                            <Input placeholder="Enter Email Address" id="success" />
                        </Form.Item> */}

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            message: 'Please accept the terms and conditions to proceed.',
                        },
                    ]}

                >
                    <Checkbox>I agree with Terms & Conditions</Checkbox>
                </Form.Item>

                <div className="modal-footer-buttons">
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" htmlType="submit" loading={loadingButton}>
                        Submit
                    </Button>
                </div>
            </Form>
                }



        </Modal >

        </>
    );
}

export default BuyPackage;