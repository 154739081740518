import React, {useState, useEffect} from "react";
import config from "../../../Config";
import axios from "axios";
import TransactionMenu from './TransactionMenu';
import { Table, Pagination } from "antd";
import dayjs from "dayjs";


function TransDeposits(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPaid, settotalPaid] = useState(0);


    useEffect(() => {
        document.title = "Crypto Deposits";


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            PageNo: pageNo,
            PageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetDeposits',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                ////console.log(response.data.ListofOrders);
                setLedger(response.data.ListofOrders);
                setLedgerOld(response.data.ListofOrders);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                settotalPaid(response.data.totalPaid);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };


    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'PaymentID',
            key: 'paymentid',
        },
        
        {
            title: 'Date',
            dataIndex: 'Created_Date',
            key: 'Created_Date',
            render: (_, record) => (
                dayjs(record.Created_Date).format(config.date_format)
            )
        },
        {
            title: 'Payment Mode',
            dataIndex: 'PaymentMode',
            key: 'mode'
        },
        {
            title: 'Deposit Amount',
            dataIndex: 'Amount',
            key: 'deposit',
        },
        {
            title: 'Received in USD',
            dataIndex: 'ReceivedUSD',
            key: 'received',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
           
        },
        
        {
            title: 'Notes',
            dataIndex: 'Message',
            key: 'Notes',
            
        },
    ];
    


    return (
        <>
            <TransactionMenu active="deposits" />
            <Table dataSource={listOfLedger} columns={columns} size="small" loading={loading} />
            <div className="pagination-wrap">

                {totalRecords > 10 && (
                    <Pagination
                        defaultCurrent={pageNo}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={page => setPageNo(page)}
                    />
                )}
            </div>
        </>
    );
}

export default TransDeposits;