import React from 'react'
import { NavLink } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TransSecond from './TransSecond';
import { useParams } from 'react-router-dom';

const TransactionsMenu = (props) => {

  let params = useParams();
  const ID = params.id;

  
  return (
    <>

      <ul className="nav nav-pills">
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/trading/${ID}`}>Tradings</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/deposit/${ID}`}>Deposits</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/transfer/sent/${ID}`}>Balance Sent</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/transfer/received/${ID}`}>Balance Received</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/self-roi/${ID}`}>Self ROI</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/first-level/${ID}`}>First Level ROI</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/second-level/${ID}`}>Second Level ROI</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/third-level/${ID}`}>Third Level ROI</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/mystery/${ID}`}>Mystery Box</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/towergad/transactions/rewards/${ID}`}>Rewards</NavLink>
        </li>
      </ul>

    </>
  )
}

export default TransactionsMenu