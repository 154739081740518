import React from 'react';

import amazone from "../../../assets/images/landing/partners/amazon.svg";
import apple from "../../../assets/images/landing/partners/apple.svg";
import atandt from "../../../assets/images/landing/partners/atandt.svg";
import cocacola from "../../../assets/images/landing/partners/cocacola.svg";
import google from "../../../assets/images/landing/partners/google.svg";
import intel from "../../../assets/images/landing/partners/intel.svg";
import kfc from "../../../assets/images/landing/partners/kfc.svg";
import linkedin from "../../../assets/images/landing/partners/linkedin.svg";
import mcdonald from "../../../assets/images/landing/partners/mcdonald.svg";
import microsoft from "../../../assets/images/landing/partners/microsoft.svg";
import nike from "../../../assets/images/landing/partners/nike.svg";
import pepsi from "../../../assets/images/landing/partners/pepsi.svg";
import pg from "../../../assets/images/landing/partners/pg.svg";
import samsung from "../../../assets/images/landing/partners/samsung.svg";
import toyota from "../../../assets/images/landing/partners/toyota.svg";


function Partners(props) {
    return (
        <section className='simple-section partners-section'>
            <div className='tg-container'>
                <div className='section-title'>
                    <h4 className='text-center'>Partners</h4>
                    <p className='section-detail text-center mt-0'>Trusted Collaborations: Our Esteemed Partnerships in the Trading Realm</p>
                </div>
                <ul className='partners-row'>
                    <li>
                        <img src={amazone} alt="partner" />
                    </li>
                    <li>
                        <img src={apple} alt="partner" />
                    </li>
                    <li>
                        <img src={atandt} alt="partner" />
                    </li>
                    <li>
                        <img src={cocacola} alt="partner" />
                    </li>
                    <li>
                        <img src={google} alt="partner" />
                    </li>
                    <li>
                        <img src={intel} alt="partner" />
                    </li>
                    <li>
                        <img src={kfc} alt="partner" />
                    </li>
                    <li>
                        <img src={linkedin} alt="partner" />
                    </li>
                    <li>
                        <img src={mcdonald} alt="partner" />
                    </li>
                    <li>
                        <img src={microsoft} alt="partner" />
                    </li>
                    <li>
                        <img src={nike} alt="partner" />
                    </li>
                    <li>
                        <img src={pepsi} alt="partner" />
                    </li>
                    <li>
                        <img src={pg} alt="partner" />
                    </li>
                    <li>
                        <img src={samsung} alt="partner" />
                    </li>
                    <li>
                        <img src={toyota} alt="partner" />
                    </li>
                </ul>
            </div>

        </section>

    );
}

export default Partners;