import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
} from "antd";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config";
const KycUserDetail = () => {
  const navigate = useNavigate();

  const { Text, Link, Title } = Typography;
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const { MemberID } = useParams();
  const [form] = Form.useForm();
  useEffect(() => {
  document.title= "KYC User Details"

    //console.log(MemberID);

    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "MemberKYC/GetMemberKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setUser(response.data);
          setLoading(false);
          //console.log(user);
          form.setFieldsValue({
            ...response.data,
          });
        } else if (response.data.status_code === 0) {
          navigate("/login");
        } else {
          //   toast.error(response.data.status_message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
        }
      })
      .catch(function (error) {
        // toast.error('Network Error..', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  }, []);
  //console.log(user.FirstName);
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col md={14} offset={5} xs={24}>
          <Card title="Personal details" bordered={true}>
            <Text style={{ float: "right" }}>
              
              Document Item (
              {user.DocumentType == "License" ? (
                <>
                  <>Driving license</>
                </>
              ) : user.DocumentType === "ID" ? (
                <>
                  <>ID</>
                </>
              ) : user.DocumentType === "Passport" ? (
                <>
                  <>Passport</>
                </>
              ) : (
                <></>
              )}
              )
            </Text>

            <div className="profile-wrap">
              <>
                <Form
                  name="basic"
                  layout="vertical"
                  size="large"
                  form={form}
                  //   onFinish={handleSubmit}
                  autoComplete="off"
                >
                  <Form.Item
                    label="First Name"
                    name="FirstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name",
                      },
                    ]}
                  >
                    <Input readOnly/>
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    name="LastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input LastName",
                      },
                    ]}
                  >
                    <Input readOnly   />
                  </Form.Item>
                  <Form.Item
                    label="Birth Country"
                    name="BirthCountry"
                    rules={[
                      {
                        required: true,
                        message: "Please input LastName",
                      },
                    ]}
                  >
                    <Input readOnly   />
                  </Form.Item>
                  <Form.Item
                    label="Current Status"
                    name="CurrentStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please input LastName",
                      },
                    ]}
                  >
                    <Input readOnly   />
                  </Form.Item>
                </Form>
                <div style={{textAlign:"end"}}>
                    <NavLink to="/member/dashboard">

                    <Button type="primary">Go Back</Button>
                    </NavLink>
                </div>
              </>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KycUserDetail;
