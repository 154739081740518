import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import axios from "axios"
import config from '../../../Config';
import dayjs from "dayjs";
import { NavLink, useParams } from 'react-router-dom';
import Pagination from "../../member/General/Pagination";
import { calculateColumnTotal } from '../../Common/Util';
import { NumberFormat } from '../../member/General/Functions';
const AdminTransfers = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransfers, setlistOfTransfers] = useState([]);
    const [totalTransactionAmount, settotalTransactionAmount] = useState(0);
    const [totalReceiveableAmount, settotalReceiveableAmount] = useState(0);
    const [totalFee, settotalFee] = useState(0);

    const [showFilters, setShowFilters] = useState(true);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    let params = useParams();

    useEffect(() => {
        document.title = "Admin Transfers";

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: StartDate,
            endDate: EndDate,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetAdminTransfers',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setlistOfTransfers(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                settotalTransactionAmount(response.data.totalTransactionAmount);
                settotalReceiveableAmount(response.data.totalReceiveableAmount);
                settotalFee(response.data.totalFee);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });


    }, [pageNo, pageSize, StartDate, EndDate]);


    const renderLedger = () => {
        if (listOfTransfers != null && listOfTransfers.length > 0) {
            return listOfTransfers.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.ID}</td>
                        <td>{dayjs(item.Transaction_Date).format("DD/MM/YYYY")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Email_Address}</td>
                        <td>{"$" + item.Transaction_Amount}</td>
                        <td>{"$" + item.Receiveable_Amount}</td>
                        <td>{"$" + item.Commission_Amount}</td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="8">
                        <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };
    return (
        <>
            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">Admin Transfers</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="/towergad/total-transfer/dashboard">
                                <button type="button" className="button button-primary">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i class="material-symbols-outlined ms-2">
                                    page_info
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Filters Menu */}
                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end gy-3">
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <TransactionsMenu /> */}
                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Transaction Date</th>
                                    <th>User Name</th>
                                    <th>Email Address</th>
                                    <th>Transaction Amount</th>
                                    <th>Receiveable Amount</th>
                                    <th>Commission Amount</th>

                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colspan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'>Page Total:</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'Transaction_Amount')}</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'Receiveable_Amount')}</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'Commission_Amount')}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'> Grand Total:</td>
                                            <td>${totalTransactionAmount}</td>
                                            <td>${totalReceiveableAmount}</td>
                                            <td>${totalFee}</td>
                                        </tr>

                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default AdminTransfers