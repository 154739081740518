import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
   
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import TowergadRightsCheck from "../TowergadRightsCheck";

function KycRequest(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "KYC Requests";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetKYCList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // setIsUpdated(true)
    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofLedger);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/towergad/login");
        } else {
             
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        //console.log(item);
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.SelectedMember.AccountKey}</td>
            <td>{item.SelectedMember.Email_Address}</td>
            <td>{item.SelectedMember.Investment_Balance}</td>
            <td>{item.SelectedMember.Current_Balance}</td>
            <td>{item.FirstName}</td>
            <td>{item.LastName}</td>
            <td>{item.BirthCountry}</td>
            <td>{item.DocumentType}</td>
            <td>{item.CurrentStatus}</td>

            <td className="text-center">
              <Link to={`/towergad/userstatistics/user-details/${item.ID}`}>view Details</Link>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="11">
            <h4
              className="mt-3"
              style={{ fontSize: "15px" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
    <TowergadRightsCheck />
      <div className="admin-content">
        <div className="text-end mb-3">
          <NavLink to="/towergad/userstatistics/dashboard">
            <button type="button" className="button button-primary">
              Back
            </button>
          </NavLink>
        </div>
        <h3>List of KYC Request</h3>
        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Account Key</th>
                  <th>Email Address</th>
                  <th>Total Package</th>
                  <th>Balance</th>
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>BirthCountry</th>
                  <th>DocumentType</th>
                  <th>CurrentStatus</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="11">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default KycRequest;
