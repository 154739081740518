import React, { useEffect } from "react";
import Footer from "../include/Footer";
import Header from "../include/Website_Header";
import bitcoin_laptop from "../../../assets/images/about/bitcoin_laptop.png";
import staff from "../../../assets/images/about/staff.jpg";
import About_Logo from "../../../assets/images/about/About_Logo.png";
import { Col, Row } from "antd";
import Partners from "../sections/Partners";
import Page_head from "../../member/layout/Page_head";
import { NavLink } from "react-router-dom";

const About = () => {
  useEffect(() => {
    document.title = "About Us ";
  }, []);
  return (
    <>
      <Header />
      <Page_head page_title="About" />
      <div className="simple-section bg-black pt-0">
        <div className="tg-container">
          <Row align={"middle"} gutter={50}>
            <Col md={12} xs={24} align={"middle"}>
              <div className="laptop_coin">
                <div className="upper_card">
                  <div className="section-title">
                    <h4 className="primary">10 Years</h4>
                    <div className="subtitle">Consulting Experience</div>
                  </div>
                </div>
                <div className="image-container">
                  <img src={bitcoin_laptop} alt="" />
                </div>
                <div className="lower_card">
                  <div className="section-title">
                    <h4 className="primary">Expert Guidance</h4>
                    <div className="subtitle">Exceptional Results</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="section-title">
                <h3>
                  About <span>Tower Gear Plus:</span> Elevating Your Trading
                  Experience
                </h3>
              </div>
              <div className="section-detail">
                At Tower Gear Plus, we redefine the way you trade, offering a dynamic
                platform designed to elevate your financial journey. Our
                commitment lies in providing you with a seamless trading
                experience, backed by cutting-edge technology and a team of
                experts dedicated to your success. Tower Gear Plus is not just a
                trading platform; it’s your gateway to a world of possibilities,
                empowering you to navigate the financial markets with confidence
                and precision.
              </div>
              <NavLink to="/register">
                <button className="button button-primary button-icon mt-5">
                  Start Trading
                  <span className="material-symbols-outlined">
                    arrow_forward_ios
                  </span>
                </button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
      <div className="simple-section staff-section">
        <div className="tg-container">
          <Row
            align={"middle"}
            gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
          >
            <Col md={10} xs={24}>
              <div className="section-title">
                <h3>
                  Get to know about people behind <span>Tower Gear Plus</span>
                </h3>
              </div>
              <div className="section-detail">
                At Tower Gear Plus, we are a passionate team of innovators and
                industry experts dedicated to revolutionizing the Property
                Exchange Commodities sector. Our journey began with a shared
                vision to create cutting-edge solutions that redefine the
                standards of excellence within this dynamic field.
              </div>

              <div class="icon-box-title about mt-5">
                <span class="material-symbols-outlined">verified_user</span>
                <div class="content">
                  <h1>Certified professionals</h1>
                  <div className="section-detail">
                    Our team comprises certified professionals dedicated to
                    excellence, bringing expertise and innovation to every
                    project we undertake
                  </div>
                </div>
              </div>
              <div class="icon-box-title about">
                <span class="material-symbols-outlined">hotel_class</span>
                <div class="content">
                  <h1>98% Recommendeed us</h1>
                  <div className="section-detail">
                    Ninety-eight percent of our clients highly recommend us for
                    our exceptional quality, service, and reliable results.
                  </div>
                </div>
              </div>
              <div class="icon-box-title about">
                <span class="material-symbols-outlined">
                  signal_cellular_alt
                </span>
                <div class="content">
                  <h1>Real Experiences</h1>
                  <div className="section-detail">
                    Real experiences from our journey, challenges we've
                    conquered, and milestones achieved in our relentless pursuit
                    of excellence.
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <img src={staff} alt="" className="staff" />
            </Col>
            <Col md={7} xs={24} align={"middle"}>
              <div className="black-img">
                <div className="section-title">
                  <h4>50+</h4>
                  <div className="section-detail">Global Clients</div>
                </div>
                <div className="subtitle mt-md-2">
                  <span>Highly Professional</span>
                </div>
                <h3>Award Nominated Engineers</h3>
                <div className="section-detail">
                  Our award-nominated engineers bring innovation and expertise
                  to the forefront, leading the industry in cutting-edge
                  solutions.
                </div>
                <img src={About_Logo} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="simple-section bg-black">
        <div className="tg-container">
          <div className="roadmap">
            <div className="section-title">
              <h3 className="primary">Roadmap</h3>
            </div>
            <Row align={"top"} gutter={50}>
              <Col md={8} xs={24}>
                <div className="roadmap-block">
                  <h3 className="list-heading">2024 Q1</h3>
                  <ul class="dot_list">
                    <li>
                      <span></span>Launch our flagship product with enhanced
                      features.
                    </li>
                    <li>
                      <span></span>Begin strategic partnerships with key
                      industry players.
                    </li>
                    <li>
                      <span></span>Kickstart marketing campaigns to build brand
                      awareness.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={8} xs={24}>
                <div className="roadmap-block">
                  <h3 className="list-heading">2024 Q2</h3>
                  <ul class="dot_list">
                    <li>
                      <span></span>Expand product offerings with new modules and
                      services.
                    </li>
                    <li>
                      <span></span>Host webinars and industry events to engage
                      with our community.
                    </li>
                    <li>
                      <span></span>Initiate user feedback surveys for continuous
                      improvement.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={8} xs={24}>
                <div className="roadmap-block">
                  <h3 className="list-heading">2024 Q3</h3>
                  <ul class="dot_list">
                    <li>
                      <span></span>Achieve a 25% increase in customer base.
                    </li>
                    <li>
                      <span></span>Implement advanced AI-driven features for
                      better user experiences.
                    </li>
                    <li>
                      <span></span>Explore international market expansion
                      opportunities.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Partners />
      <Footer />
    </>
  );
};

export default About;
