import React, { useEffect, useState } from 'react';

function ServerTime(props) {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // Update the current time every second
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures useEffect runs only once after initial render

    // Format the current time as "Sat, 07 Oct 2023 11:12:20"
    const formattedTime = currentTime.toUTCString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    });

    return <>{formattedTime}</>;
}

export default ServerTime;
