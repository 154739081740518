import React from 'react'
import iconRank from "../../../assets/images/dashboard/icons/rank.svg";

const RankCard = (props) => {
    return (
        <>
        <div className="rank-box">
                <div className="flag">
                    <img src={props.flag} alt="" />
                    <span>{props.country_name}</span>
                </div>
                <div className="profile">
                    <div className="img-warper">
                        <img src={props.user} alt="" />
                    </div>
                    <div className="info">
                        <h1>{props.name}</h1>
                        <p>{props.email}</p>
                    </div>
                </div>
                <div className="rank">
                    <img src={iconRank} alt="" />
                    <div className="detail">
                        <span>Rank</span>
                        <p>{props.rank}</p>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default RankCard