import React, { useEffect } from "react";
import Footer from "../include/Footer";
import Header from "../include/Website_Header";
import phone from "../../../assets/images/landing/phone.svg";
import mail from "../../../assets/images/landing/mail.svg";
import location from "../../../assets/images/landing/location.svg";
import { Col, Row } from "antd";
import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import Page_head from "../../member/layout/Page_head";
const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  // messages
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    messageApi.open({
      type: "success",
      content: "User massage has been send successfully",
    });
  };
  return (
    <>
      {contextHolder}
      <Header />
      <Page_head page_title="Contact" />
      <div className="simple-section bg-black">
        <div className="tg-container">
          <Row className="gy-5">
            <Col md={24} xs={24}>
              
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2485.757883159642!2d-0.11732312423828518!3d51.4626016139762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s605%20Brixton%20Market%2C%20Subway%20Station%2C%20London%2C%C2%A0United%C2%A0Kingdom.!5e0!3m2!1sen!2s!4v1698141809358!5m2!1sen!2s"
                width="100%"
                                height="400"
                                style={{ borderRadius: 10 }}
                                allowFullScreen=""
                                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
          <Row gutter={25} className="mt-5">
            <Col md={15} xs={24}>
              <h1>
                <b> Contact Us </b>
              </h1>
              <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Row gutter={25}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Full Name"
                      name="Full Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Full Name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Subject"
                      name="Subject"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Subject!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Phone No"
                      name="Phone No"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Phone No!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Massage"
                  name="Massage"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Massage!",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col md={9} xs={24}>
              <h1>
                <b>Information</b>
              </h1>
              <div className="link mt-3">
                <div className="socila-link">
                  <div className="icon">
                    <img src={phone} alt="" />
                  </div>
                  <div className="detail">
                    <h5>Call</h5>
                    <p>+7 (342) 249-5509</p>
                  </div>
                </div>
                <div className="socila-link my-4">
                  <div className="icon">
                    <img src={mail} alt="" />
                  </div>
                  <div className="detail">
                    <h5>Email</h5>
                    <p>info@towergear.world</p>
                  </div>
                </div>
                <div className="socila-link">
                  <div className="icon">
                    <img src={location} alt="" />
                  </div>
                  <div className="detail">
                    <h5>Address</h5>
                    <p>
                      605 Brixton Market, Subway Station,
                      London, United Kingdom.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
