import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from "../../../Config";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  Modal,
  Upload,
  message,
  Space,
  Spin
} from "antd";
import { Menu} from "antd";
import { LockOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';


import { NavLink } from 'react-router-dom';

const Setting = () => {
    const items = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: (<NavLink to="/member/kyc">KYC</NavLink>),
        }
         
    ];
  return (
   <>

<h3 className="card-title">Settings</h3>
      <Row gutter={[24, 24]}>
        <Col md={6} xs={24}>
        <Menu
                        mode="inline"
                       
                        items={items}
                    />
        </Col>
        
      </Row>
   </>
  )
}

export default Setting