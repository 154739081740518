import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import TowergadRightsCheck from "../TowergadRightsCheck";
import { Form } from "react-bootstrap";
import { message } from "antd";
import Cropper from "react-cropper";
import { calculateColumnTotal } from "../../Common/Util";

function TradingList(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;

    // //console.log(userstatus.userstatus);

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);
    const [validatedReward, setValidatedReward] = useState(false);
    const [validatedMystryBox, setValidatedMystryBox] = useState(false);
    const [totalAmount, settotalAmount] = useState(0);

    const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
    const [loadingUpdateBtnReward, setLoadingUpdateBtnReward] = useState(false);
    const [loadingUpdateBtnMystryBox, setLoadingUpdateBtnMystryBox] =
        useState(false);

    const [selectedMemberID, setSelectedMemberID] = useState(0);
    const [selectedMember, setSelectedMember] = useState({});

    const [messageApi, contextHolder] = message.useMessage();
    //image

    const [dragging, setDragging] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);

    const [cropper, setCropper] = useState();
    const [image, setImage] = useState("");

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

    //   //console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [show, setShow] = useState(false);
    const [showReward, setShowReward] = useState(false);
    const [showMystryBox, setShowMystryBox] = useState(false);

    //filters
    const [status, setStatus] = useState("Active");
    const [filteremail, setFilterEmail] = useState("");
    const [filterFullname, setFilterFullname] = useState("");
    
    const [filterminBalance, setFilterminBalance] = useState(0);
    const [filterMemberType, setFilterMemberType] = useState("");
    const [filterSortBy, setFilterSortBy] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterPackageBalance, setFilterPackageBalance] = useState("");
    const [filterStartDate, setfilterStartDate] = useState("");
    const [filterEndDate, setfilterEndDate] = useState("");

    let navigate = useNavigate();

    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        document.title = "Trades";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetPackageList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(api_config)
            .then(function (response) {
                //console.log(response.data)
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    settotalAmount(response.data.totalAmount);
                    ////console.log(totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    navigate("/towergad/login");
                } else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [pageNo, pageSize, isUpdated]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>{count++ + pageSize * (pageNo - 1)}</td>
                        <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Name}</td>
                        <td>{item.PackagePrice}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="5">
                        <h4 className="mt-3 text-center" style={{ fontSize: "15px", color: "white" }}>
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    const handleSearch = () => {
        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: filterStartDate,
            endDate: filterEndDate,
            Amount: filterPackageBalance,
            UserName: filterFullname,
            sortBy: filterSortBy,
            Email_Address: filteremail

        };

        console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetPackageList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    navigate("/towergad/login");
                } else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    };

    return (
        <>
            {contextHolder}

            <TowergadRightsCheck />

            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">Trades</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="/towergad/dashboard">
                                <button type="button" className="button button-primary">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary  ms-3"
                                style={{
                                    fontWeight: "500",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i class="material-symbols-outlined">filter_list</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Start Date</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterStartDate}
                                                    onChange={(e) => setfilterStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">End Date</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterEndDate}
                                                    onChange={(e) => setfilterEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Package Balance</label>
                                            <div className="inputwrap mt-2 mb-2">
                                            <select className="form-select" value={filterPackageBalance}
                                                onChange={(e) => setFilterPackageBalance(e.target.value)}>
                                                <option value="">Select an option</option>

                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="300">300</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                <option value="2000">2000</option>
                                                <option value="3000">3000</option>
                                            </select>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">User Name</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={filterFullname}
                                                    onChange={(e) => setFilterFullname(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Email Address</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={filteremail}
                                                    onChange={(e) => setFilterEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Sort By</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={filterSortBy}
                                                    onChange={(e) => setFilterSortBy(e.target.value)}
                                                >
                                                    <option value="Oldest On Top" selected>
                                                        Oldest On Top
                                                    </option>
                                                    <option value="Latest On Top">Latest On Top</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col text-end">
                                        <button
                                            onClick={handleSearch}
                                            className="button button-primary "
                                            style={{
                                                fontWeight: "500",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Created Date</th>
                                    <th>User ID</th>
                                    <th>Package Name</th>
                                    <th>Package Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colspan="5">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                    <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'>Page Total:</td>
                                            <td>${calculateColumnTotal(listOfLedger, 'PackagePrice')}</td>
                                            
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'> Grand Total:</td>
                                            <td>${totalAmount}</td>
                                            
                                        </tr>

                                    
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default TradingList