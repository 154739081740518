
import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Select, Modal, message } from 'antd';
import config from '../../../Config';
import axios from 'axios';


function AddWalletBank(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));



    const [IsDefault, setIsDefault] = useState(false);


    // loadings
    const [loading, setLoading] = useState(false);

    // messages
    const [messageApi, contextHolder] = message.useMessage();


    const onFinish = (formData) => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            IsDefault: IsDefault,
            WalletType: "Bank Account",
            ...formData
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/AddWallet',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {

                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                    props.close();

                    //onWalletAdd();

                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                }


            })
            .catch(function (error) {


            });
    }

    const onChangeDefault = (e) => {
        setIsDefault(e.target.checked);
    };


    const handleChangeCurrency = (value) => {

    }

    return (
        <>
            {contextHolder}
            <Modal
                title="Add Bank Wallet"
                open={props.status}
                //onOk={props.close}
                onCancel={props.close}
                footer={null}
                width={600}
            >
                <p>Please make sure your wallet address aligns with the selected payment mode.</p>

                <Form
                    size="large"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[20, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item label="Nick Name" name="Nick">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Account Title"
                                name="WalletName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter account name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Account Holder Name"
                                name="AccountTitle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter wallet address.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="IBAN"
                                name="Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter IBAN number.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Currency"
                                name="AccountCurrency"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Currency',
                                    },
                                ]}
                            >
                                <Select
                                    
                                    //onChange={handleChangeCurrency}
                                    options={[
                                        { value: 'PKR', label: 'PKR' },
                                        { value: 'AED', label: 'AED' },
                                        { value: 'EUR', label: 'EUR' },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Bank Name"
                                name="BankName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Bank name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>




                    <Checkbox onChange={onChangeDefault}>Set as Default</Checkbox>
                    <Form.Item
                        label="PIN Code"
                        name="PinCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter PIN Code',
                            },
                        ]}
                        style={{ marginTop: "20px" }}>
                        <Input />
                    </Form.Item>
                    <div style={{ textAlign: "right" }}>
                        <Button htmlType="submit" type="primary" loading={loading}>Save</Button>
                    </div>



                </Form>

            </Modal>
        </>
    );
}

export default AddWalletBank;