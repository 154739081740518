import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/style.css";






//import './antd/dist/antd.less';
//import "./assets/css/main.less";

import Login from "./components/auth/Login";
import Dashboard from "./components/member/dashboard/Dashboard";
import Home from "./components/Website/Home";
import Main from "./components/member/layout/Main";

import { ConfigProvider, theme } from "antd";
import Register from "./components/auth/Register";
import Tradings from "./components/member/tradings/Tradings";
import UpdateProfile from "./components/member/profile/UpdateProfile";
import ChangePassword from "./components/member/profile/ChangePassword";
import ChangePin from "./components/member/profile/ChangePin";
import Deposit from "./components/member/deposit/Deposit";
import Transfer from "./components/member/transfer/Taransfer";
import Referrals from "./components/member/referrals/Referrals";
import Network from "./components/member/network/Network";
import Withdrawal from "./components/member/withdrawal/Withdrawal";
import Ranks from "./components/member/ranks/Ranks";
import Wallets from "./components/member/paymentmodes/Wallets";
import TransTradings from "./components/member/transactions/TransTradings";
import TransDeposits from "./components/member/transactions/TransDeposits";
import TransTransfer from "./components/member/transactions/TransTransfer";
// import TransWithdrawal from "./components/member/transactions/TransWithdrawal";
import Forgot from "./components/auth/Forgot";
import KycStep1 from "./components/member/settings/kyc/KycStep1";
import KycBirthDetails from "./components/member/settings/kyc/KycBirthDetails";
import KycDocumentDetails from "./components/member/settings/kyc/KycDocumentDetails";
import DocLicense from "./components/member/settings/kyc/DocLicense";
import DocPassport from "./components/member/settings/kyc/DocPassport";
import DocID from "./components/member/settings/kyc/DocID";
import About from "./components/Website/pages/About";
import Services from "./components/Website/pages/Services";
import Pages from "./components/Website/pages/Pages";
import News from "./components/Website/pages/News";
import Contact from "./components/Website/pages/Contact";
import TransROISelf from "./components/member/transactions/TransROISelf";
import TransROISecond from "./components/member/transactions/TransROISecond";
import TransROIThird from "./components/member/transactions/TransROIThird";
import TransROIFirst from "./components/member/transactions/TransROIFirst";
import KycSubmitted from "./components/member/settings/kyc/KycSubmitted";
import Setting from "./components/member/settings/Setting";
import KycCong from "./components/member/settings/kyc/KycCong";
import KycUserDetail from "./components/member/settings/kyc/KycUserDetail";











import TowergadDashboard from "./components/Towergad/TowergadDashboard";
import TowergadLogin from "./components/Towergad/TowergadLogin";
import TowergadLayout from "./components/Towergad/TowergadLayout";
import DashboardOne from "./components/Towergad/DashboardOne";
import UserStatisticsDashboard from "./components/Towergad/UserStatistics/UserStatisticsDashboard";
import FundDashboard from "./components/Towergad/Fund/FundDashboard";
import MembersList from "./components/Towergad/UserStatistics/MembersList";
import BalanceStatisticsDashboard from "./components/Towergad/BalanceStatistics/BalanceStatisticsDashboard";
import TotalTransferDashboard from "./components/Towergad/TotalTransfers/TotalTransferDashboard";
import TowergadFeeDashboard from "./components/Towergad/TowergadFee/TowergadFeeDashboard";
import TotalPackagesDashboard from "./components/Towergad/TotalPackages/TotalPackagesDashboard";
import MonthlyFundsDashboard from "./components/Towergad/MonthlyFund/MonthlyFundsDashboard";
import PackagesStatisticsDashboard from "./components/Towergad/PackageStatistics/PackagesStatisticsDashboard";
import BonusStatisticsDashboard from "./components/Towergad/BonusStatistics/BonusStatisticsDashboard";
import CompanyProfitDashboard from "./components/Towergad/CompanyProfit/CompanyProfitDashboard";
import DepositDashboard from "./components/Towergad/Deposit/DepositDashboard";
import WithdrawalDashboard from "./components/Towergad/Withdrawal/WithdrawalDashboard";
import BonusPanelDashboard from "./components/Towergad/BonusPanel/BonusPanelDashboard";
// import PassiveBonusDashboard from " ./components/Towergad/PassiveBonus/PassiveBonusDashboard";
// import RewardBonusDashboard from " ./components/Towergad/RewardBonus/RewardBonusDashboard";
// import OtherRewardBonusDashboard from " ./components/Towergad/OtherRewardBonus/OtherRewardBonusDashboard";
import CommodityPannelDashboard from "./components/Towergad/CommodityPanel/CommodityPannelDashboard";
import RoiDashboard from "./components/Towergad/Roi/RoiDashboard";
import TreeBonusDashboard from "./components/Towergad/TreeBonus/TreeBonusDashboard";
// import MartixBonusDashboard from "./components/Towergad/MatrixBonus/MartixBonusDashboard";
import TowergadCommodityDashboard from "./components/Towergad/Commodity/TowergadCommodityDashboard";
import KycRequest from "./components/Towergad/UserStatistics/KycRequests";
import UserDetailsPage from "./components/Towergad/UserStatistics/UserDetailsPage";


import TotalPackageList from "./components/Towergad/Fund/TotalPackageList";
import CompanyProfitList from "./components/Towergad/CompanyProfit/CompanyProfitList";
import CryptoDeposit from "./components/Towergad/Deposit/CryptoDeposit";
import NowPayment from "./components/Towergad/Deposit/NowPayment";
import CoinPayment from "./components/Towergad/Deposit/CoinPayment";
import TotalDeposit from "./components/Towergad/Deposit/TotalDeposit";
import CryptoWithdrawalList from "./components/Towergad/transactions/Withdrawal/CryptoWithdrawalList";
import DirectBonusList from "./components/Towergad/BonusPanel/DirectBonusList";
import NetworkBonusList from "./components/Towergad/BonusPanel/NetworkBonusList"
import ReferralBonusList from "./components/Towergad/BonusPanel/ReferralBonusList";
import RenewalBonusList from "./components/Towergad/BonusPanel/RenewalBonusList";
// import RoiBonusList from "./components/Towergad/PassiveBonus/RoiBonusList";
// import TreeBonusList from "./components/Towergad/PassiveBonus/TreeBonusList";
// import MatrixBonusList from "./components/Towergad/PassiveBonus/MatrixBonusList";

// import Investus20_20List from "./components/Towergad/PassiveBonus/Investus20_20List";
import TowergadInvestorBonus from "./components/Towergad/CommodityPanel/TowergadInvestorBonus";
import TowergadRepurchaseBonus from "./components/Towergad/CommodityPanel/TowergadRepurchaseBonus"
import TowergadRpInvestorBonus from "./components/Towergad/CommodityPanel/TowergadRpInvestorBonus";
import OpenTicketList from "./components/Towergad/Support/OpenTicketList";
import Approved from "./components/Towergad/Withdrawal/Approved";
import Rejected from "./components/Towergad/Withdrawal/Rejected";
import Succeeded from "./components/Towergad/Withdrawal/Succeeded";
import Canceled from "./components/Towergad/Withdrawal/Canceled";
import Initiated from "./components/Towergad/Withdrawal/Initiated";
import TowergadDirectBonus from "./components/Towergad/CommodityPanel/TowergadDirectBonus";
import SecurityDashboard from "./components/Towergad/Security/SecurityDashboard";
import ManageUsers from "./components/Towergad/Security/ManageUsers";
import AddUser from "./components/Towergad/Security/AddUser";
import UpdateUser from "./components/Towergad/Security/UpdateUser";
import ManageGroup from "./components/Towergad/Security/ManageGroup";
import AddGroup from "./components/Towergad/Security/AddGroup";
import UpdateGroup from "./components/Towergad/Security/UpdateGroup";
import RightAssignment from "./components/Towergad/Security/RightAssignment";
import NoAccess from "./components/Towergad/NoAccess";
import TowergadTicketsDetail from "./components/Towergad/Support/TowergadTicketsDetail";
import CloseTicketList from "./components/Towergad/Support/CloseTicketList";
import PendingTicketList from "./components/Towergad/Support/PendingTicketList";


import RejectedDeposit from "./components/Towergad/Deposit/RejectedDeposit";
import PendingDeposit from "./components/Towergad/Deposit/PendingDeposit";

import SupportDashboard from "./components/Towergad/Support/SupportDashboard";
import NewTicketList from "./components/Towergad/Support/NewTicketList";
import VerifyEmail from "./components/auth/VerifyEmail";
import ResetPassword from "./components/auth/ResetPassword";
import LuckyBox from "./components/member/transactions/LuckyBox";
// import RankRewardDashboard from "./components/Towergad/RanksReward/RankRewardDashboard";
// import ManageRanks from "./components/Towergad/RanksReward/ManageRanks";

import Rewards from "./components/member/ranks/Rewards";
// import ManageRewards from "./components/Towergad/RanksReward/ManageRewards";
import MysteryBox from "./components/member/ranks/MysteryBox";
import PrivacyPolicy from "./components/Website/pages/support/PrivacyPolicy";
import TermsConditions from "./components/Website/pages/support/TermsConditions";
import Faq from "./components/Website/pages/support/Faq";
import OnlineSupport from "./components/Website/pages/support/OnlineSupport";
import MobileApp from "./components/Website/pages/quickLinks/MobileApp";
import ReferAndEarn from "./components/Website/pages/quickLinks/ReferAndEarn";
import Property from "./components/Website/pages/realEstates/Property";
import Exchanges from "./components/Website/pages/realEstates/Exchanges";
import Commodites from "./components/Website/pages/realEstates/Commodites";
import LaunchPromo from "./components/member/Promotions/LaunchPromo";
import TransRewards from "./components/member/transactions/TransRewards";
import TransTransferReceived from "./components/member/transactions/TransTransferReceived";
import DirectRank from "./components/Towergad/Ranks/DirectRank";
import Reward from "./components/Towergad/Reward/Reward";
import TradingList from "./components/Towergad/PackageStatistics/TradingList";
import SettingsDashboard from "./components/Towergad/Settings/SettingsDashboard";
import Transactions from "./components/Towergad/Transaction/Transactions";
import TransDeposit from "./components/Towergad/Transaction/TransDeposit";
import TransTrading from "./components/Towergad/Transaction/TransTrading";
import TransFirst from "./components/Towergad/Transaction/TransFirst";
import TransSecond from "./components/Towergad/Transaction/TransSecond";
import TransThird from "./components/Towergad/Transaction/TransThird";
import TransSelfRoi from "./components/Towergad/Transaction/TransSelfRoi";
import TransMyBox from "./components/Towergad/Transaction/TransMyBox";
import TransReward from "./components/Towergad/Transaction/TransReward";

import TransTransfers from "./components/Towergad/Transaction/TransTransfers"

import AdminNetwork from "./components/Towergad/AdminNetwork/AdminNetwork";
import AdminReferral from "./components/Towergad/AdminReferrals/AdminReferral";
import TransTransfersReceived from "./components/Towergad/Transaction/TransTransfersReceived";
import MysteryListing from "./components/Towergad/Reward/MysteryListing";
import AdminTransfers from "./components/Towergad/TotalTransfers/AdminTransfers";
import MutualTransfers from "./components/Towergad/TotalTransfers/MutualTransfers";
import UpdateProfilebyAdmin from "./components/Towergad/UserStatistics/UpdateProfilebyAdmin";
import KYCApproved from "./components/Towergad/UserStatistics/KYCApproved";
import DownlineFor from "./components/member/transactions/DownlineFor";
import DownlineBy from "./components/member/transactions/DownlineBy";
import PurchaseNow from "./components/Towergad/Purchase/PurchaseNow";
import InstantRewards from "./components/member/transactions/InstantReward";


const path = window.location.pathname;

if (path.startsWith("/towergad/")) {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css";
  document.head.appendChild(link);
}



function Index() {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
       // algorithm: darkAlgorithm,
        //type: 'dark',

        components: {
          Button: {
            primaryColor: "#000",
            fontWeight: "600",
          },
        },

        token: {
          // Seed Token
          colorPrimary: "#f9b707",
          //borderRadius: 8,

          // Alias Token
          //colorBgContainer: '#f6ffed',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pages" element={<Pages />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/online-support" element={<OnlineSupport />} />
          <Route path="/mobile-app" element={<MobileApp />} />
          <Route path="/refer-earn" element={< ReferAndEarn />} />
          <Route path="/property" element={<Property />} />
          <Route path="/exchanges" element={<Exchanges />} />
          <Route path="/commodites" element={<Commodites />} />
          <Route path="/launch" element={<LaunchPromo />} />


          <Route path="/" element={<Main />}>
            <Route path="/member/dashboard" element={<Dashboard />} />



            <Route path="/member/tradings" element={<Tradings />} />

            <Route path="/member/profile" element={<UpdateProfile />} />
            <Route
              path="/member/change-password"
              element={<ChangePassword />}
            />
            <Route path="/member/change-pin" element={<ChangePin />} />
            <Route path="/member/deposit/payment-modes" element={<Wallets />} />
            <Route path="/member/transfers" element={<Transfer />} />
            <Route path="/member/referrals" element={<Referrals />} />
            <Route path="/member/network" element={<Network />} />
            <Route path="/member/withdrawal" element={<Withdrawal />} />
            <Route path="/member/ranks" element={<Ranks />} />
            <Route path="/member/Rewards" element={<Rewards />} />
            <Route path="/member/MysteryBox" element={<MysteryBox />} />
            {/* kyc */}
            <Route path="/member/settings" element={<Setting />} />

            <Route path="/member/kyc" element={<KycStep1 />} />
            <Route
              path="/member/kyc/birth-details"
              element={<KycBirthDetails />}
            />
            <Route
              path="/member/kyc/document-details"
              element={<KycDocumentDetails />}
            />
            <Route
              path="/member/kyc/document-details/license"
              element={<DocLicense />}
            />
            <Route
              path="/member/kyc/document-details/passport"
              element={<DocPassport />}
            />
            <Route path="/member/kyc/document-details/ID" element={<DocID />} />
            <Route path="/member/kyc/submitted" element={<KycSubmitted />} />
            <Route path="/member/kyc/congratulations" element={<KycCong />} />
            <Route
              path="/member/kyc/user-details"
              element={<KycUserDetail />}
            />

            <Route path="/member/addmoney" element={<Deposit />} />

            {/* transactions */}

            <Route
              path="/member/transactions/tradings"
              element={<TransTradings />}
            />
            <Route
              path="/member/transactions/deposits"
              element={<TransDeposits />}
            />
            <Route
              path="/member/transactions/transfers"
              element={<TransTransfer />}
            />
            <Route
              path="/member/transactions/transfers/received"
              element={<TransTransferReceived />}
            />
            <Route
              path="/member/transactions/roi/self"
              element={<TransROISelf />}
            />
            <Route
              path="/member/transactions/roi/first"
              element={<TransROIFirst />}
            />
            <Route
              path="/member/transactions/roi/second"
              element={<TransROISecond />}
            />
            <Route
              path="/member/transactions/roi/third"
              element={<TransROIThird />}
            />
            <Route
              path="/member/transactions/lucky-box"
              element={<LuckyBox />}
            />
            <Route
              path="/member/transactions/rewards"
              element={<TransRewards />}
            />
            <Route
              path="/member/transactions/instantrewards"
              element={<InstantRewards />}
            />
            <Route
              path="/member/transactions/downline-activations/activated-for"
              element={<DownlineFor />}
            />
            <Route
              path="/member/transactions/downline-activations/activated-by"
              element={<DownlineBy />}
            />


          </Route>

          <Route path="/towergad/login" element={<TowergadLogin />} />

          <Route path="/towergad" element={<TowergadLayout />}>
            <Route exact path="/towergad/dashboard" element={<TowergadDashboard />} />
            <Route path="/towergad/dashboard1" element={<DashboardOne />} />
            <Route path="/towergad/balance-statistics/dashboard" element={<BalanceStatisticsDashboard />} />
            <Route path="/towergad/towergad-fee/dashboard" element={<TowergadFeeDashboard />} />
            <Route path="/towergad/total-packages/dashboard" element={<TotalPackagesDashboard />} />
            <Route path="/towergad/monthly-funds/dashboard" element={<MonthlyFundsDashboard />} />
            <Route path="/towergad/packages-statistics/dashboard" element={<PackagesStatisticsDashboard />} />
            <Route path="/towergad/trading-list" element={<TradingList />} />
            <Route path="/towergad/bonus-statistics/dashboard" element={<BonusStatisticsDashboard />} />
            <Route path="/towergad/support/detail/:id" element={<TowergadTicketsDetail />} />

            {/* settings */}
            <Route path="/towergad/setting/dashboard" element={<SettingsDashboard />} />


            {/* user statisics  */}

            <Route path="/towergad/userstatistics/dashboard" element={<UserStatisticsDashboard />} />
            <Route path="/towergad/userstatistics/userstatus/:userstatus" element={<MembersList />} />
            <Route path="/towergad/userstatistics/Total-user" element={<MembersList />} />
            <Route path="/towergad/userstatistics/KycRequests" element={<KycRequest />} />
            <Route path="/towergad/userstatistics/KYCApproved" element={<KYCApproved />} />
            <Route path="/towergad/userstatistics/user-details/:KYCID" element={<UserDetailsPage />} />
            <Route path="/towergad/userstatistics/update-profile/:id" element={<UpdateProfilebyAdmin />} />

            {/* start dashboard 1 side bar  */}
            {/* company profit  */}

            <Route path="/towergad/company-profit/dashboard" element={<CompanyProfitDashboard />} />
            <Route path="/towergad/company-profit/total-profit" element={<CompanyProfitList />} />

            {/* deposit panel  */}

            <Route path="/towergad/deposit/dashboard" element={<DepositDashboard />} />
            <Route path="/towergad/deposit/total-deposit" element={<TotalDeposit />} />
            <Route path="/towergad/deposit/crypto-deposit" element={<CryptoDeposit />} />
            <Route path="/towergad/deposit/coin-payment" element={<CoinPayment />} />
            <Route path="/towergad/deposit/now-payment" element={<NowPayment />} />
            <Route path="/towergad/deposit/rejected-deposit" element={<RejectedDeposit />} />
            <Route path="/towergad/deposit/pending-deposit" element={<PendingDeposit />} />


            {/* transfers */}
            <Route path="/towergad/total-transfer/dashboard" element={<TotalTransferDashboard />} />
            <Route path="/towergad/total-transfer/admin-transfers" element={<AdminTransfers />} />
            <Route path="/towergad/total-transfer/mutual-transfers" element={<MutualTransfers />} />






            {/* withdrawal panel  */}

            <Route path="/towergad/withdrawal/dashboard" element={< WithdrawalDashboard />} />
            <Route path="/towergad/withdrawal/initiated" element={<Initiated />} />
            <Route path="/towergad/withdrawal/approved" element={<Approved />} />
            <Route path="/towergad/withdrawal/rejected" element={<Rejected />} />
            <Route path="/towergad/withdrawal/success" element={<Succeeded />} />
            <Route path="/towergad/withdrawal/canceled" element={<Canceled />} />


            {/* bonus panel  */}

            <Route path="/towergad/bonus-panel/dashboard" element={<BonusPanelDashboard />} />
            <Route path="/towergad/bonus-panel/direct-bonus-list" element={<DirectBonusList />} />
            <Route path="/towergad/bonus-panel/network-bonus-list" element={<NetworkBonusList />} />
            <Route path="/towergad/bonus-panel/referral-bonus-list" element={<ReferralBonusList />} />
            <Route path="/towergad/bonus-panel/renewal-bonus-list" element={<RenewalBonusList />} />


            {/* Purchase Now  */}

            <Route path="/towergad/PurchaseNow" element={<PurchaseNow />} />



            {/* Direct Rank  */}

            <Route path="/towergad/ranks/direct-rank" element={<DirectRank />} />
            {/* Reward  */}

            <Route path="/towergad/Reward" element={<Reward />} />

            {/* commodity panel  */}

            <Route path="/towergad/commodity-panel/dashboard" element={<CommodityPannelDashboard />} />
            <Route path="/towergad/commodity-panel/direct-bonus" element={<TowergadDirectBonus />} />
            <Route path="/towergad/commodity-panel/investor-bonus" element={<TowergadInvestorBonus />} />
            <Route path="/towergad/commodity-panel/repurchase-bonus" element={<TowergadRepurchaseBonus />} />
            <Route path="/towergad/commodity-panel/rp-investor-bonus" element={<TowergadRpInvestorBonus />} />

            {/* start dashboard 3 side bar  */}
            <Route path="/towergad/roi/dashboard" element={<RoiDashboard />} />
            <Route path="/towergad/treebonus/dashboard" element={<TreeBonusDashboard />} />
            <Route path="/towergad/commodity/dashboard" element={<TowergadCommodityDashboard />} />
            <Route path="/towergad/fund/dashboard" element={<FundDashboard />} />
            <Route path="/towergad/fund/total-packages" element={<TotalPackageList />} />

            {/* ADMIN TRANSACTIONS DASHBOARD */}
            <Route path="/towergad/transactions/dashboard" element={<Transactions />} />
            <Route path="/towergad/transactions/trading/:id" element={<TransTrading />} />
            <Route path="/towergad/transactions/first-level/:id" element={<TransFirst />} />
            <Route path="/towergad/transactions/second-level/:id" element={<TransSecond />} />
            <Route path="/towergad/transactions/third-level/:id" element={<TransThird />} />
            <Route path="/towergad/transactions/self-roi/:id" element={<TransSelfRoi />} />
            <Route path="/towergad/transactions/mystery/:id" element={<TransMyBox />} />
            <Route path="/towergad/transactions/rewards/:id" element={<TransReward />} />
            <Route path="/towergad/transactions/transfer/sent/:id" element={<TransTransfers />} />
            <Route path="/towergad/transactions/transfer/received/:id" element={<TransTransfersReceived />} />
            <Route path="/towergad/transactions/deposit/:id" element={<TransDeposit />} />







            <Route path="/towergad/transaction/crypto-withdrawal-list" element={<CryptoWithdrawalList />} />
            <Route path="/towergad/mystery-box-deductions" element={<MysteryListing />} />

            {/* Network */}
            <Route path="/towergad/member-network/:id" element={<AdminNetwork />} />
            <Route path="/towergad/towergad-Referrals/:id" element={<AdminReferral />} />


            {/* ADMIN SUPPORTPANEL */}

            <Route path="/towergad/support/dashboard" element={<SupportDashboard />} />
            <Route path="/towergad/support/open-ticket" element={<OpenTicketList />} />
            <Route path="/towergad/support/new-ticket" element={<NewTicketList />} />

            {/* ADMIN SECURITY PANEL */}

            <Route path="/towergad/security/dashboard" element={<SecurityDashboard />} />
            <Route path="/towergad/security/manage-users" element={<ManageUsers />} />
            <Route path="/towergad/security/manage-users/add" element={<AddUser />} />
            <Route path="/towergad/security/manage-users/edit/:id" element={<UpdateUser />} />
            <Route path="/towergad/security/manage-groups" element={<ManageGroup />} />
            <Route path="/towergad/security/manage-groups/add" element={<AddGroup />} />
            <Route path="/towergad/security/manage-groups/edit/:id" element={<UpdateGroup />} />
            <Route path="/towergad/security/manage-groups/right-assignment/:id" element={<RightAssignment />} />




            <Route path="/towergad/no-access" element={<NoAccess />} />



            <Route path="/towergad/support/close-ticket" element={<CloseTicketList />} />
            <Route path="/towergad/support/pending-ticket" element={<PendingTicketList />} />






          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
