// DeleteWallet.js
import axios from 'axios';
import config from '../../../Config';


const DeleteWallet = (walletId) => {
  const data = {
    AccessKey: localStorage.getItem("AccessKey"),
    UserID: localStorage.getItem("ID"),
    RequestID: walletId
  };

  //console.log(data);

  var api_config = {
    method: 'post',
    url: config.base_url + 'MemberWallet/DeleteWallet',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return new Promise((resolve, reject) => {
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          resolve(response.data); // Resolve with response data for success
        } else {
          reject(response.data); // Reject with response data for failure
        }
      })
      .catch(function (error) {
        reject(error); // Reject with error object for API call failure
      });
  });
};

export default DeleteWallet;
