import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../../Config';



function MemberInfo() {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [MemberInfo, setMemberInfo] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            ID: UserID,
            //AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    setMemberInfo(response.data);
                    setLoading(false);
                } else {
                    // Handle error
                    
                }
                //   setLoading(false);
            })
            .catch(function (error) {
              
                //console.log(error);
            });

    }, []);

    if (!loading) {

        return MemberInfo;

    }
    else {
        return false;
    }



   
}

export default MemberInfo;
