import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Cropper from "react-cropper";

import { Input, message } from "antd";
const RewardModel = ({
  showReward,
  handleCloseReward,
  validatedReward,
  handleAddReward,
   loadingUpdateBtnReward,
  selectedMember,
  handleDrop,
  handleDragOver,
  onChange,
  dragging,
  imageSelected,
  image,
  setCropper
}) => {
  




  return (
    <>
      <Modal
        className="theme-modal"
        show={showReward}
        onHide={handleCloseReward}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Reward For {selectedMember.User_Name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedReward}
            onSubmit={handleAddReward}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Reward Name</label>
                <input
                  type="text"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Reward_Name"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label>Reward Date</label>
                <input
                  type="Date"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Reward_Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Reward Amount</label>
                <input
                  type="number"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Reward_Amount"
                  className="form-control"
                  required
                  min={0}
                />
              </div>
            

              <div>
                {/* {
                  <div style={{ marginBottom: "4px" }}>
                    <img
                      style={{ height: "100px" }}
                      // src={config.img_path + kyc.Passport}
                    />
                  </div>
                } */}
                <div className="card-gradient">
                  <div className="card-inner" style={{ padding: "1px" }}>
                    <div
                      className={`upload-box ${dragging ? "dragging" : ""}`}
                      id="drop-area"
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      {imageSelected ? (
                        <>
                          <Cropper
                            zoomTo={0.5}
                            // aspectRatio={8 / 8}
                            preview=".img-preview"
                            src={image}
                            dragMode="move"
                            viewMode={3}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                          <div>
                            <label htmlFor="ChoosePhoto1">
                              <span
                                htmlFor="ChoosePhoto1"
                                type="button"
                                className="button button-outline-primary"
                                style={{ marginTop: "10px" }}
                              >
                                Re Upload
                              </span>
                              <Input
                                style={{ position: "absolute", opacity: "0" }}
                                id="ChoosePhoto1"
                                accept="image/jpeg,image/png"
                                type="file"
                                onChange={onChange}
                              />
                            </label>
                          </div>
                        </>
                      ) : (
                        <>
                          <label htmlFor="ChoosePhoto">
                            <h4>Drag and drop your document</h4>
                            <p>Max size 2 MB. Files allowed: JPG, PNG</p>
                            <span
                              htmlFor="ChoosePhoto"
                              type="button"
                              className="button button-outline-primary"
                            >
                              Browse File
                            </span>
                            {/* <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} /> */}
                            <Input
                              style={{ position: "absolute", opacity: "0" }}
                              id="ChoosePhoto"
                              accept="image/jpeg,image/png"
                              type="file"
                              onChange={onChange}
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              { loadingUpdateBtnReward ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100 mt-3">
                  Add Reward
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RewardModel;
