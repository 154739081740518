import React, { useState } from "react";
import { Space, Dropdown, Button, Modal, Form, Input, Checkbox, Row, Col, Select, message } from 'antd';
import { DownOutlined, BankOutlined } from '@ant-design/icons';
import config from "../../../Config";
import axios from "axios";
import AddWalletBank from "./AddWalletBank";



function DDAddNewWallet({onWalletAdd}) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    
    

    const [IsDefault, setIsDefault] = useState(false);


    // loadings
    const [loading, setLoading] = useState(false);

     // messages
     const [messageApi, contextHolder] = message.useMessage();

    // modal to add wallet
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalBankOpen, setIsModalBankOpen] = useState(false);

    const showModalBank = () => {
        setIsModalBankOpen(true);
    };
    const handleBankCancel = () => {
        onWalletAdd();
        setIsModalBankOpen(false);
    };


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        
        setIsModalOpen(false);
    };



    const items = [
        {
            key: '1',
            label: (
                <a href="#" onClick={showModal}>
                    Crypto
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href='#' onClick={showModalBank}>
                    Bank Account
                </a>
            ),
            //icon: <BankOutlined />,
        },

    ];


    const onFinish = (formData) => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            BankName: "",
            WalletType: "Crypto",
            AccountTitle: "",
            Currency: "",
            IsDefault: IsDefault,
            ...formData
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/AddWallet',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
        .then(function (response) {
            //console.log(response.data);
            if (response.data.status_code == 1) {

                messageApi.open({
                    type: 'success',
                    content: response.data.status_message,
                  });

                handleCancel();
                setLoading(false);
                onWalletAdd();
                
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: response.data.status_message,
                  });
                setLoading(false);
            }
           

        })
        .catch(function (error) {

          
        });
    }

    const onChangeDefault = (e) => {
        setIsDefault(e.target.checked);
    };



    return (
        <>
            {contextHolder}
            <Dropdown
                menu={{
                    items,
                }}
            >
                <a className="button button-round  button-outline-primary button-long button-small" onClick={(e) => e.preventDefault()}>
                    <Space>
                        Add Wallet
                        <DownOutlined />
                    </Space>


                </a>
            </Dropdown>
            
            <AddWalletBank status={isModalBankOpen} close={handleBankCancel} />

            <Modal
                title="Add Crypto Wallet"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={600}
            >
                <p>Please make sure your wallet address aligns with the selected payment mode.</p>

                <Form
                    size="large"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[20, 0]}>
                        <Col xs={24} md={10}>
                            <Form.Item label="Nickname" name="Nick">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={14}>
                            <Form.Item
                                label="Payment Mode"
                                name="Network"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a payment mode',
                                    },
                                ]}
                            >
                                <Select
                                    //defaultValue="lucy"
                                    style={{
                                        width: '100%',
                                    }}
                                    //onChange={handleChange}
                                    options={[
                                        {
                                            value: 'BTC',
                                            label: 'Bitcoin - BTC',
                                        },
                                        {
                                            value: 'USDT',
                                            label: 'TRC20 - USDT',
                                        },
                                        {
                                            value: 'ETH',
                                            label: 'Ethereum (ERC-20) - ETH',
                                        },
                                        {
                                            value: 'TRX',
                                            label: 'TRON - TRX',
                                        },

                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>



                    <Form.Item
                        label="Wallet address"
                        name="Address"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter wallet address.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Checkbox onChange={onChangeDefault}>Set as Default</Checkbox>
                    <Form.Item
                        label="PIN Code"
                        name="PinCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter PIN Code',
                            },
                        ]}
                        style={{ marginTop: "20px" }}>
                        <Input />
                    </Form.Item>
                    <div style={{ textAlign: "right" }}>
                        <Button htmlType="submit" type="primary" loading={loading}>Save</Button>
                    </div>



                </Form>

            </Modal>

        </>
    );
}

export default DDAddNewWallet;