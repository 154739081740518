import React, { useEffect, useState } from 'react';
import { Col, Row } from "antd";
import iconBalance from "../../../assets/images/dashboard/icons/balance.png";
import iconRank from "../../../assets/images/dashboard/icons/rank.png";
import iconTradingIncome from "../../../assets/images/dashboard/icons/trading-income.png";
import iconTradeVolume from "../../../assets/images/dashboard/icons/trade-volume.png";
import iconTradeVolume1 from "../../../assets/images/dashboard/icons/target.png";

import { NumberFormat } from "../General/Functions";
function Overview(props) {


    const FullName = localStorage.getItem("Full_Name");


    const DashboardData = props.data;





    return (
        <>
            <h3 className="card-title">Overview</h3>
            <div className="card-overview card-bottom-shadow">
                <div className="card-body card-backdrop">
                    <div className="position-relative">
                        <ul className='user-info'>
                            <li>
                                <h1><span className="text-dim-6">Hi,</span> {DashboardData.Full_Name}</h1>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={iconRank} />
                                </div>
                                <div className="info">
                                    <p>Rank</p>
                                    <h4>{DashboardData.RankName}</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={iconBalance} />
                                </div>
                                <div className="info">
                                    <p>Available Balance</p>
                                    <h4>$ {NumberFormat(DashboardData.Current_Balance)}</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={iconTradeVolume} />
                                </div>
                                <div className="info">
                                    <p>Trade Volume</p>
                                    <h4>$ {NumberFormat(DashboardData.Investment_Balance)}</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={iconTradingIncome} />
                                </div>
                                <div className="info">
                                    <p>Trading Income</p>
                                    <h4>$ {NumberFormat(DashboardData.Profit_Received)}</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={iconTradeVolume1} />
                                </div>
                                <div className="info">
                                    <p>2x Target</p>
                                    <h4>$ {NumberFormat(DashboardData.Target6x)}</h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Overview;