import React from "react";

import Win from "../../../assets/images/dashboard/win.jpg";
import Party from "../../../assets/images/dashboard/party.svg";

function FutureBox(props) {
    return (
        <>
            
            <div className="future-box">
                <img src={Win} />
                <div>
                    <div className="total-value">
                        <p>Total Deduction</p>
                        <h3>${props.Deduction_Box_Value}</h3>
                    </div>
                    <div className="future-value">
                        <p>Future Box Value</p>
                        <h3>${props.Future_Box_Value}</h3>
                        <img src={Party} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FutureBox;