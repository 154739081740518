import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import Reward from "../../../../assets/images/landing/earn-reward.jpg";
import Benefits from "../../../../assets/images/landing/Benefits.jpg";
import { Row, Col } from 'antd';

const ReferAndEarn = () => {
  useEffect(() => {
    document.title = "Refer And Earn";
  }, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Towergearplus Referral Program' />
        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'}>
              <Col md={{ span: "12" }} xs={24}>
                <div className="section-title">
                  <h3>
                    Refer Friends, Earn Rewards
                  </h3>
                  <div className='subtitle'><span>Introducing Towergearplus's Exclusive Referral Program</span></div>
                </div>
                <div className="section-detail">
                  Join Towergearplus's referral program and turn your network into a source of income. It's simple: refer friends, family, or colleagues to Towergearplus, and when they sign up and start trading, both you and your referral earn exciting rewards. Spread the word and let your connections benefit from Towergearplus's innovative trading solutions while you enjoy fantastic perks.
                </div>
              </Col>
              <Col md={{ span: "12" }} xs={24}>
                <img src={Reward} alt="" style={{ borderRadius: "10px" }} />
              </Col>
            </Row>
          </div>
        </div>
        <div className="simple-section pt-0">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'} >
              <Col md={{ span: "24" }} xs={24} align={'middle'}>
                <div className="section-title">
                  <h3>
                    How It Works
                  </h3>
                </div>
              </Col>
              <Col md={{ span: "24" }} xs={24}>
                <Row
                  gutter={[50, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                  align={'middle'}
                >
                  <Col md={{ span: "8" }} xs={24}>
                    <div className="center-info">
                      <span>1</span>
                      <h3>
                        Share Your Unique Referral Link
                      </h3>
                      <div className="section-detail">
                        Upon signing up with Towergearplus, you'll receive a unique referral link. Share this link with your friends and associates through social media, email, or messaging apps. The link identifies you as the referrer, ensuring you receive credit for every successful referral.
                      </div>
                    </div>
                  </Col>
                  <Col md={{ span: "8" }} xs={24}>
                    <div className="center-info">
                      <span>2</span>
                      <h3>
                        Your Friends Join Towergearplus
                      </h3>
                      <div className="section-detail">
                        When your referrals use your link to sign up on Towergearplus and start trading, they not only gain access to a world-class trading platform but also become eligible for special bonuses and benefits available to new users.
                      </div>
                    </div>
                  </Col>
                  <Col md={{ span: "8" }} xs={24}>
                    <div className="center-info">
                      <span>3</span>
                      <h3>
                        Both You and Your Referral Get Rewarded
                      </h3>
                      <div className="section-detail">
                        For every successful referral, both you and your friend are rewarded. You might earn commission on their trades, receive discounts on transaction fees, or even get exclusive access to premium features. The more friends you refer, the more rewards you accumulate.
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="simple-section">
          <div className="tg-container">
            <Row
              gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
              align={'middle'}>
              <Col md={{ span: "24" }} xs={24} align={'middle'}>
                <div className="section-title mb-md-5">
                  <h3>
                    Benefits of Referring with Towergearplus
                  </h3>
                </div>
              </Col>
              <Col md={{ span: "18" }}>
                <ul className='count-list'>
                  <li>
                    <span>1</span>
                    <div className="content">
                      <h5>Earn Passive Income</h5>
                      <div className="section-detail">
                        Continuous Earnings: Enjoy a continuous stream of income as long as your referrals actively trade on Towergearplus.
                      </div>
                    </div>
                  </li>
                  <li>
                    <span>2</span>
                    <div className="content">
                      <h5> Exclusive Bonuses for Referrers</h5>
                      <div className="section-detail">
                        Special Bonuses: Receive exclusive bonuses or discounts tailored for our referral program participants, enhancing your trading experience.
                      </div>
                    </div>
                  </li>
                  <li>
                    <span>3</span>
                    <div className="content">
                      <h5>Strengthen Your Network</h5>
                      <div className="section-detail">
                        Expand Your Network: Connect with like-minded individuals interested in trading, fostering a community of knowledgeable investors.
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md={{ span: "6" }}>
                <img src={Benefits} alt="" style={{ borderRadius: "10px" }} />
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default ReferAndEarn