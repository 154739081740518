import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";

import bonus from "../../../assets/images/admin/icons/bonus.png";
import received_bonus from "../../../assets/images/admin/icons/received_bonus.png";
import deduction from "../../../assets/images/admin/icons/deduction.png";

import fundation from "../../../assets/images/admin/icons/fundation.png";

import peferral from "../../../assets/images/admin/icons/peferral.png";

import TowergadHeader from "../TowergadHeader";

   
   
import TowergadRightsCheck from "../TowergadRightsCheck";

function BonusPanelDashboard() {
  return (
    <>
         
      <TowergadRightsCheck />
      <PageTitle title="BONUS PANEL" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="50$"
              label="TOTAL RECEIVED BONUS"
              icon={received_bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="blue"
              value="12"
              label="TOTAL PAID BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="40%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="350$"
              label="TOTAL PENDING BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="70%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="brown"
              value="10$"
              label="TOTAL DEDUCTION 20~20"
              icon={deduction}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="30%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="brown"
              value="40$"
              label="TOTAL FOUNDATION"
              icon={fundation}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="370$"
              label="TOTAL PAID BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="/admin/bonus-panel/direct-bonus-list"
              class="brown"
              value="70$"
              label="DIRECT BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="/admin/bonus-panel/network-bonus-list"
              class="blue"
              value="30$"
              label="NETWORK BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="/admin/bonus-panel/referral-bonus-list"
              class="red"
              value="40$"
              label="REFERRAL BONUS"
              icon={peferral}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="/admin/bonus-panel/renewal-bonus-list"
              class="green"
              value="50$"
              label="RENEWAL BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="100%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BonusPanelDashboard;
