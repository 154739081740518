import React, { useEffect } from "react";
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Privacy Policy' />

        {/* <div className="hero page-title mb-md-5">
          <div className="hero-content">
            <div className="container position-relative">
              <div className="title">
                <h1 className="text-center">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div> */}
        <div className="simple-section">
          <div className="auth-container terms-wrap" >
            <div className="section-title">
              <h3>
                Privacy Policy
              </h3>
            </div>
            <p className="section-detail" style={{color:"white"}}>
              For us, privacy is crucial. To further protect your privacy, we
              provide this notice outlining our online information policies and
              the decisions you can make regarding the collection and use of
              your information. By using the Website in a way that results in
              your giving us personal information, you agree to these terms.
              State Law and Policy; Associated Rights: Please be aware that,
              depending on where you live, you may have additional rights
              derived from State legislation. These state-based privacy
              safeguards may enhance, reinforce, or in some other manner
              complement any inherent privacy rights you may have or those
              provided you by federal law. Our company's policy is to strictly
              follow the privacy regulations of any nation where we do business.
              As a result, you are able to contact us at any time using our
              Contact information to claim any State rights.
            </p>
            <div className="section-title">
              <h3>
              1. The privacy of children
              </h3>
            </div>
            <p className="section-detail" style={{color:"white"}}>
              For us, privacy is crucial. To 
              Young children's privacy needs to be protected in particular.
              Because of this, no aspect of our website is designed to appeal to
              anybody under the age of 18, and we never collect or maintain
              information from anyone who we really know to be under 18. The
              Website and Tower Gear Plus products and services are not accessible by
              anybody under the age of 18.
            </p>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
