import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";

import CRYPTO from "../../../assets/images/admin/icons/CRYPTO.png";
import pendings from "../../../assets/images/admin/icons/pendings.png";

// import AdminHeader from "../AdminHeader";

import TowergadRightsCheck from "../TowergadRightsCheck";
import Spinner from "react-bootstrap/Spinner";

function TotalTransferDashboard() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <TowergadRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <PageTitle title="TOTAL TRANSFERS" />
          <div className="admin-content">
            <div className="row">
              <div className="col-md-3">
                <TowergadCard
                  link="/towergad/total-transfer/admin-transfers"
                  class="blue"
                  // value={NumberFormat(depositStatics.totalDeposit)}
                  label="Admin Transfers"
                  icon={pendings}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="10%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>
              <div className="col-md-3">
                <TowergadCard
                  link="/towergad/total-transfer/mutual-transfers"
                  class="green"
                  // value={NumberFormat(depositStatics.cryptoDeposit)}
                  label="Mutual Transfers"
                  icon={CRYPTO}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="16%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TotalTransferDashboard;
