import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";

import bonus from "../../../assets/images/admin/icons/bonus.png";

import TowergadHeader from "../TowergadHeader";

   
   
import TowergadRightsCheck from "../TowergadRightsCheck";

function BonusStatisticsDashboard() {
  return (
    <>
    <TowergadRightsCheck />
         
      <PageTitle title="TOTAL TRANSFERS" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="500$"
              label="TOTAL RECEIVED
BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="120$"
              label="TOTAL PAID
BONUS
"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="70%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="red"
              value="350$"
              label="TOTAL PENDING
BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="blue"
              value="700$"
              label="ACTIVE BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="brown"
              value="40$"
              label="PASSIVE BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="50%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value="370$"
              label="REWARD BONUS
"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BonusStatisticsDashboard;
