import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
  Select,
} from "antd";
import axios from "axios";
import config from "../../../../Config";
import { useNavigate } from "react-router-dom";
import Cropper from "react-cropper";

const DocLicense = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Text, Link, Title } = Typography;
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const MAX_IMAGE_SIZE = 4 * 1024 * 1024; // 4MB in bytes

  // front side
  const [ImgFront, setImgFront] = useState("");
  const [frontSideSelected, setfrontSideSelected] = useState(false);
  const [frontCropper, setFrontCropper] = useState();

  // back side
  const [ImgBack, setImgBack] = useState("");
  const [backSideSelected, setbackSideSelected] = useState(false);
  const [backCropper, setBackCropper] = useState();
  useEffect(()=>{
    
    document.title= "KYC License Details"
  },[])

  const onChangeFront = (e) => {
    e.preventDefault();
    setfrontSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setfrontSideSelected(false);
      messageApi.open({
        type: "error",
        content:
          "Image size must be less than 4 MB",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgFront(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onChangeBack = (e) => {
    e.preventDefault();
    setbackSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setbackSideSelected(false);
      messageApi.open({
        type: "error",
        content: "Image size must be less than 4 MB ",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgBack(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    setLoading(true);
    if (typeof frontCropper !== "undefined") {
      var cropDataFront = frontCropper.getCroppedCanvas().toDataURL();
      var cleanerBase64Front = cropDataFront.substring(22);
    }

    if (typeof backCropper !== "undefined") {
      var cropDataBack = backCropper.getCroppedCanvas().toDataURL();
      var cleanerBase64Back = cropDataBack.substring(22);
    }

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      LicenseFront: cleanerBase64Front,
      LicenseBack: cleanerBase64Back,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "MemberKYC/UpdateLicenseKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
         
          messageApi.open({
            type: "success",
            content:
            "KYC submitted successfully",
          });

          navigate("/member/kyc/submitted");
        } else {
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }

        //  //console.log(response.data);

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Invalid Format",
        });
      });
  };

  const [dragging, setDragging] = useState(false);
  const [dragFront, setdragFront] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };
  const handleDragfirst = (event) => {
    event.preventDefault();
    setdragFront(true);
  };

  const handleDropfirst = (event) => {
    event.preventDefault();
    setfrontSideSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      if (file.size > MAX_IMAGE_SIZE) {
        setfrontSideSelected(false);
        messageApi.open({
          type: "error",
          content: "Image size must be less than 4 MB ",
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImgFront(e.target.result);
        if (backCropper) {
          backCropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setbackSideSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      if (file.size > MAX_IMAGE_SIZE) {
        setbackSideSelected(false);
        messageApi.open({
          type: "error",
          content: "Image size must be less than 4 MB ",
        });
        return;
      }

      // //console.log(file.size);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgBack(e.target.result);
        if (backCropper) {
          backCropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {contextHolder}

      <Row gutter={[24, 24]}>
        <Col md={20} offset={2} xs={24}>
          <Card
            title="Take photos of your Driving License or upload them"
            bordered={true}
          >
            <Title style={{ margin: "0px" }} level={5}>
              Upload both sides of your Driving License. A third-party certified
              provider will check your identity so you’ll be able to participate
              in the Towergear affiliate program.
            </Title>

            <Row gutter={[24, 24]}>
              <Col md={10} offset={1} xs={24}>
                <Title level={3}>Front Side </Title>
                <div className="card-gradient">
                  <div className="card-inner" style={{ padding: "1px" }}>
                    <div
                      className={`upload-box ${dragFront ? "dragFront" : ""}`}
                      id="drop-area"
                      onDragOver={handleDragfirst}
                      onDrop={handleDropfirst}
                    >
                      {frontSideSelected ? (
                        <>
                          <Cropper
                            zoomTo={0}
                            preview=".img-preview"
                            src={ImgFront}
                            dragMode="move"
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setFrontCropper(instance);
                            }}
                            guides={true}
                          />
                          <div style={{marginTop:"15px"}}>
                            <label htmlFor="ChoosePhoto1">
                              <span
                                htmlFor="ChoosePhoto1"
                                type="button"
                                className="button button-outline-primary"
                                
                              >
                                Re Upload
                              </span>
                              <Input
                                style={{ position: "absolute", opacity: "0" }}
                                id="ChoosePhoto1"
                                accept="image/jpeg,image/png"
                                type="file"
                                onChange={onChangeFront}
                              />
                            </label>
                          </div>
                        </>
                      ) : (
                        <label htmlFor="ChoosePhotoFront">
                          <h4>Drag and drop your document</h4>
                          <p>Max size 4 MB. Files allowed: JPG, PNG</p>
                          <span
                            htmlFor="ChoosePhotoFront"
                            type="button"
                            className="button button-outline-primary"
                          >
                            Browse Files
                          </span>
                          <Input
                            style={{ position: "absolute", opacity: "0" }}
                            id="ChoosePhotoFront"
                            accept="image/jpeg,image/png"
                            type="file"
                            onChange={onChangeFront}
                          />
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={10} offset={2} xs={24}>
                <Title level={3}>Back Side </Title>
                <div className="card-gradient">
                  <div className="card-inner" style={{ padding: "1px" }}>
                    <div
                      className={`upload-box ${dragging ? "dragging" : ""}`}
                      id="drop-area"
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      {backSideSelected ? (
                        <>
                          <Cropper
                            zoomTo={0}
                            preview=".img-preview"
                            src={ImgBack}
                            dragMode="move"
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setBackCropper(instance);
                            }}
                            guides={true}
                          />
                          <div style={{marginTop:"15px"}}>
                            <label htmlFor="ChoosePhoto2">
                              <span
                                htmlFor="ChoosePhoto2"
                                type="button"
                                className="button button-outline-primary"
                              >
                                Re Upload
                              </span>
                              <Input
                                style={{ position: "absolute", opacity: "0" }}
                                id="ChoosePhoto2"
                                accept="image/jpeg,image/png"
                                type="file"
                                onChange={onChangeBack}
                              />
                            </label>
                          </div>
                        </>
                      ) : (
                        <label htmlFor="ChoosePhotoBack">
                          <h4>Drag and drop your document</h4>
                          <p>Max size 4 MB. Files allowed: JPG, PNG</p>
                          <span
                            htmlFor="ChoosePhotoBack"
                            type="button"
                            className="button button-outline-primary"
                          >
                            Browse Files
                          </span>
                          <Input
                            style={{ position: "absolute", opacity: "0" }}
                            id="ChoosePhotoBack"
                            accept="image/jpeg,image/png"
                            type="file"
                            onChange={onChangeBack}
                          />
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div style={{ textAlign: "end", marginTop: "15px" }}>
              <Button
                onClick={getCropData}
                type="primary"
                loading={loading}
                disabled={!frontSideSelected || !backSideSelected}
              >
                Continue
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DocLicense;
