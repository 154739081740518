import React from 'react';

import iconNetwork from "../../../assets/images/dashboard/icons/network.svg";
import imgellipseRed from "../../../assets/images/dashboard/ellipse-red.svg";
import { NavLink } from 'react-router-dom';


function NetworkCard(props) {
    return (
        <>
            <h3 className="card-title" style={{ visibility: "hidden" }}>Network</h3>
            <div className="card-network">

                <div className="card-body">
                    <NavLink to="/member/network">&nbsp;</NavLink>
                    <h4>My Network</h4>

                    <h2>{props.count}</h2>
                    <p>Total Members</p>

                </div>
            </div>
        </>
    );
}

export default NetworkCard;