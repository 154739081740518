import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Drawer, Row, Space, Tooltip, message } from "antd";
import axios from "axios";
import config from "../../../Config";
import dayjs from "dayjs";

function Notifications(props) {
  const [messageApi, contextHolder] = message.useMessage();

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  // notifications
  const [ListofNotifcations, setListofNotifcations] = useState([]);
  const [unseenCount, setunseenCount] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [isChanged, setIsChanged] = useState(0);
  const [selectedBtnId, setSelectedBtnId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  // ALL
  const [loadingReadAll, setloadingReadAll] = useState(false);
  const [loadingDeleteAll, setloadingDeleteAll] = useState(false);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // setIsDeleted(false);
    setIsUpdated(false);
    const Params = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Notification/GetAllNotifications",
      headers: {
        "Content-Type": "application/json",
      },
      data: Params,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setListofNotifcations(response.data.ListofNotifcations);
          let list = response.data.ListofNotifcations;

          const unseenCount = list.filter(
            (notification) => !notification.Is_Seen
          ).length;
          setunseenCount(unseenCount);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [isUpdated]);

  const MarkRead = (NotificationID) => {
    let status = isChanged;
    let newStatus = parseInt(status) + parseInt(1);
    setIsChanged(newStatus);
    setLoading(true);
    setSelectedBtnId(NotificationID);
    const Params = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: NotificationID,
    };

    var api_config = {
      method: "post",
      //url: config.base_url + 'Notification/DeleteNotifications',
      url: config.base_url + "Notification/ReadNotification",
      headers: {
        "Content-Type": "application/json",
      },
      data: Params,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsDeleted(true);
          setLoading(false);
          setIsUpdated(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
          //setListofNotifcations(newarray);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const MarkDelete = (NotificationID) => {
    let status = isChanged;
    let newStatus = parseInt(status) + parseInt(1);
    setIsChanged(newStatus);
    setLoading(true);
    setSelectedBtnId(NotificationID);
    const Params = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: NotificationID,
    };

    var api_config = {
      method: "post",
      //url: config.base_url + 'Notification/DeleteNotifications',
      url: config.base_url + "Notification/DeleteNotification",
      headers: {
        "Content-Type": "application/json",
      },
      data: Params,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsDeleted(true);
          setLoading(false);
          setIsUpdated(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
          //setListofNotifcations(newarray);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleReadAll = () => {
    setloadingReadAll(true);
    const Params = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    ////console.log(Params);

    var api_config = {
      method: "post",
      url: config.base_url + "Notification/ReadAllNotifications",
      headers: {
        "Content-Type": "application/json",
      },
      data: Params,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          // //console.log(response.data.status_message);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          setloadingReadAll(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleDeleteAll = () => {
    setloadingDeleteAll(true);
    const Params = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    ////console.log(Params);

    var api_config = {
      method: "post",
      url: config.base_url + "Notification/DeleteAllNotifications",
      headers: {
        "Content-Type": "application/json",
      },
      data: Params,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          //console.log(response.data.status_message);

          setloadingDeleteAll(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const renderNotifications = () => {
    if (ListofNotifcations.length > 0) {
      return ListofNotifcations.map((item, index) => {
        return (
          <Col xs={24} md={8} key={index}>
            <div className="notification_item">
              <div className="content">
                <h4>{item.Title}</h4>
                <p>{item.Detail}</p>
                <div className="date">
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                  {dayjs(item.Created_Date).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="actions">
                <Tooltip title="Mark as read">
                  <div>
                    {!item.Is_Seen ? (
                      <span
                        onClick={() => MarkRead(item.ID)}
                        className="material-symbols-outlined"
                      >
                        mark_email_unread
                      </span>
                    ) : (
                      <span
                        style={{ cursor: "not-allowed" }}
                        className="material-symbols-outlined"
                      >
                        drafts
                      </span>
                    )}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div>
                    <span
                      onClick={(e) => MarkDelete(item.ID)}
                      className="material-symbols-outlined"
                    >
                      delete
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Col>
        );
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className="notifications">
        {unseenCount > 0 && <span></span>}
        <svg
          onClick={showDrawer}
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 15H20V17H0V15H2V8C2 3.58172 5.58172 0 10 0C14.4183 0 18 3.58172 18 8V15ZM7 19H13V21H7V19Z"
            fill="#FFF"
          />
        </svg>
      </div>
      <Drawer
        title="Notifications"
        placement="top"
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={() => (onClose(), handleDeleteAll())}>
              Clear All
            </Button>
            <Button type="primary" onClick={() => (onClose(), handleReadAll())}>
              Read All
            </Button>
          </Space>
        }
      >
        <div className="notifications_bg">
          <Row gutter={[16, 16]}>{renderNotifications()}</Row>
        </div>
      </Drawer>
    </>
  );
}

export default Notifications;
