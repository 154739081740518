import { Col, Row } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo/towergear_white.png';
import { scrollToTop } from '../../Common/scrollToTop';
import { Button, Input, Select, Space } from 'antd';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-glass">
          <div className='footer-container'>
            <div className='footer-row'>
              <div className='footer-widget'>
                <div className="brand">
                  <img src={logo} className="navbar-brand" alt="brand" />
                  <div className="section-detail">
                    Maximize your wealth through strategic investments in exchanges
                  </div>
                  <div className="socila-icon">
                    <div className="icon">
                      <a href="">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.2917 7.125H13.8542L13.4584 8.70833H10.2917V15.8333H8.70835V8.70833H5.54169V7.125H8.70835V5.64281C8.70835 4.23124 8.85537 3.71937 9.13134 3.20332C9.40732 2.68728 9.81233 2.28227 10.3283 2.00629C10.8444 1.7303 11.3562 1.58333 12.7679 1.58333C13.1811 1.58333 13.5432 1.62291 13.8542 1.70208V3.16666H12.7679C11.7199 3.16666 11.4008 3.22829 11.075 3.40249C10.8349 3.53092 10.6559 3.7099 10.5275 3.95002C10.3533 4.27576 10.2917 4.59488 10.2917 5.64281V7.125Z" fill="black" />
                        </svg>
                      </a>
                      <a href="">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 1.5H0.75L6.94565 9.76087L1.08746 16.4999H3.07498L7.86623 10.9883L12 16.5H17.25L10.7938 7.89172L16.3501 1.5H14.3626L9.87322 6.66434L6 1.5ZM12.75 15L3.75 3H5.25L14.25 15H12.75Z" fill="black" />
                        </svg>
                      </a>
                      <a href="">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5009 8.35629C11.3033 7.53661 12.3481 7 13.5634 7C16.2212 7 18.3759 9.1546 18.3759 11.8125V18.375H16.6259V11.8125C16.6259 10.1211 15.2547 8.75 13.5634 8.75C11.872 8.75 10.5009 10.1211 10.5009 11.8125V18.375H8.75087V7.4375H10.5009V8.35629ZM4.37585 5.6875C3.65098 5.6875 3.06335 5.09988 3.06335 4.375C3.06335 3.65012 3.65098 3.0625 4.37585 3.0625C5.10072 3.0625 5.68835 3.65012 5.68835 4.375C5.68835 5.09988 5.10072 5.6875 4.37585 5.6875ZM3.50085 7.4375H5.25085V18.375H3.50085V7.4375Z" fill="black" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='footer-widget'>
                <div className="footer-menu">
                  <h5>Support</h5>
                  <ul className="">
                    <li>
                      <NavLink onClick={scrollToTop} to="/faq">

                        FAQ
                      </NavLink>
                    </li>

                    <li>
                      <NavLink onClick={scrollToTop} to="/online-support">

                        Online Support
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={scrollToTop} to="/privacy-policy">

                        Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink onClick={scrollToTop} to="/terms-and-conditions">
                        Terms & Condition</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='footer-widget'>
                <div className="footer-menu">
                  <h5>Quick Links</h5>
                  <ul className="">
                    <li><NavLink onClick={scrollToTop} to="/register">Create account</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/mobile-app">Mobile App</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/refer-earn">Refer and earn</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className='footer-widget'>
                <div className="footer-menu">
                  <h5>Real Estates</h5>
                  <ul className="">
                    <li><NavLink onClick={scrollToTop} to="/property">Property</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/exchanges">Exchange</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/commodites">Commodities</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className='footer-widget'>
                <div className="footer-menu">
                  <h5>CONNECT</h5>
                  <Space.Compact
                    style={{
                      width: '100%',
                    }}
                  >
                    <Input placeholder="Enter Email" />
                    <Button type="primary">
                      <span class="material-symbols-outlined">
                        login
                      </span>
                    </Button>
                  </Space.Compact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        &copy; 2023 - All Rights Reserved by Tower Gear Plus.
      </div>
    </>
  )
}

export default Footer