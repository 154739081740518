import React, { useEffect, useState } from 'react'
import RanksMenu from './RanksMenu';
import { Col, Empty, Row, Spin } from 'antd';
import RewardCard from './RewardCard';
import car from "../../../assets/images/dashboard/ranks/car.jpg";
import avatar1 from "../../../assets/images/avatar/1.png";
import avatar2 from "../../../assets/images/avatar/2.png";
import avatar3 from "../../../assets/images/avatar/3.png";
import phone from "../../../assets/images/dashboard/ranks/phone.jpg";
import money from "../../../assets/images/dashboard/ranks/money.jpg";
import config from '../../../Config';
import axios from 'axios';
import Spinner from "react-bootstrap/Spinner";
import dayjs from 'dayjs';

const Rewards = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  const [reward, setReward] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Tower Rewards";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: 1,
      pageSize: 10,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reward/GetAllRewards",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setReward(response.data.ListofLedger);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
         
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          // navigate("/towergad/login");
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);


  const renderLedger = () => {
    if (reward != null && reward.length > 0) {
      return reward.map((item, index) => {
        //console.log(item);
        return (
          <>
            <Col xs={24} md={8} key={index}>
              <RewardCard
                rewardphoto={config.assets + "Personal/RewardImages/" + item.RewardPhoto}
                country={item.Country}
                user={config.img_path + item.Img_File}
                name={item.Full_Name}
                email={item.Email_Address}
                reward={item.RewardName}
                data={dayjs(item.RewardDate).format("D MMMM YYYY")}
              />
            </Col>
          </>
        );
      });
    } else {
      return (
        <Col xs={24} md={{ span: 8, offset: 8 }} textAlign={"middle"}>
         <Empty />
        </Col>

      );
    }
  };



  return (
    <div>
      <RanksMenu active="Rewards" />
      <div className='rewards-bg'>
        <div className='overlay'>
          <h3 style={{ textAlign: "center", margin: "0 0 25px 0" }}>Tower Rewards</h3>
          <Row gutter={[24, 24]}>

            {loading ? (
              <Spin />
            ) : (
              <>{renderLedger()}</>
            )}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Rewards