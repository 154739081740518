import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Select, Space, Modal, message, Spin } from 'antd';
import config from '../../../Config';
import axios from 'axios';
import iconBalance from "../../../assets/images/dashboard/icons/balance.svg";
import { NumberFormat } from '../General/Functions';
import WalletsDropdown from './WalletsDropdown';
import MemberInfo from '../shared/MemberInfo';


import SendEmail from "../shared/SendEmail";

function NewWithdrawal(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [wallet, setWallet] = useState({});

    const [rates, setRates] = useState([]);
    const [CryptoRates, setCryptoRates] = useState([]);
    const [WalletID, setWalletID] = useState(0);
    const [WithdrawFee, setWithdrawFee] = useState(null);

    const [amount, setAmount] = useState("");
    const [remainingBalance, setRemainingBalance] = useState(0);

    const [showError, setShowError] = useState(false);
    const [Amounterror, setAmounterror] = useState(false);

    const [finalAmount, setFinalAmount] = useState(0);
    const [pamount, setpAmount] = useState(0);

    const [PaymentMode, setPaymentMode] = useState("");

    const [loadingCurrencies, setloadingCurrencies] = useState(false);

    const [BTC, setBTC] = useState(0);
    const [ETH, setETH] = useState(0);
    const [USDT, setUSDT] = useState(0);
    const [TRX, setTRX] = useState(0);


    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingWallets, setLoadingWallets] = useState(false);
    // messages
    const [messageApi, contextHolder] = message.useMessage();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(60); // 60 seconds

    const [ConvertedAmount, setConvertedAmount] = useState(0);
    const wallets = WalletsDropdown();
    const member = MemberInfo();

    const onFinish = (formData) => {

        setLoading(true);


        let CurrencyRate = 0;

        if (PaymentMode == "BTC") {
            CurrencyRate = BTC;
        }
        else if (PaymentMode == "ETH") {
            CurrencyRate = ETH;
        }
        else if (PaymentMode == "TRX") {
            CurrencyRate = TRX;
        }
        else if (PaymentMode == "USDT") {
            CurrencyRate = USDT;
        }
        else if (PaymentMode == "Bank Account") {
            if (wallet.AccountCurrency == "PKR") {
                CurrencyRate = rates.PKR;
            }
            else if (wallet.AccountCurrency == "EUR") {
                CurrencyRate = rates.EUR;
            }
            if (wallet.AccountCurrency == "AED") {
                CurrencyRate = rates.AED;
            }
        }

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Amount: amount,
            WalletID: WalletID,
            PaymentMode: PaymentMode,
            SelectedCurrencyRate: CurrencyRate,
            SelectedCurrency: wallet.AccountCurrency,
            FeePercentage: WithdrawFee,
            ...formData
        };

       console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/AddWithdrawlRequest',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                if (response.data.status_code == 1) {

                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                    props.close();

                    //onWalletAdd();

                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                }
                setIsButtonDisabled(true);

                // Enable the button after 1 minute
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 60000);
                setTimer(60);

            })
            .catch(function (error) {


            });
    }





    //7da24ec81b918bbe12effe29fb3a1b25
    useEffect(() => {
        var api_config = {
            method: "get",
            url: "https://api.metalpriceapi.com/v1/latest?api_key=7da24ec81b918bbe12effe29fb3a1b25&base=USD&currencies=INR,PKR,AED,EUR,BTC",
            headers: {
                "Content-Type": "application/json",
                // 'X-API-KEY': 'a3ae13b739a5177f04f978f8d00b3786'
            },
            //data: data
        };
        ////console.log(api_config);

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setRates(response.data.rates);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);


    useEffect(() => {
        if (WalletID > 0) {
            setloadingCurrencies(true);
            var api_config = {
                method: "get",
                url: "https://rest.coinapi.io/v1/exchangerate/USD",
                headers: {
                    //'Content-Type': 'application/json',
                    "X-CoinAPI-Key": "BE3F6519-612E-4CA3-BA6D-6752E98C73BB",
                },
                //data: data
            };
            axios(api_config)
                .then(function (response) {
                    console.log(response.data);

                    if (response.data.rates) {
                        setCryptoRates(response.data.rates);
                        let rates = response.data.rates;

                        let btc = rates.filter((usdt) => usdt.asset_id_quote == "BTC");
                        let eth = rates.filter((usdt) => usdt.asset_id_quote == "ETH");
                        let usdt = rates.filter((usdt) => usdt.asset_id_quote == "USDT");
                        let trx = rates.filter((usdt) => usdt.asset_id_quote == "TRX");
                        setUSDT(usdt[0].rate);
                        setBTC(btc[0].rate);
                        setETH(eth[0].rate);
                        setTRX(trx[0].rate);

                        setloadingCurrencies(false);
                    }



                    ////console.log(usdt[0].rate);
                    // //console.log(eth);
                    // //console.log(usdt);
                    // //console.log(trx);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }, [WalletID]);


    useEffect(() => {

        if (WalletID != 0) {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                ID: WalletID,
            }
            var api_config = {
                method: 'post',
                url: config.base_url + 'MemberWallet/GetWalletByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(api_config)
                .then(function (response) {

                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        // Handle success
                        setWallet(response.data.Wallet);
                        setLoading(false);

                        if (response.data.Wallet.WalletType == "Crypto") {
                            setPaymentMode(response.data.Wallet.Network);
                        }
                        else {
                            setPaymentMode("Bank Account");
                        }

                    } else {
                        // Handle error

                    }
                    //   setLoading(false);
                })
                .catch(function (error) {

                    //console.log(error);
                });
        }
    }, [WalletID]);


    const CalcPercentage = (e) => {
        let amount = e.target.value;


        if (amount.length < 8) {
            // console.log(amount);
            setAmount(amount)
            if (amount.length == 0 || amount == 0) {
                setRemainingBalance(member.Current_Balance);
                setFinalAmount(0);
                setpAmount(0);
            } else {
                // shafat

                let final1 = ((amount / 100) * WithdrawFee).toFixed(2);
                let final = amount - final1;



                setFinalAmount(final);
                setpAmount(final1);

                setRemainingBalance(member.Current_Balance - amount);


                if (amount === '') {
                    setAmount(amount);
                    setShowError(false); // Hide the error message when a valid amount is entered
                } else {
                    setShowError(true); // Show the error message when an invalid amount is entered
                }


            }

        }

    };




    const handleChangeWallet = (value) => {
        setWalletID(value);
    }

    const handleFeeChange = (value) => {
        setWithdrawFee(value);
    }

    const renderWalletTypes = () => {
        
        
        //console.log(CryptoRates.length);
        if (loading || loadingCurrencies || loadingWallets || CryptoRates.length === 0) {
            return <p>Loading...</p>
        }
        else {
            if (wallet.WalletType == "Crypto") {
                let convertedAmount = 0;



                if (wallet.Network == "BTC") {
                    convertedAmount = finalAmount * BTC;
                }
                else if (wallet.Network == "ETH") {
                    convertedAmount = finalAmount * ETH;
                }
                else if (wallet.Network == "USDT") {
                    convertedAmount = finalAmount * USDT;
                }
                else if (wallet.Network == "TRX") {
                    convertedAmount = finalAmount * TRX;
                }
                else {
                    console.log("no wallet network ");
                }

               // console.log(CryptoRates);
              //  console.log('final amount' + finalAmount);
              //  console.log('btc' + BTC);
                // console.log(wallet.Network);
                // console.log(convertedAmount);
                // console.log(BTC);


                return (
                    <>
                        <ul className='list-info-between'>
                            <li>
                                <span className="list-label">Address</span>
                                <span className='list-value'>{wallet.Address}</span>
                            </li>
                            <li>
                                <span className="list-label">Fees</span>
                                <span className='list-value'>{WithdrawFee}%</span>
                            </li>
                            <li>
                                <span className="list-label">Receivable Amount in {wallet.Network}</span>
                                <span className='list-value'>{convertedAmount.toFixed(8)}</span>
                            </li>
                            <li>
                                <span className="list-label">Remaining Balance</span>
                                <span className='list-value'>{remainingBalance}</span>
                            </li>
                        </ul>
                    </>
                )
            }
            else if (wallet.WalletType == "Bank Account") {



                return (
                    <>
                        <ul className='list-info-between'>
                            <li>
                                <span className="list-label">Bank Name</span>
                                <span className='list-value'>{wallet.BankName}</span>
                            </li>
                            <li>
                                <span className="list-label">Account Title</span>
                                <span className='list-value'>{wallet.AccountTitle}</span>
                            </li>
                            <li>
                                <span className="list-label">IBAN</span>
                                <span className='list-value'>{wallet.Address}</span>
                            </li>
                            <li>
                                <span className="list-label">Account Currency</span>
                                <span className='list-value'>{wallet.AccountCurrency}</span>
                            </li>
                            <li>
                                <span className="list-label">Fees</span>
                                <span className='list-value'>{WithdrawFee}%</span>
                            </li>
                            <li>
                                <span className="list-label">Receivable Amount in {wallet.AccountCurrency}</span>
                                <span className='list-value'>{finalAmount}</span>
                            </li>
                            <li>
                                <span className="list-label">Remaining Balance</span>
                                <span className='list-value'>{remainingBalance}</span>
                            </li>
                        </ul>
                    </>
                )
            }
        }
    }


    const handleSendCode = () => {
        const props = {
            TransactionType: "WithdrawCash",
        };

        if (SendEmail(props)) {
            setIsButtonDisabled(true);

            messageApi.open({
                type: 'success',
                content: 'Authentication code has been sent',
            });
        }
        else {
            setIsButtonDisabled(false);

            messageApi.open({
                type: 'error',
                content: 'Fail to send Authentication code',
            });
        }



        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    };


    return (
        <>
            {contextHolder}
            <Modal
                title="Add Withdrawal"
                open={props.status}
                //onOk={props.close}
                onCancel={props.close}
                footer={null}
                width={600}
            >

                <div className="icon-box">
                    <div className="icon">
                        <img src={iconBalance} />
                    </div>
                    <div className="info">
                        <p>Available Balance</p>
                        <h4>$ {NumberFormat(member ? member.Current_Balance : 0)}</h4>
                    </div>
                </div>
                <Form
                    size="large"
                    layout="vertical"
                    onFinish={onFinish}
                >

                    <Form.Item label="Select Payment Mode">
                        <Select
                            onChange={handleChangeWallet}
                            defaultValue="Select Payment Mode"
                            options={wallets}
                            loading={wallets === false ? true : false}
                        />
                    </Form.Item>





                    {
                        WalletID > 0 &&
                        <>
                            <Form.Item
                                label="Withdraw Type"
                                name="Type"
                                //onChange={CalcPercentage}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Mode'
                                    }
                                ]}
                            >
                                <Select
                                    onChange={handleFeeChange}
                                    options={[
                                        {
                                            value: '12',
                                            label: 'Instant 12%',
                                        },
                                        {
                                            value: '0',
                                            label: '30 Days Withdrawal 0%',
                                        },
                                    ]}
                                />
                            </Form.Item>

                            {
                                loadingCurrencies ?
                                    <Spin tip="Loading currencies" />
                                    :
                                    <>
                                        <Form.Item
                                            label="Amount in USD"
                                            name="Amount"
                                            onChange={CalcPercentage}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter amount'
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        {renderWalletTypes()}
                                    </>
                            }

                            <Form.Item
                                name="AuthorizationCode"
                                label="Email Authentication Code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Authentication Code',
                                    },
                                ]}
                            >
                                <Space.Compact
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Input />
                                    <Button type="default"
                                        onClick={handleSendCode}
                                        disabled={isButtonDisabled}
                                    >GET CODE</Button>
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item
                                name="PinCode"
                                label="PIN Code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your 6-digit pin.',
                                    },
                                    {
                                        pattern: /^\d{6}$/,
                                        message: 'Please enter a 6-digit number.',
                                    },
                                ]}
                            >
                                <Input maxLength={6} showCount />
                            </Form.Item>
                            <div style={{ textAlign: "right" }}>
                                <Button htmlType="submit" type="primary" loading={loading}>Next</Button>
                            </div>
                        </>
                    }

                </Form>

            </Modal>
        </>
    );
}

export default NewWithdrawal;