import React, { useState, useEffect } from "react";

function CountdownTimer({ date }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function calculateTimeRemaining() {
    const now = new Date();
    const targetDate = new Date(date);
    const timeDifference = targetDate - now;

    if (timeDifference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <>
      <div class="counr-down">
   
        <div id="clockdiv">
        
          <div className="clock-wrapper">
            <div className="">
              <span className="days">{timeRemaining.days}</span>
            </div>
            <div className="smalltext">Days</div>
          </div>
          <div className="clock-wrapper">
            <div className="">
              <span className="hours">{timeRemaining.hours}</span>
            </div>
            <div className="smalltext">Hours</div>
          </div>
          <div className="clock-wrapper">
            <div className="">
              <span className="minutes">{timeRemaining.minutes}</span>
            </div>
            <div className="smalltext">Minutes</div>
          </div>
          <div className="clock-wrapper">
            <div className="">
              <span className="seconds">{timeRemaining.seconds}</span>
            </div>
            <div className="smalltext">Seconds</div>
          </div>
        </div>
        </div>
     
    </>
  );
}

export default CountdownTimer;
