import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";

function TransROISecond(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        document.title = "ROI Self Transaction";


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            LevelNo: 2

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ROI/GetTeamROI',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log("response",response.data);
                setlistOfTransactions(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const columns = [
        {
            title: 'SR',
            dataIndex: 'row', // Assuming 'row' is a field in your data source
            key: 'row',
            render: (_, record, index) => (
                index++ + pageSize * (pageNo - 1) +1
            )
        },
        {
            title: 'Dated',
            dataIndex: 'Dated',
            key: 'Dated',
            render: (_, record) => (
                dayjs(record.Dated).format(config.date_format)
            )
        },
        {
            title: 'Name',
            dataIndex: 'Full_Name',
            key: 'Full_Name',
        },
        {
            title: 'Email Address',
            dataIndex: 'Email_Address',
            key: 'Email_Address',
        },



        {
            title: 'Trading Amount',
            dataIndex: 'TradingAmount',
            key: 'TradingAmount',
            render: (_, record) => (
                "$ " + record.TradingAmount
            )
        },

        {
            title: 'Eligible Trading',
            dataIndex: 'EligibleTradingAmount',
            key: 'EligibleTradingAmount',
            render: (_, record) => (
                "$ " + record.EligibleTradingAmount
            )
        },

        {
            title: 'Profit ',
            dataIndex: 'ROIAmount ',
            key: 'ROIAmount ',
            render: (_, record) => (
                "$ " + record.ROIAmount
            )
        },
        {
            title: 'Level No',
            dataIndex: 'LevelNo',
            key: 'LevelNo',
        },


        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',

        },
    ];

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };


    return (
        <>
            <TransactionMenu active="roisecond" />
            <Table dataSource={listOfTransactions} columns={columns} size="small" loading={loading} pagination={false} />
            <div className="pagination-wrap">

                {totalRecords > 10 && (
                    <Pagination
                        defaultCurrent={1}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={page => setPageNo(page)}
                    />
                )}
            </div>
        </>
    );
}

export default TransROISecond;