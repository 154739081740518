import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";

import profit from "../../../assets/images/admin/icons/profit.png";

import calendar from "../../../assets/images/admin/icons/calendar.png";
import date3 from "../../../assets/images/admin/icons/date3.png";

import date2 from "../../../assets/images/admin/icons/date2.png";
import date1 from "../../../assets/images/admin/icons/date1.png";

   
   

import { useNavigate } from "react-router-dom";
import TowergadRightsCheck from "../TowergadRightsCheck";

function CompanyProfitDashboard() {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [totalProfit, settotalProfit] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [threeMonth, setThreeMonth] = useState("");
  const [twoMonth, setTwoMonth] = useState("");
  const [oneMonth, setOneMonth] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "users/GetCompanyProfit";

    // setIsUpdated(false);
    // setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "users/GetCompanyProfit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
          settotalProfit(response.data.totalProfit);
          setCurrentMonth(response.data.currentMonth);
          setThreeMonth(response.data.threeMonthBefore);
          setTwoMonth(response.data.twoMonthBefore);
          setOneMonth(response.data.oneMonthBefore);

          //   setLedger(response.data.ListofRecords);
          //   setLoading(false);
          //   setTotalRecords(response.data.totalRecords);
          //   //console.log(totalRecords);
        } else {
          navigate("/towergad/login");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);
  return (
    <>
    <TowergadRightsCheck />
         
      <PageTitle title="COMPANY PROFIT" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <TowergadCard
              link="/towergad/company-profit/total-profit"
              class="blue"
              value={totalProfit}
              label="TOTAL PROFIT"
              icon={profit}
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="red"
              value={currentMonth}
              label="CURRENT MONTH"
              icon={calendar}
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value={threeMonth}
              label="3 MONTH BEFORE 
"
              icon={date3}
            />
          </div>
          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="brown"
              value={twoMonth}
              label="2 MONTH BEFORE
"
              icon={date2}
            />
          </div>

          <div className="col-md-3">
            <TowergadCard
              link="#/"
              class="green"
              value={oneMonth}
              label="1 MONTH BEFORE
"
              icon={date1}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyProfitDashboard;
