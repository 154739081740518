import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import TowergadCard from "../TowergadCard";
import manage_user from "../../../assets/images/admin/manage_user.svg";
import manage_group from "../../../assets/images/admin/manage_group.svg";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
   
   
import TowergadRightsCheck from "../TowergadRightsCheck";

function SecurityDashboard() {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [SecurityData, setSecurityData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Security Dashboard";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetSecurityDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);

        if (response.data.status_code == 1) {
          setSecurityData(response.data);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/towergad/login");
        } else {
             
        }

        setLoading(false);
      })
      .catch(function (error) {
            
      });
  }, []);

  return (
    <>
      <TowergadRightsCheck />

      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
             
          <PageTitle title="Security " />
          <div className="admin-content">
            <div className="row">
              <div className="col-md-3">
                <TowergadCard
                  link="/towergad/security/manage-users"
                  class="green"
                  value={SecurityData.totalUsers}
                  label="Manage User"
                  icon={manage_user}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="10%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>
              <div className="col-md-3">
                <TowergadCard
                  link="/towergad/security/manage-groups"
                  class="red"
                  value={SecurityData.totalGroups}
                  label="Manage Groups"
                  icon={manage_group}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="30%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SecurityDashboard;
