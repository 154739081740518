import React, { useEffect, useState } from "react";
import RanksMenu from "./RanksMenu";
import { Col, Empty, Row, Spin } from "antd";
import avatar1 from "../../../assets/images/avatar/1.png";
import avatar2 from "../../../assets/images/avatar/2.png";
import avatar3 from "../../../assets/images/avatar/3.png";
import avatar4 from "../../../assets/images/avatar/4.png";
import avatar5 from "../../../assets/images/avatar/5.png";
import avatar6 from "../../../assets/images/avatar/6.png";
import avatar7 from "../../../assets/images/avatar/7.png";
import avatar8 from "../../../assets/images/avatar/8.png";
import united_states from "../../../assets/images/flag/united-states.png";
import philippines from "../../../assets/images/flag/philippines.png";
import canada from "../../../assets/images/flag/canada.png";
import india from "../../../assets/images/flag/india.png";
import united_kingdom from "../../../assets/images/flag/united-kingdom.png";
import pakistan from "../../../assets/images/flag/pakistan.png";
import australia from "../../../assets/images/flag/australia.png";
import china from "../../../assets/images/flag/china.png";
import RankCard from "./RankCard";
import config from "../../../Config";
import axios from "axios";


function Ranks(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  const [ranks, setRanks] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Tower Ranks";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: 1,
      pageSize: 10,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetRankedMembers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setRanks(response.data.ListofLedger);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
         
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          // navigate("/towergad/login");
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (ranks != null && ranks.length > 0) {
      return ranks.map((user, index) => {
        //console.log(item);
        return (
          <>
            <Col xs={24} md={6}>
              <RankCard
                flag={config.images + "flags/" + user.Extra1}
                country_name={user.Country}
                user={config.img_path + user.Img_File}
                name={user.Full_Name}
                email={user.Email_Address}
                rank={user.RankName}
              />
            </Col>
          </>
        );
      });
    } else {
      return (
        <Col xs={24} md={{span:8, offset:8}}  textAlign={"middle"}>
          <Empty />
        </Col>
      );
    }
  };

  return (
    <>
      <RanksMenu active="Ranks" />
      <div className="ranks-bg">
        <div className="overlay">
          <h3 style={{ textAlign: "center", margin: "0 0 25px 0" }}>
            Tower Ranks
          </h3>
          <Row gutter={[24, 24]} align={"middle"}>
            {loading ? (
              <Spin />
            ) : (
              <>{renderLedger()}</>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default Ranks;
