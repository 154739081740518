import React, { useState, useEffect } from "react";
import { Tabs, Table, Switch, Space, Tooltip, message } from 'antd';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";
import DDAddNewWallet from "./DDAddNewWallet";
import config from "../../../Config";
import axios from "axios";
import dayjs from "dayjs";
import DeleteWallet from "./DeleteWallet";


function Wallets(props) {


    const [wallets, setWallets] = useState([]);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    // wallets
    const [isDefaultUpdated, setIsDefaultUpdated] = useState(false);
    const [IsNewWalletAdded, setIsNewWalletAdded] = useState(false);

    // loadings
    const [loading, setLoading] = useState(false);

    // messages
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        document.title = "Payment Modes";
        setLoading(true);
        setIsDefaultUpdated(false);
        setIsNewWalletAdded(false);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/GetWallets',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setWallets(response.data.ListofWallets);
                    setLoading(false);


                }


            })
            .catch(function (error) {

            });

    }, [isDefaultUpdated, IsNewWalletAdded]);


    const onChange = (key) => {
        // //console.log(key);
    };




    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'Created_Date',
            key: 'date',
            render: (_, record) => (
                dayjs(record.Created_Date).format("DD MMM YYYY")
            )

        },
        {
            title: 'Wallet Name',
            dataIndex: 'WalletName',
            key: 'WalletName',
        },
        {
            title: 'Nick',
            dataIndex: 'Nick',
            key: 'Nick',
        },
        {
            title: 'Network',
            dataIndex: 'Network',
            key: 'Network',
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address',
        },
        {
            title: 'Default',
            dataIndex: 'Status',
            key: 'Status',
            render: (_, record) => (
                <Switch 
                    defaultChecked={record.IsDefault} 
                    onChange={(e) => MakeWalletDefault(record, e)}
                    disabled={record.IsDefault}
                    />
            )
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edit"><a><EditOutlined /></a></Tooltip>
                    <Tooltip title="Delete"><a onClick={(e) => handleDeleteWallet(record.ID)}><DeleteOutlined /></a></Tooltip>
                </Space>
            )
        },
    ];

  
    const handleDeleteWallet = async (WalletID) => {
        try {
            const response = await DeleteWallet(WalletID);
            messageApi.open({
                type: 'success',
                content: response.status_message,
            });
            setIsNewWalletAdded(true);
            

          // Handle success, update state, etc.
        } catch (error) {
          //console.error('Delete failed', error);

            messageApi.open({
                type: 'error',
                content: error.status_message,
            });
          // Handle failure, show error message, etc.
        }
      };




    const items = [
        {
            key: '1',
            label: 'Crypto',
            children: <Table
                dataSource={wallets.filter(item => item.WalletType == "Crypto")}
                columns={columns}
                size="small"
                pagination={false}
                loading={loading} />,
        },
        {
            key: '2',
            label: 'Bank Account',
            children: <Table
                dataSource={wallets.filter(item => item.WalletType == "Bank Account")}
                columns={columns}
                size="small"
                pagination={false}
                loading={loading} />,
        },
    ];


    const MakeWalletDefault = (item, e) => {
        //e.target.checked
        setLoading(true);
        //console.log(item);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: item.ID,
            IsDefault: e
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/MakeWalletDefault',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                //console.log(response.data);
                if (response.data.status_code == 1) {

                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });

                    setIsDefaultUpdated(true);

                }


            })
            .catch(function (error) {

            });

        setLoading(false);
    }




    return (
        <>
            {contextHolder}
            <div className="card-action-title">
                <h3 className="card-title">Payment Modes</h3>
                <div className="card-actions">
                    <DDAddNewWallet onWalletAdd={() => setIsNewWalletAdded(true)} />
                </div>
            </div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </>
    );
}

export default Wallets;