import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";

// import RankCard from "./RankCard";
import config from "../../../Config";
import axios from "axios";
import Pagination from "../../member/General/Pagination";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import { calculateColumnTotal } from "../../Common/Util";
import TowergadRightsCheck from "../TowergadRightsCheck";

function PurchaseNow(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));


    const [Purchase, setPurchase] = useState([]);
    const [loading, setLoading] = useState(false);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [filterStartDate, setfilterStartDate] = useState('');
    const [filterEndDate, setfilterEndDate] = useState('');
    const [showFilters, setShowFilters] = useState(true);
    const [TotalPackageAmount, setTotalPackageAmount] = useState('');
    const [totalFee, settotalFee] = useState('');

    useEffect(() => {
        document.title = "Purchase Now";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: filterStartDate,
            endDate: filterEndDate
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetDownlineActivationPaid",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log("response", response.data);
                if (response.data.status_code == 1) {
                    setPurchase(response.data.ListofOtherReward);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setTotalPackageAmount(response.data.totalPackage);
                    settotalFee(response.data.totalFee);
                }
                // navigate("/towergad/login");
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, filterStartDate, filterEndDate]);

    const renderLedger = () => {
        if (Purchase != null && Purchase.length > 0) {
            let count = 1;
            return Purchase.map((item, index) => {
                return (
                    <>
                        <tr key={index}>
                            <td>{item.SecretID}</td>
                            <td>{item.PackageName}</td>
                            <td>{dayjs(item.Dated).format(config.date_format)}</td>
                            <td>{item.User_ID}</td>
                            <td>{item.Paid_By}</td>
                            <td>$ {item.PackageAmount}</td>
                            <td>$ {item.FeeAmount}</td>
                            <td>{item.Status}</td>
                        </tr>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="7">
                        <h4
                            className="mt-3 text-center"
                            style={{ fontSize: "15px", color: "white" }}
                        >
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    return (
        <>
            <TowergadRightsCheck />
            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">Purchase Now</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary  ms-3"
                                style={{
                                    fontWeight: "500",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i class="material-symbols-outlined">filter_list</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Start Date</label>
                                            <div className="inputwrap mt-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterStartDate}
                                                    onChange={(e) => setfilterStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">End Date</label>
                                            <div className="inputwrap mt-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterEndDate}
                                                    onChange={(e) => setfilterEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Trade</th>
                                    <th>Date</th>
                                    <th>Paid For</th>
                                    <th>Paid By</th>
                                    <th>Trade Value</th>
                                    <th>Fee Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={"7"} className="text-center">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {Purchase.length > 0 &&
                                            <>
                                                <tr>
                                                    <td colSpan={5} className='text-end'>Subtotal:</td>
                                                    <td>$ {calculateColumnTotal(Purchase, 'PackageAmount')}</td>
                                                    <td>$ {calculateColumnTotal(Purchase, 'FeeAmount')}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className='text-end'> Grand Total:</td>
                                                    <td>$ {TotalPackageAmount}</td>
                                                    <td>$ {totalFee}</td>
                                                    <td></td>
                                                </tr>
                                            </>
                                        }



                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div >
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
export default PurchaseNow