import React, { useState, useEffect } from "react";
import TransactionMenu from "./TransactionMenu";
import axios from "axios";
import config from "../../../Config";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";

const InstantRewards = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [loading, setLoading] = useState(false);
  const [listOfTransactions, setlistOfTransactions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    document.title = "Rewards Transaction";

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Wallet/GetDirectBonus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data);
        setlistOfTransactions(response.data.ListofLedger);
        setTotalRecords(response.data.totalRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize]);
  const columns = [

    {
      title: "Transaction Date",
      dataIndex: "Transaction_Date",
      key: "Transaction_Date",
      render: (_, record) => (
        dayjs(record.Transaction_Date).format(config.date_format)
      )
    },

    {
      title: "Reference No",
      dataIndex: "Reference_No",
      key: "Reference_No",
      render: (_, record) => (
        record.Reference_No
      )
    },
    {
      title: "Reward Amount",
      dataIndex: "RewardAmount",
      key: "RewardAmount",
      render: (_, record) => (
        "$ " + record.RewardAmount
      )
    },
    {
      title: "Reward Percent",
      dataIndex: "RewardPercent",
      key: "RewardPercent",
      render: (_, record) => (
        "% " + record.RewardPercent
      )
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",


    },
    // {
    //     title: "Reward Photo",
    //     dataIndex: "RewardPhoto",
    //     key: "RewardPhoto",
    //     render: (_, record) => (
    //        <img src={config.img_path + record.RewardPhoto}/>
    //     )

    //   },

  ];

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <>
      <TransactionMenu active="InstantRewards" />
      <Table
        dataSource={listOfTransactions}
        columns={columns}
        size="small"
        loading={loading}
        pagination={false}
      />
      <div className="pagination-wrap">
        {totalRecords > 10 && (
          <Pagination
            defaultCurrent={1}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSize={pageSize}
            total={totalRecords}
            onChange={(page) => setPageNo(page)}
          />
        )}
      </div>
    </>
  );
};

export default InstantRewards;
