import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
import CountdownTimer from "../../Common/CountdownTimer";

import logo from "../../../assets/images/logo/logo.svg"

function LaunchPromo(props) {



  useEffect(() => {
    document.title = "Launching Soon";
  }, []);



  return (
    <>

      <div className="launch-wrap">
        <div className="launch-inner">
          <div className="launch-logo">
            <img src={logo} />
          </div>
          <div className="launch-title">
            <h3>Unveiling Excellence:</h3>
            <h1>Launching Soon</h1>
          </div>
          <div className="launch-time-box">
            {/* <Countdown text="test" derbyDate={PromoEndDate} /> */}

            <CountdownTimer date="2023-10-25" />
          </div>
          <p>
            Get ready to embark on a journey of innovation and
            reliability! We are thrilled to announce the upcoming
            launch of Tower Gear Plus on 25 October 2023. Anticipate
            unparalleled quality, exceptional service, and
            groundbreaking solutions that will redefine your trading
            experience. Join us as we set new standards in the
            industry. Stay tuned for an exciting future!
          </p>
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <NavLink className="button button-outline-primary button-icon" to="/">
              Back to Home <span class="material-symbols-outlined">arrow_forward_ios</span>
            </NavLink>
          </div>
        </div>
      </div>





    </>
  );
}

export default LaunchPromo;
