import { Space, Table, Popconfirm, Pagination, message, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../Config";
import NewWithdrawal from './NewWithdrawal';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

function Withdrawal({ onWalletAdd }) {


  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [WithdrawelList, SetWithdrawelList] = useState([])
  // loadings
  const [loading, setLoading] = useState(false);

  const [withdraw, setWithdraw] = useState([]);
  const [widthOld, setWithdrawOld] = useState([]);
  const [subTotal, setsubTotal] = useState(0);
  const [Total, setTotal] = useState(0);
  const [loadingRecordId, setLoadingRecordId] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [IsCanceled, setIsCanceled] = useState(false);
  const [RequestID, setRequestID] = useState(null);

  // messages
  const [messageApi, contextHolder] = message.useMessage();

  // modal to add wallet
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalBankOpen, setIsModalBankOpen] = useState(false);

  const showModalBank = () => {
    setIsModalBankOpen(true);
  };
  const handleBankCancel = () => {
    // onWalletAdd();
    setIsCanceled(true);
    setIsModalBankOpen(false);
  };



  useEffect(() => {
    document.title = "Withdrawal";
    setIsCanceled(false);
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/GetWithdrawlList',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {

        console.log(response.data);

        if (response.data.status_code == 1) {
          SetWithdrawelList(response.data.ListofWithdrawl);
          setWithdrawOld(response.data.ListofWithdrawl);
          setTotalRecords(response.data.totalRecords);
          setTotal(response.data.Total);
          setsubTotal(response.data.subTotal);
          setLoading(false);
        }
        else {
          setLoading(false);
        }


      })
      .catch(function (error) {
        //console.log(error);
      });

  }, [IsCanceled]);


  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'PaymentID',
      key: 'PaymentID',
    },
    {
      title: 'Date',
      dataIndex: 'Request_Date',
      key: 'Date',
      render: (_, record) => (
        dayjs(record.Request_Date).format(config.date_format)
      )
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (_, record) => (
        "$" + record.Amount
      )
    },
    {
      title: 'Fee',
      dataIndex: 'FeeAmount',
      key: 'Fee',
      render: (_, record) => (
         record.FeeAmount + " %"
      )
    },
    {
      title: 'Receivable Amount',
      dataIndex: 'NetReturnableInCurrency',
      key: 'Receivable',
    },
    {
      title: 'Withdrawal Mode',
      dataIndex: 'PaymentMode',
      key: 'Mode',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: 'Action',
      dataIndex: 'Status',
      key: 'Status',
      render: (_, record) => (
        record.Status == "Initiated" ?
          <Popconfirm
            title="Cancel the Request"
            description="Are you sure to cancel this request?"
            onConfirm={(e) => CancelRequest(record.ID)}
            //onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type='default'>Cancel</Button>
          </Popconfirm>

          :
          ""
      )
    },

    // {
    //   title: 'Invoice',
    //   dataIndex: 'Invoice',
    //   key: 'Invoice',
    // },
  ];


  const CancelRequest = (ID) => {



    setRequestID(ID);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID
    };



    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/CancelWithdrawlRequest',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setIsCanceled(true);

      })
      .catch(function (error) {
        //console.log(error);
      });

  }


  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setCurrentPage(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Change this to your desired page size
  const [currentPage, setCurrentPage] = useState(1);




  return (
    <>
      <div className="card-action-title">
        <h3 className="card-title">Withdrawal</h3>
        <div className="card-actions">
          <NavLink style={{marginRight: "10px"}} className="button button-round  button-outline-primary button-long button-small" to="/member/deposit/payment-modes">
            Wallets
          </NavLink>
          <a className="button button-round  button-outline-primary button-long button-small"
            onClick={showModalBank} >New Request</a>
        </div>
      </div>

      {/* <Table dataSource={dataSource} columns={columns} size="small" /> */}
      <NewWithdrawal status={isModalBankOpen} close={handleBankCancel} />
      <Table size="small" loading={loading} columns={columns} dataSource={WithdrawelList}
        pagination={false} // Remove table pagination 
      />
      {/* <div className="text-end mt-5">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          size="small"
          total={WithdrawelLists.length}
          onChange={onPageChange}
          onShowSizeChange={onPageSizeChange} // Handle page size change
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `Total ${total} items`}
        />
      </div> */}

    </>
  );
}

export default Withdrawal;