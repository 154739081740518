import React, { useEffect } from "react";
import PageTitle from "./PageTitle";
import iconCalendar from "../../assets/images/admin/icons/calendar.png";
import iconDate2 from "../../assets/images/admin/icons/date2.png";
import iconDate3 from "../../assets/images/admin/icons/date3.png";
import iconProfit from "../../assets/images/admin/icons/profit.png";
import TowergadLoginCheck from "./TowergadLoginCheck";
// import AdminLoginCheck from "./AdminLoginCheck";

function TowergadDashboard(props) {
  useEffect(() => {
    document.title = "Admin Tower Gear Plus";
  }, []);

  return (
    <>
      <TowergadLoginCheck/>

      <PageTitle title="Tower Gear Plus DASHBOARD" />

      {/* <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="5"
              label="TOTAL PROFIT"
              icon={iconProfit}
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="15"
              label="CURRENT Month"
              icon={iconCalendar}
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="3222"
              label="3 MONTH BEFORE"
              icon={iconDate3}
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="178455"
              label="2 MONTH BEFORE"
              icon={iconDate2}
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="325"
              label="1 MONTH BEFORE"
              icon={iconCalendar}
            />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default TowergadDashboard;
