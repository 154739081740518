import React from 'react'
import Congratulations from "../../../assets/images/dashboard/ranks/Congratulations.svg";
import gift from "../../../assets/images/dashboard/ranks/gift.svg";

const MysteryCard = (props) => {
    return (
        <>
            <div className="mystery-box">
                <div className="flag">
                    <img src={props.flag} alt="" />
                    <span>{props.country_name}</span>
                </div>
                <h4 className='date'>Dated: <span>{props.dated}</span></h4>
                <div className="profile">
                    <div className="img-warper">
                        <img src={props.photo} alt="" />
                    </div>
                    <div className="info">
                        <img src={Congratulations} alt="" />

                        <h1>{props.name}</h1>
                        {/* <p>{props.email}</p> */}
                        <h4>Amount Deduction: $ <span>{props.deduction}</span></h4>
                    </div>
                    <div className="gift-box">
                        <img src={gift} alt="" />
                        <h1>$ {props.winner}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MysteryCard