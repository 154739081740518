import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";

function TransROISelf(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        document.title = "ROI Self Transaction";


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ROI/GetSelfROI',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setlistOfTransactions(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'Dated',
            key: 'Dated',
            render: (_, record) => (
                dayjs(record.Dated).format(config.date_format)
            )
        },
        {
            title: 'Trading Amount',
            dataIndex: 'TradingAmount',
            key: 'TradingAmount',
            render: (_, record) => (
                "$" + record.TradingAmount
            )
        },
        {
            title: 'Balance Profit',
            dataIndex: 'BalanceProfit',
            key: 'BalanceProfit',
            render: (_, record) => (
                "$" + record.BalanceProfit
            )


        },
        {
            title: 'Compounding',
            dataIndex: 'Compounding',
            key: 'Compounding',
            render: (_, record) => (
                record.Compounding ?
                    <>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#AFF704" />
                        </svg>
                    </>
                    :
                    <>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#BB0616" />
                        </svg>
                    </>
            )
        },
        {
            title: 'Per Day Profit',
            dataIndex: 'PerDayProfit',
            key: 'PerDayProfit',
            render: (_, record) => (
                "$" + record.PerDayProfit
            )
        },
        {
            title: 'Profit Earned',
            dataIndex: 'ProfitEarned',
            key: 'ProfitEarned',
            render: (_, record) => (
                "$" + record.ProfitEarned
            )
        },
        {
            title: 'Profit Withdrawn',
            dataIndex: 'ProfitWithdrawn',
            key: 'ProfitWithdrawn',
            render: (_, record) => (
                "$" + record.ProfitWithdrawn
            )
        },
        {
            title: 'Remaining Days',
            dataIndex: 'RemainingDays',
            key: 'RemainingDays',

        },
        {
            title: 'Trading 2x',
            dataIndex: 'Trading2x',
            key: 'Trading2x',

        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            
        },
    ];

    return (
        <>
            <TransactionMenu active="rioself" />
            <Table dataSource={listOfTransactions} columns={columns} size="small" loading={loading} pagination={false} />
            <div className="pagination-wrap">

                {totalRecords > 10 && (
                    <Pagination
                        defaultCurrent={1}
                        showSizeChanger
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={page => setPageNo(page)}
                    />
                )}
            </div>
        </>
    );
}

export default TransROISelf;