import React, { useState, useEffect } from 'react'
import logo from '../../../assets/images/logo/towergear_white.png'
import { NavLink } from 'react-router-dom'
import { scrollToTop } from '../../Common/scrollToTop'

const Header = (props) => {

    const AccessKey = localStorage.getItem("AccessKey");


    const renderButtons = () => {
        if (AccessKey) {
            return (
                <>
                    <NavLink onClick={scrollToTop} to="/member/dashboard" className="button button-primary button-icon">
                        Dashboard <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </NavLink>
                </>

            );
        }
        else {
            return (
                <>
                    <NavLink onClick={scrollToTop} to="/login" className="button button-primary button-icon">
                        Sign In <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </NavLink>
                </>

            );
        }

    }

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        // Add an event listener to the window for scroll event
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        // Calculate the scroll position threshold to determine when to add/remove the class
        const scrollThreshold = 150; // Adjust this value as per your requirement

        // Check if the scroll position is beyond the threshold
        const shouldAddClass = window.scrollY > scrollThreshold;

        setIsScrolled(shouldAddClass);
    };

    const [active, setActive] = useState(false);

    const handleClick = () => {
        // Toggle the 'active' state
        setActive(!active);


        // Toggle the 'active' class on 'menubutton' based on the 'active' state
        const menubutton = document.getElementById("menubutton");

        if (menubutton) {
            menubutton.classList.toggle('active');
        }

        // Toggle the 'active' class on 'Nav_bar' based on the 'active' state
        const Nav_bar = document.getElementById("Nav_bar");

        if (Nav_bar) {
            Nav_bar.classList.toggle('active');
        }
    };
    return (
        <>
            <header id='website-header' className={`${props.addclass} ${isScrolled ? 'sticky' : ''}`}>
                <div className='tg-container'>
                    <div className="website-header">
                        <img src={logo} className="navbar-brand" alt="brand" />
                        <ul className="navbar-nav" id='Nav_bar'>
                            <li className="nav-item"><NavLink onClick={scrollToTop} to="/home" className="nav-link">Home</NavLink></li>
                            <li className="nav-item"><NavLink onClick={scrollToTop} to="/about" className="nav-link">About</NavLink></li>
                            <li className="nav-item"><NavLink onClick={scrollToTop} to="/services" className="nav-link">Service</NavLink></li>
                            <li className="nav-item"><NavLink onClick={scrollToTop} to="/contact" className="nav-link">Contact</NavLink></li>
                            <li className="nav-item">
                                <div className='d-block d-md-none'>
                                    {renderButtons()}
                                </div>
                            </li>
                        </ul>
                        <div className='d-none d-md-block'>
                            {renderButtons()}
                        </div>
                        <svg className={`ham hamRotate ham8 ${active ? 'active' : ''}`}
                            id="menubutton"
                            onClick={handleClick}
                            viewBox="0 0 100 100" width="80">
                            <path
                                className="line top"
                                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                            <path
                                className="line middle"
                                d="m 30,50 h 40" />
                            <path
                                className="line bottom"
                                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                        </svg>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header