import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
   
  Select,
} from "antd";
import axios from "axios";
import config from "../../../../Config";

const KycSubmitted = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Text, Link, Title } = Typography;
  useEffect(()=>{
      document.title= "KYC Submitted"

    },[])
  return (
   <>
   
   <Row gutter={[24, 24]}>
        <Col md={20} offset={2} xs={24}>
          <Card title="KYC Submission Completed" bordered={true}>
            <Title level={5} style={{margin:"0px"}}>Your document is successfully submitted and is under review. It may take  24-72 hours to verify.  <br/>Thank you for your patience.</Title>
         
           
             
             <div style={{textAlign:"end",marginTop:"5px"}}>


        <Button
        type="primary"
         disabled
        
            
            
           >Submitted</Button>

</div>
{/* <div>

{
    loading ?
    ""

        // <Spinner animation="border" role="status"></Spinner>
        :
        <Button
        type="primary"
            onClick={getCropData}
            disabled={!imageSelected}>Continue</Button>
}
</div> */}


         
                      
                      
          </Card>
        </Col>
      </Row>
   
   </>
  )
}

export default KycSubmitted